import React from 'react'
import { AiOutlineHeart } from 'react-icons/ai'
import { AiFillHeart } from 'react-icons/ai'
import StarRating from './StarRating'

export default function SearchResultsBox( { book }) {
    return (
            <div className='box'>
                <div className='results-box'>
                    <div className='rowC'>
                        <div className='results-image'>
                            <a href={"/books/"+book.bookId}>
                                <img
                                    className='results-book-cover'
                                    src={book.bookPhotoURL ?? '/default_book.png'}
                                    alt={book.bookname}
                                />
                            </a>
                            {/* <button className='favourites-button'> */}
                                {/* <AiFillHeart
                                    color={'#C4C4C4'}
                                    onMouseOver={({ target }) => (target.style.color = 'red')}
                                    onMouseOut={({ target }) => (target.style.color = '#C4C4C4')}
                                /> */}
                            {/* </button> */}
                        </div>
                        <div className='results-description'>
                            
                                <div className='box3'>
                                    <a href={"/books/"+book.bookId} className='results-title'>{book.bookName}</a>
                                </div>
                            
                            <div className='author-name'>
                                <p>{"by " + (book.author ?? "Unknown") + "("+book.yearPublished+")" }</p>                 
                            </div>
                            <div className='author-name'>
                                <p>{book.genre}</p>
                            </div>
                            
                            
                            <div className="book-description">
                                {book.description ?? "No description available"}
                            </div>

                            {/* <div>
                                <button className='save-button'>Save to bookshelf</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
}
