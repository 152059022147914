import React, { useState } from 'react'


export default function NavbarItem( {title, link} ) {
    // this is a clickable element of the Navbar

    return (
        <a href={link} >
                <li className="nav-item">
                {title}
        </li>
            </a>
    )
}
