import React, { useEffect } from 'react';
import '../../css/search.css';
import { FaSearch } from 'react-icons/fa';
import queryString from 'query-string'

export default function SearchBar( { history, setQuery, searchInputRef, setCurrentPage, setSelectedGenre } ) {

	useEffect( () => {
		const searchInputBar = document.getElementById('search-input-bar')

		searchInputBar.addEventListener('keydown', (e) => {
			if(e.code === "Enter"){
				handleSearchInputChange(e)
			}
		})

		return searchInputBar.removeEventListener('keydown', (e) => {
			if(e.code === "Enter"){
				handleSearchInputChange(e)
			}
		})


	}, [])


	function handleSearchInputChange(e){	

		const location = history.location
		const q = (location && location.search )? queryString.parse( location.search).q : null
		const g = (location && location.search )? queryString.parse( location.search).g : 'all'

		if(e.target.value == q){
			// console.log("Input no change.")
			return
		}
		if(e.target.value === "" || e.target.value === " " || e.target.value === null || e.target.value === undefined){
			setQuery("")
			setCurrentPage(1)
			setSelectedGenre('all')
			return history.push("/search")
		}

		setQuery(e.target.value)
		setCurrentPage(1)
		setSelectedGenre(g ?? "all")
		history.push("/search?q=" + e.target.value + "&p=1&g=" + (g??"all"))
	}

	return (
		<div className='search'>
			<div className="header">
				<p> Search </p>	
			</div> 
			<div className='search-bar-inner'>
				<input
					type='text'
					placeholder='Find Books'
					className='search-bar'
					ref={searchInputRef}
					id="search-input-bar"/>
				<FaSearch className='search-icon' />
				<div className="search-bar-enter"> Enter ↩ </div>
			</div>
		</div>

	);
}

export function SortBar() {
	return (
		<div className='box'>
			<div className='container'>
				<div className='box2'>
					<div className='row'>
						{/* Column 1 */}
						<div className='col'>
							<p className='sort-description'>Year Published</p>
							<select className='sort-dropdown'>
								<option selected value='All'>
									All
								</option>
								<option value='2000'>2000</option>
								<option value='2001'>2001</option>
								<option value='2002'>2002</option>
								<option value='2003'>2003</option>
								<option value='2004'>2004</option>
								<option value='2005'>2005</option>
								<option value='2006'>2006</option>
								<option value='2007'>2007</option>
								<option value='2008'>2008</option>
							</select>
						</div>
						{/* Column 2 */}
						<div className='col'>
							<p className='sort-description'>Genre</p>
							<select className='sort-dropdown'>
								<option selected value='All'>
									All
								</option>
								<option value='Romance'>Romance</option>
								<option value='Horror'>Horror</option>
								<option value='Mystery'>Mystery</option>
								<option value='Fiction'>Fiction</option>
								<option value='Non-fiction'>Non-fiction</option>
								<option value='Thriller'>Thriller</option>
								<option value='Biography'>Biography</option>
								<option value='Comedy'>Comedy</option>
								<option value='Memoir'>Memoir</option>
							</select>
						</div>
						{/* Column 3 */}
						<div className='col'>
							<p className='sort-description'>Rating</p>
							<select className='sort-dropdown'>
								<option selected value='All'>
									All
								</option>
								<option value='>4'>4 or above</option>
								<option value='>3'>3 or above</option>
								<option value='>2'>2 or above</option>
								<option value='>1'>1 or above</option>
							</select>
						</div>
						{/* Column 4 */}
						<div className='col'>
							<p className='sort-description'>Language</p>
							<select className='sort-dropdown'>
								<option selected value='All'>
									All
								</option>
								<option value='English'>English</option>
								<option value='Traditional Chinese'>Traditional Chinese</option>
								<option value='Simplified Chinese'>Simplified Chinese</option>
								<option value='Spanish'>Spanish</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
