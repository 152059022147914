import React, { useState, useEffect } from 'react'
import queryString from 'query-string'

export default function FilterBar( {history, facetResults, selectedGenre, setSelectedGenre, setCurrentPage}) {

    const [ parsedGenres, setParsedGenres ] = useState([])


    const LIST_OF_GENRES = [
        "Fiction"
        ,"Children"
        ,"Fantasy"
        ,"Literature"
        ,"Business & Finance"
        ,"Art & Crafts"
        ,"Mystery"
        ,"Science"
        ,"Young Adult"
        ,"Classics"
        ,"Philopsophy"
        ,"Others"
    ]

    useEffect( () => {
        if(selectedGenre){
            setParsedGenres(selectedGenre.split('xx').filter(x => {return x!==""}))
        }
    }, [selectedGenre])


    function handleClearFilter() {
        setSelectedGenre('all')
        const q = (history.location && history.location.search )? queryString.parse( history.location.search).q : null
        const p = (history.location && history.location.search )? queryString.parse( history.location.search).p : 1
        history.push('/search?q=' + q + "&p=" + p + "&g=all")
    }

    function handleSelectFilter(e){
        
        const q = (history.location && history.location.search )? queryString.parse( history.location.search).q : null
        
        if(selectedGenre == 'all'){
            setSelectedGenre(e.target.value + 'xx')
            setCurrentPage(1)
            return history.push('/search?q=' + q + "&p=1&g=" + e.target.value + 'xx')
        }
        // in case the selected filter is already selected
        const parsedGenresCaps = parsedGenres.map(x => {return x.toUpperCase()})
        // console.log("parsedGenresCaps", parsedGenresCaps)
        // console.log("target:", e.target.value.toUpperCase())
        if(parsedGenresCaps.includes(e.target.value.toUpperCase())){
            const newParsedGenres = parsedGenres.filter( g => { return g.toUpperCase() !== e.target.value.toUpperCase()}) 

            const newSelectedGenrestring = newParsedGenres.length < 1 ? 'all' : newParsedGenres.join('xx')

            setSelectedGenre(newSelectedGenrestring)
            setCurrentPage(1)
            return history.push('/search?q=' + q + "&p=1&g=" + newSelectedGenrestring)
        }

        const newParsedGenres = [...parsedGenres, e.target.value]
        setSelectedGenre(newParsedGenres.join('xx'))
        setCurrentPage(1)
        history.push('/search?q=' + q + "&p=1&g=" + newParsedGenres.join('xx'))
    }

    return (
        
        <div className="search-filter-bar-container">
            {selectedGenre == "all" ? "" : 
                <button className="genre-not-selected" onClick={handleClearFilter}> x Clear Filters</button>
            }

            {LIST_OF_GENRES.map( g => {
                return (
                    <button onClick={handleSelectFilter} className={parsedGenres.includes(g)? "genre-selected" : "genre-not-selected"} value={g}> 
 
                        { 
                            g 
                        } 
                    
                    </button>
                )
            })
            }



        </div>
        
    )
}
