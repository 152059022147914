import React from 'react';
import Footer from './Footer';
import '../css/productschool.css';
import { Helmet } from 'react-helmet'

export default function ProductSchool() {
	return (
		<>

		<div>

			<p className='product-school-title'>Nixpage Classroom</p>
			<p className='product-school-coming-soon'>Coming Soon</p>
			<p className='product-school-intro'>
				Nixpage Classroom brings the virtual library to your school. With
				Nixpage’s enormous book database, students can rate and review any book
				they want. Within your classes, you can distribute book review
				assignments in an efficient manner. Students are motivated to complete
				assignments and gain points for the quantity and quality of book reports
				they hand in, keep reading to reach the #1 spot on the leaderboard!
				Nixpage is the new reading lifestyle.
			</p>
			<div className='product-school-features-container'>
				{/* FEATURE 1 */}
				<div className='row'>
					<div className='col'>
						<div className='container-align-center-vertical'>
							<p className='product-school-features-one-title'>1. Classroom</p>
							<p className='product-school-features-one-paragraph'>
								Import all information of your students to create your
								classroom, wide range of functions will be enabled to the
								classroom. Simply import your class information through an Excel
								submission.
							</p>
						</div>
					</div>
					<div className='col'>
						<div className='container-align-center'>
							<img
								className='product-school-features-one-animation'
								src='/classroom.gif'
								alt='feature animation'
							/>
						</div>
					</div>
				</div>

				{/* FEATURE 2 */}
				<div className='row'>
					<div className='col'>
						<div className='container-align-center-vertical'>
							<p className='product-school-features-one-title'>
								2. Bookshelves
							</p>
							<p className='product-school-features-one-paragraph'>
								Create a bookshelf for the entire class with recommended reading
								materials, or even create bookshelves for each teaching module
								to include only relevant books.
							</p>
						</div>
					</div>
					<div className='col'>
						<div className='container-align-center'>
							<img
								className='product-school-features-one-animation'
								src='/bookshelves.gif'
								alt='feature animation'
							/>
						</div>
					</div>
				</div>

				{/* FEATURE 3 */}
				<div className='row'>
					<div className='col'>
						<div className='container-align-center-vertical'>
							<p className='product-school-features-one-title'>
								3. Assignments
							</p>
							<p className='product-school-features-one-paragraph'>
								Create and distribute assignments to your class. You will be
								able to manage your submissions, score them, and provide
								feedback to individual students.
							</p>
						</div>
					</div>
					<div className='col'>
						<div className='container-align-center'>
							<img
								className='product-school-features-one-animation'
								src='/assignments.gif'
								alt='feature animation'
							/>
						</div>
					</div>
				</div>

				{/* FEATURE 4 */}
				<div className='row'>
					<div className='col'>
						<div className='container-align-center-vertical'>
							<p className='product-school-features-one-title'>
								4. Leaderboard
							</p>
							<p className='product-school-features-one-paragraph'>
								Encourages reading among students. Points will be assigned for
								completing assignments and book reviews, the students at the top
								of the leaderboard may receive rewards from teachers.
							</p>
						</div>
					</div>
					<div className='col'>
						<div className='container-align-center'>
							<img
								className='product-school-features-one-animation'
								src='/leaderboard.gif'
								alt='leaderboard animation'
							/>
						</div>
					</div>
				</div>

				{/* FEATURE 5 */}
				<div className='row'>
					<div className='col'>
						<div className='container-align-center-vertical'>
							<p className='product-school-features-one-title'>5. Analytics</p>
							<p className='product-school-features-one-paragraph'>
								A dashboard of analytics on the students and classes, including
								measuring student progresses, cross-class performances etc.
							</p>
						</div>
					</div>
					<div className='col'>
						<div className='container-align-center'>
							<img
								className='product-school-features-one-animation'
								src='/analytics.gif'
								alt='analytics animation'
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
		</>
	);
}
