import firebase from 'firebase/app'
import "firebase/auth" /* auth module for logins */
import "firebase/firestore" 
import "firebase/storage"

const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    dataBaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_FIREBASE_APP_ID
})

/* use firebase in every page in our app */
export const auth = app.auth()
export const db = app.firestore()
export const storage = app.storage()
export const timestamp = firebase.firestore.FieldValue.serverTimestamp



const exports = { app, db, storage, timestamp }

export default exports