import { useState } from 'react';
import Footer from './Footer';
import '../css/productschool.css';
import { Helmet } from 'react-helmet'
import ProductSchool from './ProductSchool'
import ProductPublic from './ProductPublic'

export default function Explore() {

    const [ publicTab, setPublicTab ] = useState(true)

    function handleChangeTab(e){
        setPublicTab(e.target.id === "public")
    }

    return (<>
        <Helmet>
		<title> nixpage | Explore </title>
		</Helmet>
        <div>
             <div className='product-school-tab-button-container'>

                <button 
                className={publicTab ? 'product-school-tab-nixpage-classroom-button': 'product-school-tab-nixpage-button'}
                id="public"
                onClick={handleChangeTab}
                >
                    Nixpage
                </button>

                <button 
                className={!publicTab ? 'product-school-tab-nixpage-classroom-button': 'product-school-tab-nixpage-button'}
                id="school"
                onClick={handleChangeTab}
                >
                    Nixpage Classroom
                </button>

            </div>

            { 
                publicTab ? <ProductPublic /> : <ProductSchool />
            }


            
        </div>
        </>
    )
}
