import React from 'react';
import '../../css/homepage.css';

export default function GenreButton({ genre, icon }) {
	return (
		<div className='homepage-genre-col'>
			<a href={'/search?q=*&p=1&g=' + genre.replace('&', '%26')}>
				<img src={icon} className='homepage-genre-button'></img>
				<p className='homepage-genre-name'>{genre}</p>
			</a>
		</div>
	);
}
