import React from 'react';
import Footer from './Footer';
import '../css/productpublic.css';
import ReactPlayer from 'react-player';
import { Helmet } from 'react-helmet';

export default function ProductSchool() {
	return (
		<>
			<div>
				<p className='product-school-title'>More Features</p>
				<p className='product-school-coming-soon'>Coming Soon</p>
				<div className='product-public-features-container'>
					{/* FEATURE 1 */}
					<div className='row'>
						<div className='col'>
							<div className='container-align-center-vertical'>
								<p className='product-school-features-one-title'>1. Search</p>
								<p className='product-school-features-one-paragraph'>
									Search any book you are interested in to get a glimse of the
									book summary, ratings, reviews and different language
									editions. You can even save it to your favourites or one of
									your custom bookshelves.
								</p>
							</div>
						</div>
						<div className='col'>
							<div className='container-align-center'>
								<img
									className='product-school-features-one-animation'
									src='/search.gif'
									alt='feature animation'
								/>
							</div>
						</div>
					</div>

					{/* FEATURE 2 */}
					<div className='row'>
						<div className='col'>
							<div className='container-align-center-vertical'>
								<p className='product-school-features-one-title'>
									2. Bookshelves
								</p>
								<p className='product-school-features-one-paragraph'>
									Create custom bookshelves to categorize your favourite books.
									Simply search and save it into any of your bookshelves, or you
									can just click the heart-shaped button to save it as your
									favourite!
								</p>
							</div>
						</div>
						<div className='col'>
							<div className='container-align-center'>
								<img
									className='product-school-features-one-animation'
									src='/bookshelfp.gif'
									alt='feature animation'
								/>
							</div>
						</div>
					</div>

					{/* FEATURE 3 */}
					<div className='row'>
						<div className='col'>
							<div className='container-align-center-vertical'>
								<p className='product-school-features-one-title'>3. Friends</p>
								<p className='product-school-features-one-paragraph'>
									Share the joy of reading with your friends, exchange
									recommendations, progress updates and more. Check all your
									friends’ updates on your newsfeed.
								</p>
							</div>
						</div>
						<div className='col'>
							<div className='container-align-center'>
								<img
									className='product-school-features-one-animation'
									src='/friends.gif'
									alt='feature animation'
								/>
							</div>
						</div>
					</div>

					{/* FEATURE 4 */}
					<div className='row'>
						<div className='col'>
							<div className='container-align-center-vertical'>
								<p className='product-school-features-one-title'>4. Ratings</p>
								<p className='product-school-features-one-paragraph'>
									Deciding which book to read and which ones to stay away from?
									Check out the ratings of your book selection to make a
									decision, you can also rate the book yourself after reading!
								</p>
							</div>
						</div>
						<div className='col'>
							<div className='container-align-center'>
								<img
									className='product-school-features-one-animation'
									src='/rating2.gif'
									alt='rating animation'
								/>
							</div>
						</div>
					</div>

					{/* FEATURE 5 */}
					<div className='row'>
						<div className='col'>
							<div className='container-align-center-vertical'>
								<p className='product-school-features-one-title'>5. Reviews</p>
								<p className='product-school-features-one-paragraph'>
									If the rating by itself doesn’t convince you, there will be
									book reviews to see what other people are saying about the
									book. Share your views with others by writing your own.
								</p>
							</div>
						</div>
						<div className='col'>
							<div className='container-align-center'>
								<img
									className='product-school-features-one-animation'
									src='/review.gif'
									alt='feature animation'
								/>
							</div>
						</div>
					</div>

					{/* FEATURE 6 */}
					<div className='row'>
						<div className='col'>
							<div className='container-align-center-vertical'>
								<p className='product-school-features-one-title'>
									6. Book Difficulty
								</p>
								<p className='product-school-features-one-paragraph'>
									We will analyze the books in our database and calculate the
									difficulty level for you. Recommend easier books to your young
									friends and more difficult books for book-lovers!
								</p>
							</div>
						</div>
						<div className='col'>
							<div className='container-align-center'>
								<img
									className='product-school-features-one-animation'
									src='/difficulty.gif'
									alt='feature animation'
								/>
							</div>
						</div>
					</div>
				</div>
				<p className='product-public-and-much-more'>and much more...</p>
			</div>
		</>
	);
}
