import React from 'react';

export default function Logo() {
	return (
		<div className='logo'>
			<a href='/'>
				<img
					className='logo-icon'
					alt='bookreads'
					src='/nixpage-beta-transparent.png'
				/>
			</a>
		</div>
	);
}
