import {React,useEffect} from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './Home';
import Signup from './Authentication/Signup';
import Login from './Authentication/Login';
import ForgotPassword from './Authentication/ForgotPassword';
import ProfilePage from './Profile/ProfilePage';
import NavigationBar from './NavigationBar/NavigationBar';
import { AuthProvider, useAuth } from '../contexts/AuthContext';
import LoggedInRoute from '../routings/LoggedInRoute';
import NonLoggedInRoute from '../routings/NonLoggedInRoute';
import '../css/index.css';
import '../css/book.css';
import '../css/myclass.css';
import '../css/classlibrary.css';
import '../css/search.css';
import '../css/myclassmodal.css';
import '../css/classfiles.css';
import MyClass from './MyClass/MyClass';
import Admin from './AdminTools/Admin';
import Footer from './Footer';
import CreateClass from './AdminTools/CreateClass';
import AssignStudents from './AdminTools/AssignStudents';
import BookPage from './Books/BookPage';
import Search from './Search/Search';
import SearchBar from './Search/SearchBar';
import SearchResults from './Search/SearchResults';
import Leaderboard from './MyClass/ClassLeaderboard/Leaderboard';
import Assignments from './MyClass/ClassAssignments/Assignments';
import AssignmentDetails from './MyClass/ClassAssignments/AssignmentDetails';
import AssignmentReview from './MyClass/ClassAssignments/AssignmentReview';
import Homepage from './Homepage';
import AboutUs from './AboutUs';
import ProductSchool from './ProductSchool';
import ProductPublic from './ProductPublic';
import { Helmet } from 'react-helmet'
import Explore from './Explore';
import ReactGa from 'react-ga';
import PageNotFound from './PageNotFound';

/**
 * @module App React Component
 */

/**
 * The main App component, this determines all the routing between the pages and limits the pathing between the pages.
 * 
 * Depending on the path, App will render the component which links to other component files

 * @returns {JSX} 
  The components being rendered depending on the path:
  - "/": renders Home.js
  - "/signup": renders Signup.js
  - "/login": renders Login.js
  - "/forgot-password": renders ForgotPassword.js
  - "/profile": renders ProfilePage.js
  - "/class" : renders MyClass.js
 */

  function App() {
    useEffect(() => {
        ReactGa.initialize('UA-201483795-1')
        // to report page view
        ReactGa.pageview(window.location.pathname + window.location.search)
    },[])
	return (
		<>

			{/* This is the main container for everything */}
			<AuthProvider>
				<NavigationBar />

				<Router>
					{/* 
            Moving forward, AuthProvider should be wrapped around here to get the
            the User object, affecting pathing e.g.
            if logged-in: "/" should render Home else redirect to "Login"
            */}

					<Switch>	
						{/* This is where the pathing is controlled for the whole app*/}
						<NonLoggedInRoute path='/about' component={AboutUs} />
						<NonLoggedInRoute path='/explore' component={Explore} />
						<NonLoggedInRoute exact path='/' component={Homepage} />
						{/* <NonLoggedInRoute path='/productschool' component={ProductSchool} />
						<NonLoggedInRoute path='/productpublic' component={ProductPublic} />
						<NonLoggedInRoute path='/signup' component={Signup} />
						<NonLoggedInRoute path='/login' component={Login} /> 
						<NonLoggedInRoute
							path='/forgot-password'
							component={ForgotPassword}
						/>
						*/}

						{/* <LoggedInRoute exact path='/' component={Home} /> */}
						{/* <LoggedInRoute path='/profile' component={ProfilePage} />
						<LoggedInRoute path='/class' component={MyClass} /> */}
						{/* <LoggedInRoute path='/admin' component={Admin} />
						<LoggedInRoute path='/createClass' component={CreateClass} />
						<LoggedInRoute path='/assignUsers' component={AssignStudents} />
						<LoggedInRoute path='/leaderboard' component={Leaderboard} />
						<LoggedInRoute path='/assignments' component={Assignments} />
						<LoggedInRoute
							path='/assignment-details'
							component={AssignmentDetails}
						/>
						<LoggedInRoute
							path='/assignment-review'
							component={AssignmentReview}
						/> */}
						<Route path='/books' component={BookPage} />
						<Route path='/search' component={Search} />
						<Route component={PageNotFound} />

					</Switch>
				</Router>

				<Footer />
			</AuthProvider>
		</>
	);
}

export default App;
