import React from 'react';
import '../css/footer.css';

export default function Footer() {
	return (
		<div className='main-footer'>
			<div className='footer-grid'>
				<div className='footer-div'>
					<h4 className='footer-header'>Company</h4>
					<ul className='list-unstyled'>
						<a href='/explore'>
							<li className='footer-item'>Explore Nixpage</li>
						</a>
						<a href='/explore'>
							<li className='footer-item'>Nixpage Classroom</li>
						</a>
						<a href='/about'>
							<li className='footer-item'>About Us</li>
						</a>
					</ul>
				</div>

				<div className='footer-div'>
					<h4 className='footer-header'>Explore</h4>
					<ui className='list-unstyled'>
						<a href='/'>
							<li className='footer-item'>Top Books</li>
						</a>
						<a href='/search'>
							<li className='footer-item'>New Releases</li>
						</a>
					</ui>
				</div>
				{/* Column3 */}
				<div className='footer-div'>
					<h4 className='footer-header'>Follow us</h4>
					<div className='row'>
						<a
							className='icon-button-1'
							href='https://www.facebook.com/Nixpage-106063238431911/'>
							<img
								src='/facebook_icon.svg'
								alt='facebook logo'
								className='socialmedia-button'
							/>
						</a>
						<a
							className='icon-button-2'
							href='https://www.instagram.com/nixpagehk/?hl=en'>
							<img
								src='/instagram_icon.svg'
								alt='instagram logo'
								className='socialmedia-button'
							/>
						</a>
					</div>
				</div>
			</div>
			<br />
			<hr className='footer-line' />
			<p className='copyright'>
				&copy;{new Date().getFullYear()} nixpage, Inc. | All rights reserved
			</p>
		</div>
	);
}
