import React, { useState, useEffect, useMemo } from 'react'

import { useLocation, Redirect } from 'react-router-dom'
import queryString from 'query-string'
import { useAuth } from '../../contexts/AuthContext'
import { db } from '../../firebase'
import BookOverview from './BookOverview'

export default function BookPage({ previousSearchString }) {
    const { currentUser } = useAuth()
    const [ userFromDB , setUserFromDB ] = useState("")
    const { pathname } = useLocation()
    const [ book, setBook ] = useState({});

    const bookId = pathname.split('/').pop()

    useMemo( () => {
        if(currentUser){
            getProfile(currentUser.uid)
        }
    }, [currentUser])

    useEffect( () => {
        console.log("OnMount useEffect for getBook(", bookId, ")")
        getBook(bookId)
    }, [bookId])
    

    function getBook(id){
        if(bookId === "books" ) return {}

        db.collection("books").where("bookId", "==", id ).get()
        .then( (querySnapshot) => {
            let result = []

            querySnapshot.forEach( (x) => {result.push(x.data())})

            setBook(result.length === 0 ? "NA" : result[0])
            console.log("Book retrieved: ", result)
        })
        .catch( (e) => { console.log("Error occurred: ", e)})
        }

    function getProfile(uid){
        if(uid==="") return
        const profileRef = db.collection("users").doc(uid)

        profileRef.get().then((doc) => {
            if(doc.exists){
                setUserFromDB(doc.data())
            } else{
                setUserFromDB(undefined) 
            }
        }).catch( (e) => {
            console.log("Error in retrieving profile:", e)
        })
        return
    }

    function getDateTodayString(){
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
          ]

        const today = new Date()
        const todayString = months[today.getMonth()] + " " + today.getDate() + " " + today.getFullYear() 


        return todayString ?? ""
    }
    function addToFavourites(){
        if(!userFromDB) return
        if(!userFromDB.books) return

        const existingFavourites = userFromDB.books.favourites ?? []

        const bookToAdd = {
            author: book.author,
            bookId: book.bookId,
            bookName: book.bookName,
            bookPhotoURL: book.bookPhotoURL,
            genre: book.genre,
            dateAdded: getDateTodayString()
        }

        const updatedFavourites = existingFavourites.concat([bookToAdd])

        db.collection("users").doc(currentUser.uid).update({
            "books.favourites" : updatedFavourites
        })
        .then( () => {console.log("Book added to user!")})
        .catch( (error) => {console.log("An error occurred adding book to favourites: ", error)})
        
    }

    function removeFromFavourites(){
        if(!userFromDB) return
        if(!userFromDB.books) return
        if(!userFromDB.books.favourites) return

        const existingFavourites = userFromDB.books.favourites

        const updatedFavourites = existingFavourites.filter( (existingBook) => {
            return existingBook.bookId !== book.bookId
        })

        console.log("Before:", existingFavourites)
        console.log("After:", updatedFavourites)

        db.collection("users").doc(currentUser.uid).update({
            "books.favourites" : updatedFavourites
        })
        .then( () => {console.log("Book removed from user!")})
        .catch( (error) => {console.log("An error occurred removing book to favourites: ", error)})
    }


    // if(bookId === "books") return <Redirect to="/search" />
    // if(book === "NA") return <Redirect to="/search" />

    return (
        <div className="book-page-container">
            <BookOverview 
            book={book} 
            previousSearchString={previousSearchString ?? ""} 
            userFromDB={userFromDB} 
            addToFavourites={addToFavourites}
            removeFromFavourites={removeFromFavourites}
            />
        </div>
    )
}
