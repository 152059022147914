import React from 'react'
import { Link } from "react-router-dom";

export default function SearchNewReleases() {
	return (
		<div className='new-release-box'>

			{/* "New Release Header" */}
			<p className='header-for-new-release'>New Release</p>

			{/* Table for new release books */}

				{/* Table for new release books */}
				<div className='book-item-search-new-release-container'>

						{/* Row 1 Col 1 */}
						<div className='book-item-search-new-release'>
						<Link to ="/books/G2ZHvd5bkrfrjsk">
							<img
								className='book-cover'
								src='https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1611556027l/53137974.jpg'
								alt='The good sister'
							/>
							<p className='book-title'>The Good Sister</p>
							</Link>
						</div>
						{/* Row 1 Col 2 */}
						<div className='book-item-search-new-release'>
						<Link to ="/books/VvtOz2KMG68VFKV">
							<img
								className='book-cover'
								src='https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1617728688l/57650174.jpg'
								alt='Northern spy'
							/>
							<p className='book-title'>Northern Spy</p>
							</Link>
						</div>
						{/* Row 1 Col 3 */}
						<div className='book-item-search-new-release'>
						<Link to ="/books/AQB9rzld9GS253W">
							<img
								className='book-cover'
								src='https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1611244191l/40204619._SY475_.jpg'
								alt='Eternal'
							/>
							<p className='book-title'>Eternal</p>
						</Link>
						</div>
						{/* Row 1 Col 4 */}
						<div className='book-item-search-new-release'>
						<Link to ="/books/oJuLVQnMVBuUHxP">
							<img
								className='book-cover'
								src='https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1627489209l/53487237._SY475_.jpg'
								alt='A Swim in a Pond in the Rain'
							/>
							<p className='book-title'>A Swim in a Pond in the Rain</p>
							</Link>
						</div>
				</div>
			</div>

	);
}
