import React from 'react';
import Footer from './Footer';
import '../css/homepage.css';
import { useState, useRef, useEffect } from 'react';
import SearchBar, { SortBar } from './Search/SearchBar';
import { db, timestamp } from '../firebase';
import { FaSearch } from 'react-icons/fa';
import GenreButton from './Search/GenreButton';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

export default function Homepage() {
	const [signupMessage, setSignupMessage] = useState('');
	const [loading, setLoading] = useState(false);
	const [signedup, setSignedup] = useState(false);

	const signupInputRef = useRef();
	const messageInputRef = useRef();

	useEffect(() => {
		const searchInputBar = document.getElementById('homepage-search-bar-input');

		searchInputBar.addEventListener('keydown', (e) => {
			if (e.code === 'Enter') {
				handleSearchInputEnter(e);
			}
		});

		return searchInputBar.removeEventListener('keydown', (e) => {
			if (e.code === 'Enter') {
				handleSearchInputEnter(e);
			}
		});
	}, []);

	function validateEmail(email) {
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	function handleSubmitSignup(e) {
		console.log('button pressed.');
		e.preventDefault();
		setLoading(true);
		const inputEmail = signupInputRef.current.value;
		const inputMessage = messageInputRef.current.value;

		// check if the inputEmail is in the correct format
		if (!validateEmail(inputEmail)) {
			setLoading(false);
			setSignupMessage(
				'Oops! Email seems to be invalid, please re-enter a valid email.'
			);
			return;
		}

		db.collection('signup-submissions')
			.add({
				submittedAt: timestamp(),
				email: inputEmail,
				message: inputMessage,
			})
			.then(() => {
				setSignedup(true);
				setSignupMessage(
					"You're set! We will let you know when we're ready via: " + inputEmail
				);
				signupInputRef.current.value = '';
				messageInputRef.current.value = '';
			})
			.catch((error) => {
				console.log(error);
				setSignupMessage('Signup Failed: ', error);
			})
			.finally(() => {
				setLoading(false);
			});
	}

	function handleSearchInputEnter(e) {
		if (
			e.target.value === '' ||
			e.target.value === ' ' ||
			e.target.value === null ||
			e.target.value === undefined
		) {
			return;
		}

		window.location = '/search?q=' + e.target.value + '&p=1&g=all';
	}

	return (
		<>
			<Helmet>
				<title> nixpage | Home </title>
			</Helmet>
			<div className='homepage-main-box'>
				{signupMessage && (
					<div className='homepage-signup-message-container'>
						<div
							className={
								'homepage-signup-message' +
								(signupMessage.includes('Oops') ? '-error' : '')
							}>
							{signupMessage}
						</div>
					</div>
				)}

				<div className='homepage-box'>
					<div className='homepage-search-box'>
						<p className='homepage-search-big-title'>Get Started.</p>
						<p className='homepage-search-title'>Search and browse books</p>
						{/* <p className='homepage-search-subtitle'>Over 10,000 books</p> */}
						<div className='homepage-search-bar'>
							<input
								type='text'
								id='homepage-search-bar-input'
								className='homepage-search-bar-input'
								placeholder='Search'
							/>
							<FaSearch className='search-icon' />
							<div className='homepage-search-bar-input-enter'> Enter ↩ </div>
						</div>
					</div>
					<div className='homepage-top-box'>
						<p className='homepage-top-title'>Top 5 Books in Hong Kong</p>

						{/* CSS Grid */}

						<div className='homepage-top-box-content'>
							<div className='homepage-top-one'>
								<p className='top-five-number'>1</p>
								<div className='homepage-top-one-box'>
									<Link to='/books/kypoj5SqJ56a7Ug'>
										<img
											className='results-book-cover'
											src='https://d1w7fb2mkkr3kw.cloudfront.net/assets/images/book/lrg/9780/2419/9780241973035.jpg'
											alt='In order to live'
										/>
									</Link>
									<Link to='/books/kypoj5SqJ56a7Ug'>
										<p className='homepage-top-book-title'>In order to live</p>
										<p className='homepage-top-book-author'>Yeonmi Park</p>
									</Link>
								</div>
							</div>

							<div className='homepage-top-one'>
								<p className='top-five-number'>2</p>
								<div className='homepage-top-one-box'>
									<Link to='/books/AQB9rzld9GS253W'>
										<img
											className='results-book-cover'
											src='https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1611244191l/40204619._SY475_.jpg'
											alt='book photo'
										/>
										<p className='homepage-top-book-title'>Eternal</p>
										<p className='homepage-top-book-author'>Lisa Scottoline</p>
									</Link>
								</div>
							</div>

							<div className='homepage-top-one'>
								<p className='top-five-number'>3</p>
								<div className='homepage-top-one-box'>
									<Link to='/books/5uQhMf3TDc2iVeM'>
										<img
											className='results-book-cover'
											src='https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1595450825l/53529219._SY475_.jpg'
											alt='A time for mercy'
										/>
										<p className='homepage-top-book-title'>A time for mercy</p>
										<p className='homepage-top-book-author'>John Grisham</p>
									</Link>
								</div>
							</div>

							<div className='homepage-top-one'>
								<p className='top-five-number'>4</p>
								<div className='homepage-top-one-box'>
									<Link to='/books/BTFWBBA2h4Y4GAr'>
										<img
											className='results-book-cover'
											src='https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1564577305l/38820046._SY475_.jpg'
											alt='21 lessons for the 21st century'
										/>
										<p className='homepage-top-book-title'>
											21 lessons for the 21st century
										</p>
										<p className='homepage-top-book-author'>Yuval N Harari</p>
									</Link>
								</div>
							</div>

							<div className='homepage-top-one'>
								<p className='top-five-number'>5</p>
								<div className='homepage-top-one-box'>
									<Link to='/books/oJuLVQnMVBuUHxP'>
										<img
											className='results-book-cover'
											src='https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1627489209l/53487237._SY475_.jpg'
											alt='A Swim in a Pond in the Rain'
										/>
										<p className='homepage-top-book-title'>
											A Swim in a Pond in the Rain
										</p>
										<p className='homepage-top-book-author'>George Saunders</p>
									</Link>
								</div>
							</div>
						</div>
					</div>

					<div className='homepage-genre-box'>
						<p className='homepage-genre-title'>
							Adventure into undiscovered worlds
						</p>
						<p className='homepage-genre-subtitle'>Discover new book genres</p>
						<div className='homepage-genre-grid'>
							<GenreButton
								genre={'Art & Crafts'}
								icon={'/genreIcons/genre-arts.png'}
							/>
							<GenreButton
								genre={'Biography'}
								icon={'/genreIcons/genre-biography.png'}
							/>
							<GenreButton
								genre={'Business & Finance'}
								icon={'/genreIcons/genre-business.png'}
							/>
							<GenreButton
								genre={'Children'}
								icon={'/genreIcons/genre-children.png'}
							/>
							<GenreButton
								genre={'Fantasy'}
								icon={'/genreIcons/genre-fantasy.png'}
							/>
							<GenreButton
								genre={'Fiction'}
								icon={'/genreIcons/genre-fiction.png'}
							/>
							<GenreButton
								genre={'History'}
								icon={'/genreIcons/genre-history.png'}
							/>
							<GenreButton
								genre={'Literature'}
								icon={'/genreIcons/genre-literature.png'}
							/>
							<GenreButton
								genre={'Philosophy'}
								icon={'/genreIcons/genre-philosophy.png'}
							/>
							<GenreButton
								genre={'Religion'}
								icon={'/genreIcons/genre-religion.png'}
							/>
							<GenreButton
								genre={'Science'}
								icon={'/genreIcons/genre-science.png'}
							/>
							<GenreButton
								genre={'Travel'}
								icon={'/genreIcons/genre-travel.png'}
							/>
						</div>
					</div>
					<div className='homepage-contacts-box'>
						<div className='homepage-leave-contacts'>
							<p className='homepage-leave-contacts-title'>
								Let us know you're interested!
							</p>
							<div className='homepage-contacts-input'>
								<form onSubmit={handleSubmitSignup}>
									<input
										className='homepage-contacts'
										placeholder='Email Address'
										ref={signupInputRef}
									/>
									<input
										className='homepage-contacts homepage-message'
										placeholder='Message'
										ref={messageInputRef}
									/>
									<button
										className={
											'homepage-contacts-submit-button' +
											(loading ? '-disabled' : '')
										}
										type='submit'
										id='notify-us'
										disabled={loading || signedup}>
										{loading
											? 'Submitting...'
											: signedup
											? 'Signed Up!'
											: 'Notify Me'}
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
