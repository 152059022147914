import React, { useState, useEffect, useRef } from 'react'
import NavbarItem from './NavbarItem'

export default function NavbarHamburger(props) {
    const [isOpen, setIsOpen] = useState(false)
    const menuRef = useRef()
    const dropdownButtonRef = useRef()

    useEffect(() => {
        const handler = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target) && 
            dropdownButtonRef.current && !dropdownButtonRef.current.contains(event.target)) 
            setIsOpen(false)
        }
        document.addEventListener("mousedown", handler)

        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    const handleMenuToggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
        <li className="hamburger-dropdown">
            <button className="hamburger-icon" onClick={handleMenuToggle} ref={dropdownButtonRef}>
                |||
            </button>

        {isOpen && 
        <> 
            <ul className="hamburger-dropdown-ul" ref={menuRef}>
                {props.children}
            </ul>
        
        </>}
        </li>
        </>
    )
}
