import React from 'react'
import { FaRegFrown } from "react-icons/fa"
import { Helmet } from 'react-helmet'


export default function PageNotFound() {
    return (
        <div className="no-classes-assigned">
            <Helmet>
                <title> nixpage | Page Not Found</title>
            </Helmet>
            <h2> <FaRegFrown/> </h2>
            <label>
                Oops... it looks like this page does not exist yet!
            </label>
            <p>
                If this page should exist, please let us know.
            </p>

        </div>
    )
}
