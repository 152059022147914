import React from 'react';
import Footer from './Footer';
import '../css/aboutus.css';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import { NoEncryption } from '@material-ui/icons';
import { Helmet } from 'react-helmet';

<link
	rel='stylesheet'
	href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'
/>;
<link
	rel='stylesheet'
	href='https://fonts.googleapis.com/icon?family=Material+Icons'
/>;

export default function AboutUs() {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const theme = createTheme({
		typography: {
			fontSize: 22,
			height: 100,
			minWidth: 200,
			textAlign: 'left',
		},
		palette: {
			primary: {
				main: '#ffffff',
			},
		},
	});

	return (
		<div className='abous-us-main-page-container'>
			<Helmet>
				<title> nixpage | About Us </title>
			</Helmet>
			<div className='about-us-top-container'>
				<img
					className='about-us-title-image'
					src='/about-us-cover.jpg'
					alt='about us cover photo'
				/>
				<p className='about-us-title-text'>About nixpage</p>
			</div>
			<p className='about-us-description'>
				Launched in August 2021, Nixpage is a book discovery and sharing
				platform, providing users with a database of over 50,000 English and
				Chinese books. Based in Hong Kong, Nixpage provides an online space for
				readers in Hong Kong to interact, discuss and discover new books.
			</p>
			<p className='about-us-description'>
				Nixpage Classroom will be launched soon, a virtual library targeting
				education institutes in Hong Kong as a tool for schools to encourage
				students to explore the world of reading through a a student-friendly
				and easily managed online platform.
			</p>
			<p className='about-us-description'>
				At Nixpage, we are on a mission to welcome non-readers to discover the
				joy in reading, and enable readers to share their passion of reading
				with fellow readers.
			</p>
			<div className='about-us-values-container'>
				<h1 className='about-us-values-title'>Our Values</h1>
				<div className='about-us-values-container-2'>
					<ThemeProvider theme={theme}>
						<div className={classes.root}>
							<Tabs
								orientation='vertical'
								indicatorColor='primary'
								textColor='primary'
								variant='wrapped'
								value={value}
								minWidth='40%'
								onChange={handleChange}
								aria-label='Vertical tabs example'
								className={classes.tabs}>
								<Tab
									style={{ textTransform: 'none' }}
									minWidth='40%'
									label='Passion for Knowledge'
									{...a11yProps(0)}
								/>
								<Tab
									style={{ textTransform: 'none' }}
									label='Togetherness'
									{...a11yProps(1)}
								/>
								<Tab
									style={{ textTransform: 'none' }}
									label='Open-mindedness'
									{...a11yProps(2)}
								/>
								<Tab
									style={{ textTransform: 'none' }}
									label='Purpose-driven'
									{...a11yProps(3)}
								/>
								<Tab
									style={{ textTransform: 'none' }}
									label='Motivated'
									{...a11yProps(4)}
								/>
								<Tab
									style={{ textTransform: 'none' }}
									label='Courageous'
									{...a11yProps(5)}
								/>
							</Tabs>
							<TabPanel value={value} index={0} style={{ color: 'white' }}>
								<p>
									We always look into the reason behind events to do better in
									the future
								</p>
								<br />
								<p>We are not afraid to ask questions or question ourselves</p>
							</TabPanel>
							<TabPanel value={value} index={1} style={{ color: 'white' }}>
								<p>“Unity is strength. Synergy is might. Teamwork is power.”</p>
								<br />
								<p>
									Each team member fits together like puzzle pieces,
									complimenting each others’ strengths and filling each others’
									gaps.{' '}
								</p>
							</TabPanel>
							<TabPanel value={value} index={2} style={{ color: 'white' }}>
								<p>
									We embrace different ways of thinking and consider different
									perspectives to make the best decisions
								</p>
								<br />
								<p>
									We believe empathy is an important value for us to understand
									the beliefs and thought process of others.
								</p>
								<br />
								<p>
									We always put ourselves in others' shoes and try our best to
									provide them with what they need.
								</p>
							</TabPanel>
							<TabPanel value={value} index={3} style={{ color: 'white' }}>
								<p>
									We make our every move with planning and a clear goal in mind.{' '}
								</p>
							</TabPanel>
							<TabPanel value={value} index={4} style={{ color: 'white' }}>
								<p>
									We are always looking for ways to improve ourselves,
									motivation is the key to our constant grind.
								</p>
								<br />
								<p>
									With our vision in mind, it gives us a reason to push through
									our limits and conquer our dreams.
								</p>
							</TabPanel>
							<TabPanel value={value} index={5} style={{ color: 'white' }}>
								<p>
									“History never looks like history when you are living through
									it. It always looks confusing and messy, and it always looks
									uncomfortable.”
								</p>
								<br />
								<p>
									We encourage courage to speak up to share any ideas, knowing
									that everyone welcomes new perspectives.
								</p>
								<br />
								<p>
									Be assertive on what you want, be aggressive on turning your
									ideas into reality.
								</p>
							</TabPanel>
						</div>
					</ThemeProvider>
				</div>
			</div>
			<div className='about-us-team-container'>
				<h1 className='about-us-team-title'>Meet Our Team</h1>
				<div className='about-us-team-inner-container'>
					<div className='about-us-team-profile-container'>
						<img
							className='about-us-team-profile-photo'
							src='/teamPhotos/bobby.jpeg'
							alt='bobby profile photo'
						/>
						<p className='about-us-team-member-name'>Bobby Chan</p>
						<p className='about-us-team-member-title'>Co-founder</p>
						<p className='about-us-team-member-title'>CEO</p>
					</div>

					<div className='about-us-team-profile-container'>
						<img
							className='about-us-team-profile-photo'
							src='/teamPhotos/yoshi.jpeg'
							alt='bobby profile photo'
						/>
						<p className='about-us-team-member-name'>Yoshi Man</p>
						<p className='about-us-team-member-title'>Co-founder</p>
						<p className='about-us-team-member-title'>Product & Technology</p>
					</div>

					<div className='about-us-team-profile-container'>
						<img
							className='about-us-team-profile-photo'
							src='/teamPhotos/edson.jpeg'
							alt='bobby profile photo'
						/>
						<p className='about-us-team-member-name'>Edson Leung</p>
						<p className='about-us-team-member-title'>Co-founder</p>
						<p className='about-us-team-member-title'>Research & Development</p>
					</div>

					<div className='about-us-team-profile-container'>
						<img
							className='about-us-team-profile-photo'
							src='/teamPhotos/dylan.jpeg'
							alt='bobby profile photo'
						/>
						<p className='about-us-team-member-name'>Dylan Ho</p>
						<p className='about-us-team-member-title'>Co-founder</p>
						<p className='about-us-team-member-title'>Design & Marketing</p>
					</div>
				</div>
			</div>
		</div>
	);
}

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: 'transparent',
		display: 'flex',
	},
	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`,
		minWidth: 180,
	},
}));
