import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { ga } from 'react-ga'
import { useAuth } from '../../contexts/AuthContext'
import Sidebar from '../Sidebar/Sidebar'
import SidebarItem from '../Sidebar/SidebarItem'
import SearchBar from './SearchBar'
import '../../css/search.css'
import SearchNewReleases from './SearchNewReleases'
import SearchResults from './SearchResults'
import {searchClient, index}  from '../../algolia'
import SearchResultsPageBar from './SearchResultsPageBar'
import FilterBar from './FilterBar'
import { Helmet } from 'react-helmet'



export default function Search() {
	const location = useLocation()
	const history = useHistory()
	const [ query, setQuery ] = useState("")
	const [ currentPage, setCurrentPage ] = useState(1)
	const [ totalPages, setTotalPages ] = useState(1)
	const [ totalHits, setTotalHits ] = useState(0)
	const [ searchResults, setSearchResults ] = useState([])
	const [ selectedGenre, setSelectedGenre ] = useState('all')
	const [ facetResults , setFacetResults] = useState({})
	const [ steadyFacet , setSteadyFacet] = useState({})

	const searchInputRef = useRef()
	
	useMemo( () => {
		setSteadyFacet(facetResults)
	}, [query])

	useEffect( () => {
		// console.log("URL Updated: Retrieving from Algolia.")

		// console.log("Handling ga script for URL: /", location.search)
		ga('send', 'pageview', location.pathname)

		if(query && currentPage > 0){
			let params = {
				page: currentPage ? currentPage - 1: 0,
				hitsPerPage: 10,
				facets:['genre']
			}

			const parsedGenre = selectedGenre ? selectedGenre.split('xx').filter(x => {return x!==""}) : ["all"]

			let filterString = ""
			parsedGenre.forEach( (x, i) => {
				filterString += ('genre: "' + x + '"' + ((i == parsedGenre.length - 1) ?'' : ' OR ')) 
			})

			filterString.replace(" ", "%20")
			filterString.replace("&", "%26")
			// console.log("parsedGenre:", parsedGenre)
			// console.log("filterString:", filterString)

			if(selectedGenre !== 'all'){
				params['filters'] = filterString
			} else{
				delete params['filters'] 
			}

			const adjustedQuery = query === '*' ? '': query
			index.search(query , params).then( ({hits, nbPages, nbHits, facets}) => {
				setSearchResults(hits)
				setTotalPages(nbPages)
				setTotalHits(nbHits)
				setFacetResults(facets)
			}) .catch( error => { console.log( "Error getting results:", error)})
		} else{
			setSearchResults([])
			setTotalPages(1)
			setTotalHits(0)
			setSelectedGenre('all')
			setFacetResults({})
		}
	}, [location])

	useEffect( () => {
		const q = (location && location.search )? queryString.parse( location.search).q : null
		const p = (location && location.search )? queryString.parse( location.search).p : 1
		const g = (location && location.search )? queryString.parse( location.search).g : "all"
		setQuery(q)
		setCurrentPage(p)
		setSelectedGenre(g)
		if(q && p && g){
			history.push("/search?q=" + q + "&p="+p + "&g=" +g) 
			setCurrentPage(p)
			searchInputRef.current.value=q
			setSelectedGenre(g)
		}
		else if(q && !p && g){
			history.push("/search?q=" + q + "&p=1" + "&g=" +g) 
			setCurrentPage(1)
			searchInputRef.current.value=q
			setSelectedGenre(g)
		}
		else if(q && p && !g){
			history.push("/search?q=" + q + "&p=" + p + "&g=all") 
			setCurrentPage(p)
			searchInputRef.current.value=q
			setSelectedGenre('all')
		}
	}, [])



	return (
		<div className='box'>
			<Helmet>
				<title> nixpage | Search </title>
			</Helmet>
			<SearchBar history={history} location={location} searchInputRef={searchInputRef} setQuery={setQuery} setCurrentPage={setCurrentPage} setSelectedGenre={setSelectedGenre}/>

			{!query && <SearchNewReleases />}

			{query && 

				<>
					<FilterBar 
					facetResults={steadyFacet}
					selectedGenre={selectedGenre} 
					setSelectedGenre={setSelectedGenre}
					setCurrentPage={setCurrentPage}
					history={history}
					/>
					<div className="search-info-box">
						{"There" + (totalHits == 1 ? " is " : " are ") + totalHits + " result" + (totalHits == 1 ? "" : "s")}
					</div>
					<SearchResults 
						searchResults={searchResults}
					/>
					<SearchResultsPageBar 
						query={query} 
						totalPages={totalPages} 
						currentPage={currentPage} 
						setCurrentPage={setCurrentPage} 
						history={history}
					/>
				</>
			
			}


		</div>
	);
}


