import React from 'react'

export default function SearchResultsPageBar( { query, totalPages, currentPage, setCurrentPage, history } ) {
    let pageButtons = []

    function handleClickPageButton(e){
        if(e.target.value == currentPage) return
        // console.log(e.target.value , "vs", currentPage)
        setCurrentPage(e.target.value)
        history.push("/search?q=" + query + "&p=" + e.target.value)

    }
    
    if(currentPage > 1){
        pageButtons.push(
            <button 
            className="search-page-buttons" 
            onClick={handleClickPageButton} 
            value={parseInt(currentPage)- 1}
            key={"search-page-button-previous"}> 
                Previous
            </button>
        )
    }
    // console.log("currentPage:", currentPage, "totalPages:", totalPages)

    const pageStarting = (parseInt(currentPage) - 4 < 0) ? 0 : (parseInt(currentPage) - 4)
    const pageEnding = (parseInt(currentPage) + 3 > parseInt(totalPages)) ? parseInt(totalPages) : (parseInt(currentPage) + 3)

    for(let i = pageStarting ; i < pageEnding ; i++){
        pageButtons.push( 
            <button 
            className={"search-page-buttons" + (parseInt(currentPage) == (i+1) ? "-active" : "" )}
            onClick={handleClickPageButton} 
            value={i+1}
            key={"search-page-button-" + (i+1)}> 
                {i+1}
            </button>
         )

    }

    if(currentPage < totalPages){
        pageButtons.push(
            <button 
            className="search-page-buttons" 
            onClick={handleClickPageButton} 
            value={parseInt(currentPage) + 1 }
            key={"search-page-button-next"}> 
                Next
            </button>
        )
    }

    return (
        <div className="search-page-buttons-bar">
            {
                pageButtons
            }
        </div>
    )
}
