const ASSIGNMENTS_DATA = 
[
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "Number",
      "grade": 100,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Letter",
      "grade": 96,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "Letter",
      "grade": 83,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Letter",
      "grade": 86,
      "feedback": "Not the greatest",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "Letter",
      "grade": 80,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Letter",
      "grade": 96,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Letter",
      "grade": 75,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Letter",
      "grade": 65,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "Number",
      "grade": 81,
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Letter",
      "grade": 61,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "Number",
      "grade": 82,
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Number",
      "grade": 68,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Number",
      "grade": 92,
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Letter",
      "grade": 94,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Number",
      "grade": 69,
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Letter",
      "grade": 61,
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "Number",
      "grade": 90,
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Letter",
      "grade": 80,
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "Letter",
      "grade": 80,
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "Not the greatest",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Letter",
      "grade": 90,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "Number",
      "grade": 63,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Number",
      "grade": 93,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "Letter",
      "grade": 87,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Number",
      "grade": 83,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "Letter",
      "grade": 85,
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Number",
      "grade": 88,
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "Letter",
      "grade": 85,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Number",
      "grade": 71,
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Number",
      "grade": 87,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Number",
      "grade": 95,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Letter",
      "grade": 87,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "Letter",
      "grade": 94,
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "Letter",
      "grade": 69,
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "Number",
      "grade": 76,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "Number",
      "grade": 91,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "Letter",
      "grade": 76,
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "Letter",
      "grade": 98,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "Letter",
      "grade": 71,
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Letter",
      "grade": 72,
      "feedback": "Not the greatest",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Letter",
      "grade": 91,
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "Letter",
      "grade": 75,
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Letter",
      "grade": 66,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "Letter",
      "grade": 87,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "Letter",
      "grade": 81,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "Number",
      "grade": 81,
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "Not the greatest",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "Letter",
      "grade": 99,
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "Letter",
      "grade": 92,
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Number",
      "grade": 86,
      "feedback": "Not the greatest",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Number",
      "grade": 63,
      "feedback": "Not the greatest",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "Number",
      "grade": 72,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Number",
      "grade": 70,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Number",
      "grade": 82,
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "Number",
      "grade": 85,
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Number",
      "grade": 69,
      "feedback": "Not the greatest",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "Letter",
      "grade": 72,
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "Letter",
      "grade": 73,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "Number",
      "grade": 79,
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "Letter",
      "grade": 66,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "Not the greatest",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Number",
      "grade": 83,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "Number",
      "grade": 86,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Number",
      "grade": 64,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "Letter",
      "grade": 80,
      "feedback": "Not the greatest",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "Letter",
      "grade": 61,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Letter",
      "grade": 61,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "Number",
      "grade": 60,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Letter",
      "grade": 79,
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "Number",
      "grade": 63,
      "feedback": "Not the greatest",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "Letter",
      "grade": 71,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "Not the greatest",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Number",
      "grade": 66,
      "feedback": "Not the greatest",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Number",
      "grade": 92,
      "feedback": "Not the greatest",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Letter",
      "grade": 71,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Letter",
      "grade": 66,
      "feedback": "Not the greatest",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Letter",
      "grade": 81,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Number",
      "grade": 99,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "Number",
      "grade": 91,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "Number",
      "grade": 72,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Number",
      "grade": 73,
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Letter",
      "grade": 81,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "Number",
      "grade": 61,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Letter",
      "grade": 60,
      "feedback": "Not the greatest",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Letter",
      "grade": 70,
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Number",
      "grade": 85,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "Number",
      "grade": 94,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "Letter",
      "grade": 95,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "Letter",
      "grade": 91,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Number",
      "grade": 94,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "Number",
      "grade": 83,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Letter",
      "grade": 100,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "Number",
      "grade": 79,
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Letter",
      "grade": 73,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Number",
      "grade": 90,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Letter",
      "grade": 88,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "Letter",
      "grade": 87,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Letter",
      "grade": 64,
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Number",
      "grade": 100,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "Letter",
      "grade": 99,
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "Number",
      "grade": 99,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Number",
      "grade": 65,
      "feedback": "Not the greatest",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Letter",
      "grade": 66,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Number",
      "grade": 90,
      "feedback": "Not the greatest",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Letter",
      "grade": 77,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Letter",
      "grade": 82,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "Number",
      "grade": 100,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "Letter",
      "grade": 60,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "Number",
      "grade": 60,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Letter",
      "grade": 92,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Letter",
      "grade": 71,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "Letter",
      "grade": 83,
      "feedback": "Not the greatest",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Number",
      "grade": 94,
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "Number",
      "grade": 96,
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "Letter",
      "grade": 62,
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Number",
      "grade": 79,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "Letter",
      "grade": 91,
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "Number",
      "grade": 74,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "Number",
      "grade": 96,
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "Number",
      "grade": 64,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "Number",
      "grade": 64,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Number",
      "grade": 64,
      "feedback": "Not the greatest",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "Letter",
      "grade": 88,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Letter",
      "grade": 99,
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Letter",
      "grade": 67,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "Letter",
      "grade": 95,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Letter",
      "grade": 84,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "Number",
      "grade": 100,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "Not the greatest",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Letter",
      "grade": 65,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "Number",
      "grade": 84,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Letter",
      "grade": 62,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Number",
      "grade": 88,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Letter",
      "grade": 94,
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "Number",
      "grade": 96,
      "feedback": "Not the greatest",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "Not the greatest",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "Number",
      "grade": 70,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Letter",
      "grade": 80,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "Number",
      "grade": 72,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Number",
      "grade": 99,
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "Number",
      "grade": 82,
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "Number",
      "grade": 71,
      "feedback": "Not the greatest",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "Number",
      "grade": 87,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "You can probably write more",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "Number",
      "grade": 87,
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "Number",
      "grade": 73,
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "4RqLGdm3GEYVxPx5DTt5vzDEJQI2",
      "classCode": "1CH",
      "dueDate": "27/7/2019",
      "assignedDate": "13/7/2019",
      "submitDate": "26/7/2019",
      "gradedDate": "2/8/2019",
      "gradeType": "Number",
      "grade": 92,
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "3/4/2020",
      "assignedDate": "20/3/2020",
      "submitDate": "2/4/2020",
      "gradedDate": "9/4/2020",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "Great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "UaTk3UfAcYdjZzAdvd1o8loyzGA3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "Lots to improve still, but meh.",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "10/10/2020",
      "assignedDate": "26/9/2020",
      "submitDate": "9/10/2020",
      "gradedDate": "16/10/2020",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "DKVLqWFp1jRIG4bX1ay7pSz9f9C3",
      "student": "lM7MmNlXF7cnO2CwQpWMW8tlhl93",
      "classCode": "6EM",
      "dueDate": "11/3/2019",
      "assignedDate": "25/2/2019",
      "submitDate": "10/3/2019",
      "gradedDate": "17/3/2019",
      "gradeType": "P/F",
      "grade": "Fail",
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "so4e9nAZR7MePGryI9u4f0zMze73",
      "student": "whR4Vo01rkfPf30eMMTssT0ep1w1",
      "classCode": "4PF",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "Not so great",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    },
    {
      "teacher": "7daokJwGL3RIPq4GE7jpaIEqTmM2",
      "student": "XUvdf5hFL0eofn1p3wXaE3Skt3i1",
      "classCode": "6EM",
      "dueDate": "8/6/2019",
      "assignedDate": "25/5/2019",
      "submitDate": "7/6/2019",
      "gradedDate": "14/6/2019",
      "gradeType": "P/F",
      "grade": "Pass",
      "feedback": "Excellent work",
      "archived": "FALSE",
      "book": "{ bookName: TKAM, author: Harper Lee, genre: Fiction}"
    }
  ]

const USERS_DATA = 
[{
    uid: "dummy1",
    userType: "Student",
    displayName: "Leonardo Blevins",
    photoURL: "https://picsum.photos/200'",
    email: "leonardo.blevins@mail.com",
    biography: "I often see the time 11:11 or 12:34 on clocks.",
    books: {
        favourites: [{
            bookId: "46",
            bookName: "Savage Feet",
            author: "Judith Estes",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 08 2018"
        }, {
            bookId: "42",
            bookName: "The Evil Rivals",
            author: "Izabelle Benjamin",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 28 2020"
        }, {
            bookId: "9",
            bookName: "Short Counsel",
            author: "Roy Nairn",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 10 2016"
        }],
        readHistory: [{
            bookId: "43",
            bookName: "A Rat's Salvation",
            author: "Ananya Gay",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 30 2019",
                page: 232
            }
        }, {
            bookId: "46",
            bookName: "Savage Feet",
            author: "Judith Estes",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 08 2018",
                page: 75
            }
        }, {
            bookId: "50",
            bookName: "Single Templar",
            author: "Kayne Driscoll",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "October 21 2018",
                page: 225
            }
        }],
        wishList: [{
            bookId: "49",
            bookName: "Cheyenne Malone",
            author: "Ellie Shaw",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "24",
            bookName: "The Girl in Transylvania",
            author: "Spike Rice",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "35",
            bookName: "A Shadow of Season",
            author: "Dilara Haley",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy2",
    userType: "Student",
    displayName: "Conall Carlson",
    photoURL: "https://picsum.photos/200'",
    email: "conall.carlson@mail.com",
    biography: "The blue parrot drove by the hitchhiking mongoose.",
    books: {
        favourites: [{
            bookId: "32",
            bookName: "Bullets in the Sea",
            author: "Lidia Rhodes",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 18 2015"
        }, {
            bookId: "11",
            bookName: "The Hurricane Keys",
            author: "Hugo Werner",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 25 2017"
        }, {
            bookId: "20",
            bookName: "Brighton Grail",
            author: "Reanna Clayton",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "May 05 2018"
        }],
        readHistory: [{
            bookId: "25",
            bookName: "Maigret and the Pickpocket",
            author: "Jonny Simon",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 09 2018",
                page: 56
            }
        }, {
            bookId: "46",
            bookName: "Savage Feet",
            author: "Judith Estes",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 08 2018",
                page: 75
            }
        }, {
            bookId: "25",
            bookName: "Maigret and the Pickpocket",
            author: "Jonny Simon",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 09 2018",
                page: 56
            }
        }],
        wishList: [{
            bookId: "34",
            bookName: "Curtain of Mystery",
            author: "Sian Huff",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "36",
            bookName: "The Spirit of the Bow",
            author: "Cherry Valencia",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "4",
            bookName: "Point Scorned",
            author: "Reanne Kennedy",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy3",
    userType: "Student",
    displayName: "Rochelle Paine",
    photoURL: "https://picsum.photos/200'",
    email: "rochelle.paine@mail.com",
    biography: "The sun had set and so had his dreams.",
    books: {
        favourites: [{
            bookId: "12",
            bookName: "Amber and Glory",
            author: "Ava-Mai Combs",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "May 22 2018"
        }, {
            bookId: "47",
            bookName: "Witch's Debt",
            author: "Marwah Cornish",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "June 17 2018"
        }, {
            bookId: "40",
            bookName: "Fine Karma",
            author: "Sofia Pratt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "December 16 2017"
        }],
        readHistory: [{
            bookId: "50",
            bookName: "Single Templar",
            author: "Kayne Driscoll",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "October 21 2018",
                page: 225
            }
        }, {
            bookId: "6",
            bookName: "The Laugh of Eternity",
            author: "Aron Romero",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 04 2017",
                page: 46
            }
        }, {
            bookId: "3",
            bookName: "The Whiskey Reality",
            author: "Carolyn Cano",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "October 12 2018",
                page: 45
            }
        }],
        wishList: [{
            bookId: "12",
            bookName: "Amber and Glory",
            author: "Ava-Mai Combs",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "33",
            bookName: "Pride of Flames",
            author: "Cayden Donovan",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "33",
            bookName: "Pride of Flames",
            author: "Cayden Donovan",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy4",
    userType: "Student",
    displayName: "Maha Dalton",
    photoURL: "https://picsum.photos/200'",
    email: "maha.dalton@mail.com",
    biography: "Peanuts don't grow on trees, but cashews do.",
    books: {
        favourites: [{
            bookId: "13",
            bookName: "The Piano Hospital",
            author: "Tonya Dyer",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "June 24 2017"
        }, {
            bookId: "26",
            bookName: "Blunt Abyss",
            author: "Ella-Mae Spencer",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "February 21 2020"
        }, {
            bookId: "27",
            bookName: "The Office Eyes",
            author: "Aminah Barnes",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 13 2020"
        }],
        readHistory: [{
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 09 2017",
                page: 181
            }
        }, {
            bookId: "24",
            bookName: "The Girl in Transylvania",
            author: "Spike Rice",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 11 2017",
                page: 298
            }
        }, {
            bookId: "25",
            bookName: "Maigret and the Pickpocket",
            author: "Jonny Simon",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 09 2018",
                page: 56
            }
        }],
        wishList: [{
            bookId: "48",
            bookName: "A Sister's Salvation",
            author: "Opal Cummings",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "4",
            bookName: "Point Scorned",
            author: "Reanne Kennedy",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "13",
            bookName: "The Piano Hospital",
            author: "Tonya Dyer",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy5",
    userType: "Student",
    displayName: "Osian Snow",
    photoURL: "https://picsum.photos/200'",
    email: "osian.snow@mail.com",
    biography: "Various sea birds are elegant, but nothing is as elegant as a gliding pelican.",
    books: {
        favourites: [{
            bookId: "32",
            bookName: "Bullets in the Sea",
            author: "Lidia Rhodes",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 18 2015"
        }, {
            bookId: "2",
            bookName: "Vineyard Waves",
            author: "Sinead Morgan",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "September 29 2018"
        }, {
            bookId: "8",
            bookName: "Batman's Fortress",
            author: "Anastazja Shepard",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 19 2017"
        }],
        readHistory: [{
            bookId: "27",
            bookName: "The Office Eyes",
            author: "Aminah Barnes",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 13 2020",
                page: 13
            }
        }, {
            bookId: "6",
            bookName: "The Laugh of Eternity",
            author: "Aron Romero",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 04 2017",
                page: 46
            }
        }, {
            bookId: "31",
            bookName: "Martian Baby",
            author: "Thierry Holman",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 28 2015",
                page: 147
            }
        }],
        wishList: [{
            bookId: "48",
            bookName: "A Sister's Salvation",
            author: "Opal Cummings",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "25",
            bookName: "Maigret and the Pickpocket",
            author: "Jonny Simon",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "36",
            bookName: "The Spirit of the Bow",
            author: "Cherry Valencia",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy6",
    userType: "Student",
    displayName: "Bertha Burton",
    photoURL: "https://picsum.photos/200'",
    email: "bertha.burton@mail.com",
    biography: "The pigs were insulted that they were named hamburgers.",
    books: {
        favourites: [{
            bookId: "6",
            bookName: "The Laugh of Eternity",
            author: "Aron Romero",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 04 2017"
        }, {
            bookId: "33",
            bookName: "Pride of Flames",
            author: "Cayden Donovan",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "August 21 2019"
        }, {
            bookId: "8",
            bookName: "Batman's Fortress",
            author: "Anastazja Shepard",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 19 2017"
        }],
        readHistory: [{
            bookId: "50",
            bookName: "Single Templar",
            author: "Kayne Driscoll",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "October 21 2018",
                page: 225
            }
        }, {
            bookId: "22",
            bookName: "The Dragon and the Tiger",
            author: "Alex House",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "November 27 2019",
                page: 224
            }
        }, {
            bookId: "34",
            bookName: "Curtain of Mystery",
            author: "Sian Huff",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 02 2016",
                page: 110
            }
        }],
        wishList: [{
            bookId: "32",
            bookName: "Bullets in the Sea",
            author: "Lidia Rhodes",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "22",
            bookName: "The Dragon and the Tiger",
            author: "Alex House",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "6",
            bookName: "The Laugh of Eternity",
            author: "Aron Romero",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy7",
    userType: "Student",
    displayName: "Sam O'Gallagher",
    photoURL: "https://picsum.photos/200'",
    email: "sam.o'gallagher@mail.com",
    biography: "Edith could decide if she should paint her teeth or brush her nails.",
    books: {
        favourites: [{
            bookId: "40",
            bookName: "Fine Karma",
            author: "Sofia Pratt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "December 16 2017"
        }, {
            bookId: "8",
            bookName: "Batman's Fortress",
            author: "Anastazja Shepard",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 19 2017"
        }, {
            bookId: "1",
            bookName: "Mystery in Trouble",
            author: "Alejandro Oakley",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "August 24 2019"
        }],
        readHistory: [{
            bookId: "25",
            bookName: "Maigret and the Pickpocket",
            author: "Jonny Simon",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 09 2018",
                page: 56
            }
        }, {
            bookId: "24",
            bookName: "The Girl in Transylvania",
            author: "Spike Rice",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 11 2017",
                page: 298
            }
        }, {
            bookId: "38",
            bookName: "The Voice of the Matter",
            author: "Libbie Aguilar",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 14 2020",
                page: 295
            }
        }],
        wishList: [{
            bookId: "29",
            bookName: "Change of Captivity",
            author: "Landon Clements",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "1",
            bookName: "Mystery in Trouble",
            author: "Alejandro Oakley",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "10",
            bookName: "The Crown of the Sunset",
            author: "Sommer Keith",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy8",
    userType: "Student",
    displayName: "Danielle Rush",
    photoURL: "https://picsum.photos/200'",
    email: "danielle.rush@mail.com",
    biography: "Her daily goal was to improve on yesterday.",
    books: {
        favourites: [{
            bookId: "44",
            bookName: "Spare Signs",
            author: "Moshe Bowden",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 30 2019"
        }, {
            bookId: "20",
            bookName: "Brighton Grail",
            author: "Reanna Clayton",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "May 05 2018"
        }, {
            bookId: "38",
            bookName: "The Voice of the Matter",
            author: "Libbie Aguilar",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 14 2020"
        }],
        readHistory: [{
            bookId: "14",
            bookName: "Country at Q",
            author: "Shanna Raymond",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 04 2018",
                page: 30
            }
        }, {
            bookId: "25",
            bookName: "Maigret and the Pickpocket",
            author: "Jonny Simon",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 09 2018",
                page: 56
            }
        }, {
            bookId: "5",
            bookName: "Prey for a Doctor",
            author: "Brent Shelton",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 06 2016",
                page: 218
            }
        }],
        wishList: [{
            bookId: "25",
            bookName: "Maigret and the Pickpocket",
            author: "Jonny Simon",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "25",
            bookName: "Maigret and the Pickpocket",
            author: "Jonny Simon",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "3",
            bookName: "The Whiskey Reality",
            author: "Carolyn Cano",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy9",
    userType: "Student",
    displayName: "Jakob Rasmussen",
    photoURL: "https://picsum.photos/200'",
    email: "jakob.rasmussen@mail.com",
    biography: "They ran around the corner to find that they had traveled back in time.",
    books: {
        favourites: [{
            bookId: "17",
            bookName: "The Wilder Walls",
            author: "Jamie Montgomery",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 27 2015"
        }, {
            bookId: "27",
            bookName: "The Office Eyes",
            author: "Aminah Barnes",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 13 2020"
        }, {
            bookId: "7",
            bookName: "Echoes of Homicide",
            author: "Kaden Massey",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "November 10 2015"
        }],
        readHistory: [{
            bookId: "1",
            bookName: "Mystery in Trouble",
            author: "Alejandro Oakley",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "August 24 2019",
                page: 244
            }
        }, {
            bookId: "9",
            bookName: "Short Counsel",
            author: "Roy Nairn",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 10 2016",
                page: 215
            }
        }, {
            bookId: "48",
            bookName: "A Sister's Salvation",
            author: "Opal Cummings",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "October 13 2017",
                page: 41
            }
        }],
        wishList: [{
            bookId: "7",
            bookName: "Echoes of Homicide",
            author: "Kaden Massey",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "48",
            bookName: "A Sister's Salvation",
            author: "Opal Cummings",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "40",
            bookName: "Fine Karma",
            author: "Sofia Pratt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy10",
    userType: "Student",
    displayName: "Buster Arnold",
    photoURL: "https://picsum.photos/200'",
    email: "buster.arnold@mail.com",
    biography: "Chocolate covered crickets were his favorite snack.",
    books: {
        favourites: [{
            bookId: "27",
            bookName: "The Office Eyes",
            author: "Aminah Barnes",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 13 2020"
        }, {
            bookId: "47",
            bookName: "Witch's Debt",
            author: "Marwah Cornish",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "June 17 2018"
        }, {
            bookId: "18",
            bookName: "The Handsome Thorn",
            author: "Ivy Singleton",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 02 2020"
        }],
        readHistory: [{
            bookId: "34",
            bookName: "Curtain of Mystery",
            author: "Sian Huff",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 02 2016",
                page: 110
            }
        }, {
            bookId: "36",
            bookName: "The Spirit of the Bow",
            author: "Cherry Valencia",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "November 30 2015",
                page: 351
            }
        }, {
            bookId: "1",
            bookName: "Mystery in Trouble",
            author: "Alejandro Oakley",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "August 24 2019",
                page: 244
            }
        }],
        wishList: [{
            bookId: "48",
            bookName: "A Sister's Salvation",
            author: "Opal Cummings",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "10",
            bookName: "The Crown of the Sunset",
            author: "Sommer Keith",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "24",
            bookName: "The Girl in Transylvania",
            author: "Spike Rice",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy11",
    userType: "Student",
    displayName: "Subhaan Hayes",
    photoURL: "https://picsum.photos/200'",
    email: "subhaan.hayes@mail.com",
    biography: "It caught him off guard that space smelled of seared steak.",
    books: {
        favourites: [{
            bookId: "50",
            bookName: "Single Templar",
            author: "Kayne Driscoll",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 21 2018"
        }, {
            bookId: "42",
            bookName: "The Evil Rivals",
            author: "Izabelle Benjamin",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 28 2020"
        }, {
            bookId: "7",
            bookName: "Echoes of Homicide",
            author: "Kaden Massey",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "November 10 2015"
        }],
        readHistory: [{
            bookId: "2",
            bookName: "Vineyard Waves",
            author: "Sinead Morgan",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 29 2018",
                page: 206
            }
        }, {
            bookId: "5",
            bookName: "Prey for a Doctor",
            author: "Brent Shelton",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 06 2016",
                page: 218
            }
        }, {
            bookId: "22",
            bookName: "The Dragon and the Tiger",
            author: "Alex House",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "November 27 2019",
                page: 224
            }
        }],
        wishList: [{
            bookId: "30",
            bookName: "A Tangled Balance",
            author: "Yasemin Hart",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "10",
            bookName: "The Crown of the Sunset",
            author: "Sommer Keith",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "10",
            bookName: "The Crown of the Sunset",
            author: "Sommer Keith",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy12",
    userType: "Student",
    displayName: "Ashley Wright",
    photoURL: "https://picsum.photos/200'",
    email: "ashley.wright@mail.com",
    biography: "Most shark attacks occur about 10 feet from the beach since that's where the people are.",
    books: {
        favourites: [{
            bookId: "22",
            bookName: "The Dragon and the Tiger",
            author: "Alex House",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "November 27 2019"
        }, {
            bookId: "32",
            bookName: "Bullets in the Sea",
            author: "Lidia Rhodes",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 18 2015"
        }, {
            bookId: "34",
            bookName: "Curtain of Mystery",
            author: "Sian Huff",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 02 2016"
        }],
        readHistory: [{
            bookId: "43",
            bookName: "A Rat's Salvation",
            author: "Ananya Gay",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 30 2019",
                page: 232
            }
        }, {
            bookId: "36",
            bookName: "The Spirit of the Bow",
            author: "Cherry Valencia",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "November 30 2015",
                page: 351
            }
        }, {
            bookId: "38",
            bookName: "The Voice of the Matter",
            author: "Libbie Aguilar",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 14 2020",
                page: 295
            }
        }],
        wishList: [{
            bookId: "11",
            bookName: "The Hurricane Keys",
            author: "Hugo Werner",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "5",
            bookName: "Prey for a Doctor",
            author: "Brent Shelton",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "22",
            bookName: "The Dragon and the Tiger",
            author: "Alex House",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy13",
    userType: "Student",
    displayName: "Ahsan Curran",
    photoURL: "https://picsum.photos/200'",
    email: "ahsan.curran@mail.com",
    biography: "There's a message for you if you look up.",
    books: {
        favourites: [{
            bookId: "21",
            bookName: "A Deadly Bastard",
            author: "Avani Flores",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "May 21 2015"
        }, {
            bookId: "43",
            bookName: "A Rat's Salvation",
            author: "Ananya Gay",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 30 2019"
        }, {
            bookId: "31",
            bookName: "Martian Baby",
            author: "Thierry Holman",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 28 2015"
        }],
        readHistory: [{
            bookId: "13",
            bookName: "The Piano Hospital",
            author: "Tonya Dyer",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "June 24 2017",
                page: 14
            }
        }, {
            bookId: "44",
            bookName: "Spare Signs",
            author: "Moshe Bowden",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 30 2019",
                page: 234
            }
        }, {
            bookId: "10",
            bookName: "The Crown of the Sunset",
            author: "Sommer Keith",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 27 2016",
                page: 205
            }
        }],
        wishList: [{
            bookId: "41",
            bookName: "Breathing Moonlight",
            author: "Ayaan Brandt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "47",
            bookName: "Witch's Debt",
            author: "Marwah Cornish",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy14",
    userType: "Student",
    displayName: "Israel Wilson",
    photoURL: "https://picsum.photos/200'",
    email: "israel.wilson@mail.com",
    biography: "The virus had powers none of us knew existed.",
    books: {
        favourites: [{
            bookId: "19",
            bookName: "The Tulip Gift",
            author: "Barry Choi",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "September 06 2016"
        }, {
            bookId: "1",
            bookName: "Mystery in Trouble",
            author: "Alejandro Oakley",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "August 24 2019"
        }, {
            bookId: "17",
            bookName: "The Wilder Walls",
            author: "Jamie Montgomery",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 27 2015"
        }],
        readHistory: [{
            bookId: "37",
            bookName: "Risk of Illusion",
            author: "Atlas Guthrie",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 08 2016",
                page: 43
            }
        }, {
            bookId: "43",
            bookName: "A Rat's Salvation",
            author: "Ananya Gay",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 30 2019",
                page: 232
            }
        }, {
            bookId: "45",
            bookName: "The Daisy Godfather",
            author: "Hayden Malone",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "December 28 2014",
                page: 64
            }
        }],
        wishList: [{
            bookId: "2",
            bookName: "Vineyard Waves",
            author: "Sinead Morgan",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "32",
            bookName: "Bullets in the Sea",
            author: "Lidia Rhodes",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "21",
            bookName: "A Deadly Bastard",
            author: "Avani Flores",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy15",
    userType: "Student",
    displayName: "Alissia Ireland",
    photoURL: "https://picsum.photos/200'",
    email: "alissia.ireland@mail.com",
    biography: "Courage and stupidity were all he had.",
    books: {
        favourites: [{
            bookId: "45",
            bookName: "The Daisy Godfather",
            author: "Hayden Malone",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "December 28 2014"
        }, {
            bookId: "36",
            bookName: "The Spirit of the Bow",
            author: "Cherry Valencia",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "November 30 2015"
        }, {
            bookId: "40",
            bookName: "Fine Karma",
            author: "Sofia Pratt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "December 16 2017"
        }],
        readHistory: [{
            bookId: "35",
            bookName: "A Shadow of Season",
            author: "Dilara Haley",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 31 2019",
                page: 104
            }
        }, {
            bookId: "30",
            bookName: "A Tangled Balance",
            author: "Yasemin Hart",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 15 2018",
                page: 403
            }
        }, {
            bookId: "47",
            bookName: "Witch's Debt",
            author: "Marwah Cornish",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "June 17 2018",
                page: 20
            }
        }],
        wishList: [{
            bookId: "34",
            bookName: "Curtain of Mystery",
            author: "Sian Huff",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "37",
            bookName: "Risk of Illusion",
            author: "Atlas Guthrie",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "23",
            bookName: "Tarzan and the Stone",
            author: "Nada Hoover",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy16",
    userType: "Student",
    displayName: "Myron Durham",
    photoURL: "https://picsum.photos/200'",
    email: "myron.durham@mail.com",
    biography: "Too many prisons have become early coffins.",
    books: {
        favourites: [{
            bookId: "10",
            bookName: "The Crown of the Sunset",
            author: "Sommer Keith",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 27 2016"
        }, {
            bookId: "6",
            bookName: "The Laugh of Eternity",
            author: "Aron Romero",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 04 2017"
        }, {
            bookId: "24",
            bookName: "The Girl in Transylvania",
            author: "Spike Rice",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "September 11 2017"
        }],
        readHistory: [{
            bookId: "9",
            bookName: "Short Counsel",
            author: "Roy Nairn",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 10 2016",
                page: 215
            }
        }, {
            bookId: "42",
            bookName: "The Evil Rivals",
            author: "Izabelle Benjamin",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 28 2020",
                page: 75
            }
        }, {
            bookId: "41",
            bookName: "Breathing Moonlight",
            author: "Ayaan Brandt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "December 29 2016",
                page: 302
            }
        }],
        wishList: [{
            bookId: "42",
            bookName: "The Evil Rivals",
            author: "Izabelle Benjamin",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "26",
            bookName: "Blunt Abyss",
            author: "Ella-Mae Spencer",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "48",
            bookName: "A Sister's Salvation",
            author: "Opal Cummings",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy17",
    userType: "Student",
    displayName: "Clarissa Cleveland",
    photoURL: "https://picsum.photos/200'",
    email: "clarissa.cleveland@mail.com",
    biography: "He figured a few sticks of dynamite were easier than a fishing pole to catch fish.",
    books: {
        favourites: [{
            bookId: "37",
            bookName: "Risk of Illusion",
            author: "Atlas Guthrie",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 08 2016"
        }, {
            bookId: "35",
            bookName: "A Shadow of Season",
            author: "Dilara Haley",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 31 2019"
        }, {
            bookId: "6",
            bookName: "The Laugh of Eternity",
            author: "Aron Romero",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 04 2017"
        }],
        readHistory: [{
            bookId: "19",
            bookName: "The Tulip Gift",
            author: "Barry Choi",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 06 2016",
                page: 211
            }
        }, {
            bookId: "44",
            bookName: "Spare Signs",
            author: "Moshe Bowden",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 30 2019",
                page: 234
            }
        }, {
            bookId: "45",
            bookName: "The Daisy Godfather",
            author: "Hayden Malone",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "December 28 2014",
                page: 64
            }
        }],
        wishList: [{
            bookId: "42",
            bookName: "The Evil Rivals",
            author: "Izabelle Benjamin",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "2",
            bookName: "Vineyard Waves",
            author: "Sinead Morgan",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "32",
            bookName: "Bullets in the Sea",
            author: "Lidia Rhodes",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy18",
    userType: "Student",
    displayName: "Lochlan Rosa",
    photoURL: "https://picsum.photos/200'",
    email: "lochlan.rosa@mail.com",
    biography: "She lived on Monkey Jungle Road and that seemed to explain all of her strangeness.",
    books: {
        favourites: [{
            bookId: "17",
            bookName: "The Wilder Walls",
            author: "Jamie Montgomery",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 27 2015"
        }, {
            bookId: "11",
            bookName: "The Hurricane Keys",
            author: "Hugo Werner",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 25 2017"
        }, {
            bookId: "20",
            bookName: "Brighton Grail",
            author: "Reanna Clayton",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "May 05 2018"
        }],
        readHistory: [{
            bookId: "24",
            bookName: "The Girl in Transylvania",
            author: "Spike Rice",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 11 2017",
                page: 298
            }
        }, {
            bookId: "15",
            bookName: "The Star Visitors",
            author: "James Stark",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 21 2016",
                page: 230
            }
        }, {
            bookId: "39",
            bookName: "The Specialist's Vacation",
            author: "Devonte Kirk",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 16 2015",
                page: 44
            }
        }],
        wishList: [{
            bookId: "32",
            bookName: "Bullets in the Sea",
            author: "Lidia Rhodes",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "26",
            bookName: "Blunt Abyss",
            author: "Ella-Mae Spencer",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "12",
            bookName: "Amber and Glory",
            author: "Ava-Mai Combs",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy19",
    userType: "Student",
    displayName: "Oisin Lewis",
    photoURL: "https://picsum.photos/200'",
    email: "oisin.lewis@mail.com",
    biography: "Although it wasn't a pot of gold, Nancy was still enthralled at what she found at the end of the rainbow.",
    books: {
        favourites: [{
            bookId: "39",
            bookName: "The Specialist's Vacation",
            author: "Devonte Kirk",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 16 2015"
        }, {
            bookId: "16",
            bookName: "Doctor in Passion",
            author: "Katya Mansell",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "August 29 2019"
        }, {
            bookId: "19",
            bookName: "The Tulip Gift",
            author: "Barry Choi",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "September 06 2016"
        }],
        readHistory: [{
            bookId: "38",
            bookName: "The Voice of the Matter",
            author: "Libbie Aguilar",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 14 2020",
                page: 295
            }
        }, {
            bookId: "10",
            bookName: "The Crown of the Sunset",
            author: "Sommer Keith",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 27 2016",
                page: 205
            }
        }, {
            bookId: "49",
            bookName: "Cheyenne Malone",
            author: "Ellie Shaw",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 07 2019",
                page: 229
            }
        }],
        wishList: [{
            bookId: "32",
            bookName: "Bullets in the Sea",
            author: "Lidia Rhodes",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "47",
            bookName: "Witch's Debt",
            author: "Marwah Cornish",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "49",
            bookName: "Cheyenne Malone",
            author: "Ellie Shaw",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy20",
    userType: "Student",
    displayName: "Tillie Frey",
    photoURL: "https://picsum.photos/200'",
    email: "tillie.frey@mail.com",
    biography: "He was surprised that his immense laziness was inspirational to others.",
    books: {
        favourites: [{
            bookId: "47",
            bookName: "Witch's Debt",
            author: "Marwah Cornish",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "June 17 2018"
        }, {
            bookId: "10",
            bookName: "The Crown of the Sunset",
            author: "Sommer Keith",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 27 2016"
        }, {
            bookId: "48",
            bookName: "A Sister's Salvation",
            author: "Opal Cummings",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 13 2017"
        }],
        readHistory: [{
            bookId: "11",
            bookName: "The Hurricane Keys",
            author: "Hugo Werner",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "October 25 2017",
                page: 22
            }
        }, {
            bookId: "42",
            bookName: "The Evil Rivals",
            author: "Izabelle Benjamin",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 28 2020",
                page: 75
            }
        }, {
            bookId: "22",
            bookName: "The Dragon and the Tiger",
            author: "Alex House",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "November 27 2019",
                page: 224
            }
        }],
        wishList: [{
            bookId: "21",
            bookName: "A Deadly Bastard",
            author: "Avani Flores",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "32",
            bookName: "Bullets in the Sea",
            author: "Lidia Rhodes",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "16",
            bookName: "Doctor in Passion",
            author: "Katya Mansell",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy21",
    userType: "Student",
    displayName: "Rosalind Hudson",
    photoURL: "https://picsum.photos/200'",
    email: "rosalind.hudson@mail.com",
    biography: "After exploring the abandoned building, he started to believe in ghosts.",
    books: {
        favourites: [{
            bookId: "45",
            bookName: "The Daisy Godfather",
            author: "Hayden Malone",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "December 28 2014"
        }, {
            bookId: "18",
            bookName: "The Handsome Thorn",
            author: "Ivy Singleton",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 02 2020"
        }, {
            bookId: "43",
            bookName: "A Rat's Salvation",
            author: "Ananya Gay",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 30 2019"
        }],
        readHistory: [{
            bookId: "42",
            bookName: "The Evil Rivals",
            author: "Izabelle Benjamin",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 28 2020",
                page: 75
            }
        }, {
            bookId: "35",
            bookName: "A Shadow of Season",
            author: "Dilara Haley",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 31 2019",
                page: 104
            }
        }, {
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 09 2017",
                page: 181
            }
        }],
        wishList: [{
            bookId: "27",
            bookName: "The Office Eyes",
            author: "Aminah Barnes",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "35",
            bookName: "A Shadow of Season",
            author: "Dilara Haley",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy22",
    userType: "Student",
    displayName: "Taio Tran",
    photoURL: "https://picsum.photos/200'",
    email: "taio.tran@mail.com",
    biography: "David proudly graduated from high school top of his class at age 97.",
    books: {
        favourites: [{
            bookId: "39",
            bookName: "The Specialist's Vacation",
            author: "Devonte Kirk",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 16 2015"
        }, {
            bookId: "11",
            bookName: "The Hurricane Keys",
            author: "Hugo Werner",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 25 2017"
        }, {
            bookId: "16",
            bookName: "Doctor in Passion",
            author: "Katya Mansell",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "August 29 2019"
        }],
        readHistory: [{
            bookId: "37",
            bookName: "Risk of Illusion",
            author: "Atlas Guthrie",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 08 2016",
                page: 43
            }
        }, {
            bookId: "41",
            bookName: "Breathing Moonlight",
            author: "Ayaan Brandt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "December 29 2016",
                page: 302
            }
        }, {
            bookId: "36",
            bookName: "The Spirit of the Bow",
            author: "Cherry Valencia",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "November 30 2015",
                page: 351
            }
        }],
        wishList: [{
            bookId: "5",
            bookName: "Prey for a Doctor",
            author: "Brent Shelton",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "4",
            bookName: "Point Scorned",
            author: "Reanne Kennedy",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "18",
            bookName: "The Handsome Thorn",
            author: "Ivy Singleton",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy23",
    userType: "Student",
    displayName: "Jett Mckenna",
    photoURL: "https://picsum.photos/200'",
    email: "jett.mckenna@mail.com",
    biography: "When motorists sped in and out of traffic, all she could think of was those in need of a transplant.",
    books: {
        favourites: [{
            bookId: "2",
            bookName: "Vineyard Waves",
            author: "Sinead Morgan",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "September 29 2018"
        }, {
            bookId: "40",
            bookName: "Fine Karma",
            author: "Sofia Pratt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "December 16 2017"
        }, {
            bookId: "46",
            bookName: "Savage Feet",
            author: "Judith Estes",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 08 2018"
        }],
        readHistory: [{
            bookId: "38",
            bookName: "The Voice of the Matter",
            author: "Libbie Aguilar",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 14 2020",
                page: 295
            }
        }, {
            bookId: "20",
            bookName: "Brighton Grail",
            author: "Reanna Clayton",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "May 05 2018",
                page: 45
            }
        }, {
            bookId: "32",
            bookName: "Bullets in the Sea",
            author: "Lidia Rhodes",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 18 2015",
                page: 88
            }
        }],
        wishList: [{
            bookId: "18",
            bookName: "The Handsome Thorn",
            author: "Ivy Singleton",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "19",
            bookName: "The Tulip Gift",
            author: "Barry Choi",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "30",
            bookName: "A Tangled Balance",
            author: "Yasemin Hart",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy24",
    userType: "Student",
    displayName: "Inaaya Goodman",
    photoURL: "https://picsum.photos/200'",
    email: "inaaya.goodman@mail.com",
    biography: "They improved dramatically once the lead singer left.",
    books: {
        favourites: [{
            bookId: "6",
            bookName: "The Laugh of Eternity",
            author: "Aron Romero",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 04 2017"
        }, {
            bookId: "44",
            bookName: "Spare Signs",
            author: "Moshe Bowden",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 30 2019"
        }, {
            bookId: "38",
            bookName: "The Voice of the Matter",
            author: "Libbie Aguilar",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 14 2020"
        }],
        readHistory: [{
            bookId: "17",
            bookName: "The Wilder Walls",
            author: "Jamie Montgomery",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 27 2015",
                page: 118
            }
        }, {
            bookId: "47",
            bookName: "Witch's Debt",
            author: "Marwah Cornish",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "June 17 2018",
                page: 20
            }
        }, {
            bookId: "7",
            bookName: "Echoes of Homicide",
            author: "Kaden Massey",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "November 10 2015",
                page: 15
            }
        }],
        wishList: [{
            bookId: "37",
            bookName: "Risk of Illusion",
            author: "Atlas Guthrie",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "13",
            bookName: "The Piano Hospital",
            author: "Tonya Dyer",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "15",
            bookName: "The Star Visitors",
            author: "James Stark",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy25",
    userType: "Student",
    displayName: "Willow Hayden",
    photoURL: "https://picsum.photos/200'",
    email: "willow.hayden@mail.com",
    biography: "Everyone says they love nature until they realize how dangerous she can be.",
    books: {
        favourites: [{
            bookId: "33",
            bookName: "Pride of Flames",
            author: "Cayden Donovan",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "August 21 2019"
        }, {
            bookId: "43",
            bookName: "A Rat's Salvation",
            author: "Ananya Gay",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 30 2019"
        }, {
            bookId: "35",
            bookName: "A Shadow of Season",
            author: "Dilara Haley",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 31 2019"
        }],
        readHistory: [{
            bookId: "46",
            bookName: "Savage Feet",
            author: "Judith Estes",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 08 2018",
                page: 75
            }
        }, {
            bookId: "26",
            bookName: "Blunt Abyss",
            author: "Ella-Mae Spencer",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "February 21 2020",
                page: 131
            }
        }, {
            bookId: "11",
            bookName: "The Hurricane Keys",
            author: "Hugo Werner",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "October 25 2017",
                page: 22
            }
        }],
        wishList: [{
            bookId: "3",
            bookName: "The Whiskey Reality",
            author: "Carolyn Cano",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "1",
            bookName: "Mystery in Trouble",
            author: "Alejandro Oakley",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "29",
            bookName: "Change of Captivity",
            author: "Landon Clements",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy26",
    userType: "Student",
    displayName: "Lisa-Marie Marquez",
    photoURL: "https://picsum.photos/200'",
    email: "lisa-marie.marquez@mail.com",
    biography: "Smoky the Bear secretly started the fires.",
    books: {
        favourites: [{
            bookId: "14",
            bookName: "Country at Q",
            author: "Shanna Raymond",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 04 2018"
        }, {
            bookId: "13",
            bookName: "The Piano Hospital",
            author: "Tonya Dyer",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "June 24 2017"
        }, {
            bookId: "20",
            bookName: "Brighton Grail",
            author: "Reanna Clayton",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "May 05 2018"
        }],
        readHistory: [{
            bookId: "41",
            bookName: "Breathing Moonlight",
            author: "Ayaan Brandt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "December 29 2016",
                page: 302
            }
        }, {
            bookId: "25",
            bookName: "Maigret and the Pickpocket",
            author: "Jonny Simon",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 09 2018",
                page: 56
            }
        }, {
            bookId: "24",
            bookName: "The Girl in Transylvania",
            author: "Spike Rice",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 11 2017",
                page: 298
            }
        }],
        wishList: [{
            bookId: "15",
            bookName: "The Star Visitors",
            author: "James Stark",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "50",
            bookName: "Single Templar",
            author: "Kayne Driscoll",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "32",
            bookName: "Bullets in the Sea",
            author: "Lidia Rhodes",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy27",
    userType: "Student",
    displayName: "Farhaan Mcfarland",
    photoURL: "https://picsum.photos/200'",
    email: "farhaan.mcfarland@mail.com",
    biography: "I hear that Nancy is very pretty.",
    books: {
        favourites: [{
            bookId: "8",
            bookName: "Batman's Fortress",
            author: "Anastazja Shepard",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 19 2017"
        }, {
            bookId: "32",
            bookName: "Bullets in the Sea",
            author: "Lidia Rhodes",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 18 2015"
        }, {
            bookId: "43",
            bookName: "A Rat's Salvation",
            author: "Ananya Gay",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 30 2019"
        }],
        readHistory: [{
            bookId: "44",
            bookName: "Spare Signs",
            author: "Moshe Bowden",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 30 2019",
                page: 234
            }
        }, {
            bookId: "3",
            bookName: "The Whiskey Reality",
            author: "Carolyn Cano",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "October 12 2018",
                page: 45
            }
        }, {
            bookId: "23",
            bookName: "Tarzan and the Stone",
            author: "Nada Hoover",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 19 2015",
                page: 51
            }
        }],
        wishList: [{
            bookId: "38",
            bookName: "The Voice of the Matter",
            author: "Libbie Aguilar",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "33",
            bookName: "Pride of Flames",
            author: "Cayden Donovan",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "10",
            bookName: "The Crown of the Sunset",
            author: "Sommer Keith",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy28",
    userType: "Student",
    displayName: "Dale Callahan",
    photoURL: "https://picsum.photos/200'",
    email: "dale.callahan@mail.com",
    biography: "Her life in the confines of the house became her new normal.",
    books: {
        favourites: [{
            bookId: "27",
            bookName: "The Office Eyes",
            author: "Aminah Barnes",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 13 2020"
        }, {
            bookId: "4",
            bookName: "Point Scorned",
            author: "Reanne Kennedy",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 09 2015"
        }, {
            bookId: "40",
            bookName: "Fine Karma",
            author: "Sofia Pratt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "December 16 2017"
        }],
        readHistory: [{
            bookId: "2",
            bookName: "Vineyard Waves",
            author: "Sinead Morgan",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 29 2018",
                page: 206
            }
        }, {
            bookId: "24",
            bookName: "The Girl in Transylvania",
            author: "Spike Rice",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 11 2017",
                page: 298
            }
        }, {
            bookId: "32",
            bookName: "Bullets in the Sea",
            author: "Lidia Rhodes",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 18 2015",
                page: 88
            }
        }],
        wishList: [{
            bookId: "21",
            bookName: "A Deadly Bastard",
            author: "Avani Flores",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "29",
            bookName: "Change of Captivity",
            author: "Landon Clements",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "4",
            bookName: "Point Scorned",
            author: "Reanne Kennedy",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy29",
    userType: "Student",
    displayName: "Ellouise Coleman",
    photoURL: "https://picsum.photos/200'",
    email: "ellouise.coleman@mail.com",
    biography: "He decided that the time had come to be stronger than any of the excuses he'd used until then.",
    books: {
        favourites: [{
            bookId: "32",
            bookName: "Bullets in the Sea",
            author: "Lidia Rhodes",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 18 2015"
        }, {
            bookId: "10",
            bookName: "The Crown of the Sunset",
            author: "Sommer Keith",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 27 2016"
        }, {
            bookId: "16",
            bookName: "Doctor in Passion",
            author: "Katya Mansell",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "August 29 2019"
        }],
        readHistory: [{
            bookId: "44",
            bookName: "Spare Signs",
            author: "Moshe Bowden",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 30 2019",
                page: 234
            }
        }, {
            bookId: "33",
            bookName: "Pride of Flames",
            author: "Cayden Donovan",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "August 21 2019",
                page: 4
            }
        }, {
            bookId: "9",
            bookName: "Short Counsel",
            author: "Roy Nairn",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 10 2016",
                page: 215
            }
        }],
        wishList: [{
            bookId: "49",
            bookName: "Cheyenne Malone",
            author: "Ellie Shaw",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "33",
            bookName: "Pride of Flames",
            author: "Cayden Donovan",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "29",
            bookName: "Change of Captivity",
            author: "Landon Clements",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy30",
    userType: "Student",
    displayName: "Moshe Barry",
    photoURL: "https://picsum.photos/200'",
    email: "moshe.barry@mail.com",
    biography: "She found his complete dullness interesting.",
    books: {
        favourites: [{
            bookId: "3",
            bookName: "The Whiskey Reality",
            author: "Carolyn Cano",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 12 2018"
        }, {
            bookId: "9",
            bookName: "Short Counsel",
            author: "Roy Nairn",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 10 2016"
        }, {
            bookId: "48",
            bookName: "A Sister's Salvation",
            author: "Opal Cummings",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 13 2017"
        }],
        readHistory: [{
            bookId: "32",
            bookName: "Bullets in the Sea",
            author: "Lidia Rhodes",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 18 2015",
                page: 88
            }
        }, {
            bookId: "26",
            bookName: "Blunt Abyss",
            author: "Ella-Mae Spencer",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "February 21 2020",
                page: 131
            }
        }, {
            bookId: "29",
            bookName: "Change of Captivity",
            author: "Landon Clements",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 25 2018",
                page: 87
            }
        }],
        wishList: [{
            bookId: "8",
            bookName: "Batman's Fortress",
            author: "Anastazja Shepard",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "12",
            bookName: "Amber and Glory",
            author: "Ava-Mai Combs",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "46",
            bookName: "Savage Feet",
            author: "Judith Estes",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy31",
    userType: "Student",
    displayName: "Kingsley Ellis",
    photoURL: "https://picsum.photos/200'",
    email: "kingsley.ellis@mail.com",
    biography: "She hadn't had her cup of coffee, and that made things all the worse.",
    books: {
        favourites: [{
            bookId: "20",
            bookName: "Brighton Grail",
            author: "Reanna Clayton",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "May 05 2018"
        }, {
            bookId: "4",
            bookName: "Point Scorned",
            author: "Reanne Kennedy",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 09 2015"
        }, {
            bookId: "10",
            bookName: "The Crown of the Sunset",
            author: "Sommer Keith",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 27 2016"
        }],
        readHistory: [{
            bookId: "46",
            bookName: "Savage Feet",
            author: "Judith Estes",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 08 2018",
                page: 75
            }
        }, {
            bookId: "10",
            bookName: "The Crown of the Sunset",
            author: "Sommer Keith",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 27 2016",
                page: 205
            }
        }, {
            bookId: "49",
            bookName: "Cheyenne Malone",
            author: "Ellie Shaw",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 07 2019",
                page: 229
            }
        }],
        wishList: [{
            bookId: "42",
            bookName: "The Evil Rivals",
            author: "Izabelle Benjamin",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "1",
            bookName: "Mystery in Trouble",
            author: "Alejandro Oakley",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "42",
            bookName: "The Evil Rivals",
            author: "Izabelle Benjamin",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy32",
    userType: "Student",
    displayName: "Meredith Bowen",
    photoURL: "https://picsum.photos/200'",
    email: "meredith.bowen@mail.com",
    biography: "At that moment she realized she had a sixth sense.",
    books: {
        favourites: [{
            bookId: "43",
            bookName: "A Rat's Salvation",
            author: "Ananya Gay",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 30 2019"
        }, {
            bookId: "22",
            bookName: "The Dragon and the Tiger",
            author: "Alex House",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "November 27 2019"
        }, {
            bookId: "40",
            bookName: "Fine Karma",
            author: "Sofia Pratt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "December 16 2017"
        }],
        readHistory: [{
            bookId: "22",
            bookName: "The Dragon and the Tiger",
            author: "Alex House",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "November 27 2019",
                page: 224
            }
        }, {
            bookId: "43",
            bookName: "A Rat's Salvation",
            author: "Ananya Gay",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 30 2019",
                page: 232
            }
        }, {
            bookId: "29",
            bookName: "Change of Captivity",
            author: "Landon Clements",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 25 2018",
                page: 87
            }
        }],
        wishList: [{
            bookId: "3",
            bookName: "The Whiskey Reality",
            author: "Carolyn Cano",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "5",
            bookName: "Prey for a Doctor",
            author: "Brent Shelton",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "44",
            bookName: "Spare Signs",
            author: "Moshe Bowden",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy33",
    userType: "Student",
    displayName: "Bradlee Broughton",
    photoURL: "https://picsum.photos/200'",
    email: "bradlee.broughton@mail.com",
    biography: "There can never be too many cherries on an ice cream sundae.",
    books: {
        favourites: [{
            bookId: "22",
            bookName: "The Dragon and the Tiger",
            author: "Alex House",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "November 27 2019"
        }, {
            bookId: "27",
            bookName: "The Office Eyes",
            author: "Aminah Barnes",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 13 2020"
        }, {
            bookId: "33",
            bookName: "Pride of Flames",
            author: "Cayden Donovan",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "August 21 2019"
        }],
        readHistory: [{
            bookId: "50",
            bookName: "Single Templar",
            author: "Kayne Driscoll",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "October 21 2018",
                page: 225
            }
        }, {
            bookId: "6",
            bookName: "The Laugh of Eternity",
            author: "Aron Romero",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 04 2017",
                page: 46
            }
        }, {
            bookId: "31",
            bookName: "Martian Baby",
            author: "Thierry Holman",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 28 2015",
                page: 147
            }
        }],
        wishList: [{
            bookId: "12",
            bookName: "Amber and Glory",
            author: "Ava-Mai Combs",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "26",
            bookName: "Blunt Abyss",
            author: "Ella-Mae Spencer",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "21",
            bookName: "A Deadly Bastard",
            author: "Avani Flores",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy34",
    userType: "Student",
    displayName: "Kristina Huber",
    photoURL: "https://picsum.photos/200'",
    email: "kristina.huber@mail.com",
    biography: "People keep telling me orange but I still prefer pink.",
    books: {
        favourites: [{
            bookId: "37",
            bookName: "Risk of Illusion",
            author: "Atlas Guthrie",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 08 2016"
        }, {
            bookId: "24",
            bookName: "The Girl in Transylvania",
            author: "Spike Rice",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "September 11 2017"
        }, {
            bookId: "14",
            bookName: "Country at Q",
            author: "Shanna Raymond",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 04 2018"
        }],
        readHistory: [{
            bookId: "39",
            bookName: "The Specialist's Vacation",
            author: "Devonte Kirk",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 16 2015",
                page: 44
            }
        }, {
            bookId: "6",
            bookName: "The Laugh of Eternity",
            author: "Aron Romero",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 04 2017",
                page: 46
            }
        }, {
            bookId: "24",
            bookName: "The Girl in Transylvania",
            author: "Spike Rice",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 11 2017",
                page: 298
            }
        }],
        wishList: [{
            bookId: "27",
            bookName: "The Office Eyes",
            author: "Aminah Barnes",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "30",
            bookName: "A Tangled Balance",
            author: "Yasemin Hart",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "49",
            bookName: "Cheyenne Malone",
            author: "Ellie Shaw",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy35",
    userType: "Student",
    displayName: "Adelina Cooley",
    photoURL: "https://picsum.photos/200'",
    email: "adelina.cooley@mail.com",
    biography: "While all her friends were positive that Mary had a sixth sense, she knew she actually had a seventh sense.",
    books: {
        favourites: [{
            bookId: "25",
            bookName: "Maigret and the Pickpocket",
            author: "Jonny Simon",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 09 2018"
        }, {
            bookId: "48",
            bookName: "A Sister's Salvation",
            author: "Opal Cummings",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 13 2017"
        }, {
            bookId: "7",
            bookName: "Echoes of Homicide",
            author: "Kaden Massey",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "November 10 2015"
        }],
        readHistory: [{
            bookId: "14",
            bookName: "Country at Q",
            author: "Shanna Raymond",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 04 2018",
                page: 30
            }
        }, {
            bookId: "37",
            bookName: "Risk of Illusion",
            author: "Atlas Guthrie",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 08 2016",
                page: 43
            }
        }, {
            bookId: "34",
            bookName: "Curtain of Mystery",
            author: "Sian Huff",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 02 2016",
                page: 110
            }
        }],
        wishList: [{
            bookId: "18",
            bookName: "The Handsome Thorn",
            author: "Ivy Singleton",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "44",
            bookName: "Spare Signs",
            author: "Moshe Bowden",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "40",
            bookName: "Fine Karma",
            author: "Sofia Pratt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy36",
    userType: "Student",
    displayName: "Martyn Hewitt",
    photoURL: "https://picsum.photos/200'",
    email: "martyn.hewitt@mail.com",
    biography: "He decided to count all the sand on the beach as a hobby.",
    books: {
        favourites: [{
            bookId: "11",
            bookName: "The Hurricane Keys",
            author: "Hugo Werner",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 25 2017"
        }, {
            bookId: "30",
            bookName: "A Tangled Balance",
            author: "Yasemin Hart",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 15 2018"
        }, {
            bookId: "21",
            bookName: "A Deadly Bastard",
            author: "Avani Flores",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "May 21 2015"
        }],
        readHistory: [{
            bookId: "25",
            bookName: "Maigret and the Pickpocket",
            author: "Jonny Simon",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 09 2018",
                page: 56
            }
        }, {
            bookId: "7",
            bookName: "Echoes of Homicide",
            author: "Kaden Massey",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "November 10 2015",
                page: 15
            }
        }, {
            bookId: "24",
            bookName: "The Girl in Transylvania",
            author: "Spike Rice",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 11 2017",
                page: 298
            }
        }],
        wishList: [{
            bookId: "30",
            bookName: "A Tangled Balance",
            author: "Yasemin Hart",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "46",
            bookName: "Savage Feet",
            author: "Judith Estes",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "32",
            bookName: "Bullets in the Sea",
            author: "Lidia Rhodes",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy37",
    userType: "Student",
    displayName: "Jolene Molina",
    photoURL: "https://picsum.photos/200'",
    email: "jolene.molina@mail.com",
    biography: "This is a Japanese doll.",
    books: {
        favourites: [{
            bookId: "25",
            bookName: "Maigret and the Pickpocket",
            author: "Jonny Simon",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 09 2018"
        }, {
            bookId: "4",
            bookName: "Point Scorned",
            author: "Reanne Kennedy",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 09 2015"
        }, {
            bookId: "39",
            bookName: "The Specialist's Vacation",
            author: "Devonte Kirk",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 16 2015"
        }],
        readHistory: [{
            bookId: "20",
            bookName: "Brighton Grail",
            author: "Reanna Clayton",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "May 05 2018",
                page: 45
            }
        }, {
            bookId: "29",
            bookName: "Change of Captivity",
            author: "Landon Clements",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 25 2018",
                page: 87
            }
        }, {
            bookId: "2",
            bookName: "Vineyard Waves",
            author: "Sinead Morgan",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 29 2018",
                page: 206
            }
        }],
        wishList: [{
            bookId: "38",
            bookName: "The Voice of the Matter",
            author: "Libbie Aguilar",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "46",
            bookName: "Savage Feet",
            author: "Judith Estes",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "26",
            bookName: "Blunt Abyss",
            author: "Ella-Mae Spencer",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy38",
    userType: "Student",
    displayName: "Henna Dean",
    photoURL: "https://picsum.photos/200'",
    email: "henna.dean@mail.com",
    biography: "A glittering gem is not enough.",
    books: {
        favourites: [{
            bookId: "25",
            bookName: "Maigret and the Pickpocket",
            author: "Jonny Simon",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 09 2018"
        }, {
            bookId: "2",
            bookName: "Vineyard Waves",
            author: "Sinead Morgan",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "September 29 2018"
        }, {
            bookId: "40",
            bookName: "Fine Karma",
            author: "Sofia Pratt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "December 16 2017"
        }],
        readHistory: [{
            bookId: "44",
            bookName: "Spare Signs",
            author: "Moshe Bowden",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 30 2019",
                page: 234
            }
        }, {
            bookId: "50",
            bookName: "Single Templar",
            author: "Kayne Driscoll",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "October 21 2018",
                page: 225
            }
        }, {
            bookId: "5",
            bookName: "Prey for a Doctor",
            author: "Brent Shelton",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 06 2016",
                page: 218
            }
        }],
        wishList: [{
            bookId: "49",
            bookName: "Cheyenne Malone",
            author: "Ellie Shaw",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "37",
            bookName: "Risk of Illusion",
            author: "Atlas Guthrie",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "43",
            bookName: "A Rat's Salvation",
            author: "Ananya Gay",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy39",
    userType: "Student",
    displayName: "Selin Russo",
    photoURL: "https://picsum.photos/200'",
    email: "selin.russo@mail.com",
    biography: "He kept telling himself that one day it would all somehow make sense.",
    books: {
        favourites: [{
            bookId: "3",
            bookName: "The Whiskey Reality",
            author: "Carolyn Cano",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 12 2018"
        }, {
            bookId: "15",
            bookName: "The Star Visitors",
            author: "James Stark",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 21 2016"
        }, {
            bookId: "45",
            bookName: "The Daisy Godfather",
            author: "Hayden Malone",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "December 28 2014"
        }],
        readHistory: [{
            bookId: "17",
            bookName: "The Wilder Walls",
            author: "Jamie Montgomery",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 27 2015",
                page: 118
            }
        }, {
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 09 2017",
                page: 181
            }
        }, {
            bookId: "10",
            bookName: "The Crown of the Sunset",
            author: "Sommer Keith",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 27 2016",
                page: 205
            }
        }],
        wishList: [{
            bookId: "14",
            bookName: "Country at Q",
            author: "Shanna Raymond",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "41",
            bookName: "Breathing Moonlight",
            author: "Ayaan Brandt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "33",
            bookName: "Pride of Flames",
            author: "Cayden Donovan",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy40",
    userType: "Student",
    displayName: "Cathal Stark",
    photoURL: "https://picsum.photos/200'",
    email: "cathal.stark@mail.com",
    biography: "When nobody is around, the trees gossip about the people who have walked under them.",
    books: {
        favourites: [{
            bookId: "50",
            bookName: "Single Templar",
            author: "Kayne Driscoll",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 21 2018"
        }, {
            bookId: "50",
            bookName: "Single Templar",
            author: "Kayne Driscoll",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 21 2018"
        }, {
            bookId: "6",
            bookName: "The Laugh of Eternity",
            author: "Aron Romero",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 04 2017"
        }],
        readHistory: [{
            bookId: "17",
            bookName: "The Wilder Walls",
            author: "Jamie Montgomery",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 27 2015",
                page: 118
            }
        }, {
            bookId: "24",
            bookName: "The Girl in Transylvania",
            author: "Spike Rice",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 11 2017",
                page: 298
            }
        }, {
            bookId: "8",
            bookName: "Batman's Fortress",
            author: "Anastazja Shepard",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 19 2017",
                page: 84
            }
        }],
        wishList: [{
            bookId: "20",
            bookName: "Brighton Grail",
            author: "Reanna Clayton",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "21",
            bookName: "A Deadly Bastard",
            author: "Avani Flores",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "44",
            bookName: "Spare Signs",
            author: "Moshe Bowden",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy41",
    userType: "Student",
    displayName: "Lyndon Jacobs",
    photoURL: "https://picsum.photos/200'",
    email: "lyndon.jacobs@mail.com",
    biography: "It was the scarcity that fueled his creativity.",
    books: {
        favourites: [{
            bookId: "21",
            bookName: "A Deadly Bastard",
            author: "Avani Flores",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "May 21 2015"
        }, {
            bookId: "21",
            bookName: "A Deadly Bastard",
            author: "Avani Flores",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "May 21 2015"
        }, {
            bookId: "13",
            bookName: "The Piano Hospital",
            author: "Tonya Dyer",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "June 24 2017"
        }],
        readHistory: [{
            bookId: "20",
            bookName: "Brighton Grail",
            author: "Reanna Clayton",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "May 05 2018",
                page: 45
            }
        }, {
            bookId: "26",
            bookName: "Blunt Abyss",
            author: "Ella-Mae Spencer",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "February 21 2020",
                page: 131
            }
        }, {
            bookId: "23",
            bookName: "Tarzan and the Stone",
            author: "Nada Hoover",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 19 2015",
                page: 51
            }
        }],
        wishList: [{
            bookId: "1",
            bookName: "Mystery in Trouble",
            author: "Alejandro Oakley",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "40",
            bookName: "Fine Karma",
            author: "Sofia Pratt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "7",
            bookName: "Echoes of Homicide",
            author: "Kaden Massey",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy42",
    userType: "Student",
    displayName: "Tina Riggs",
    photoURL: "https://picsum.photos/200'",
    email: "tina.riggs@mail.com",
    biography: "Three generations with six decades of life experience.",
    books: {
        favourites: [{
            bookId: "30",
            bookName: "A Tangled Balance",
            author: "Yasemin Hart",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 15 2018"
        }, {
            bookId: "33",
            bookName: "Pride of Flames",
            author: "Cayden Donovan",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "August 21 2019"
        }, {
            bookId: "11",
            bookName: "The Hurricane Keys",
            author: "Hugo Werner",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 25 2017"
        }],
        readHistory: [{
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 09 2017",
                page: 181
            }
        }, {
            bookId: "9",
            bookName: "Short Counsel",
            author: "Roy Nairn",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 10 2016",
                page: 215
            }
        }, {
            bookId: "49",
            bookName: "Cheyenne Malone",
            author: "Ellie Shaw",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 07 2019",
                page: 229
            }
        }],
        wishList: [{
            bookId: "20",
            bookName: "Brighton Grail",
            author: "Reanna Clayton",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "24",
            bookName: "The Girl in Transylvania",
            author: "Spike Rice",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "45",
            bookName: "The Daisy Godfather",
            author: "Hayden Malone",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy43",
    userType: "Student",
    displayName: "Federico Hensley",
    photoURL: "https://picsum.photos/200'",
    email: "federico.hensley@mail.com",
    biography: "The stranger officiates the meal.",
    books: {
        favourites: [{
            bookId: "1",
            bookName: "Mystery in Trouble",
            author: "Alejandro Oakley",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "August 24 2019"
        }, {
            bookId: "36",
            bookName: "The Spirit of the Bow",
            author: "Cherry Valencia",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "November 30 2015"
        }, {
            bookId: "8",
            bookName: "Batman's Fortress",
            author: "Anastazja Shepard",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 19 2017"
        }],
        readHistory: [{
            bookId: "21",
            bookName: "A Deadly Bastard",
            author: "Avani Flores",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "May 21 2015",
                page: 106
            }
        }, {
            bookId: "16",
            bookName: "Doctor in Passion",
            author: "Katya Mansell",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "August 29 2019",
                page: 227
            }
        }, {
            bookId: "6",
            bookName: "The Laugh of Eternity",
            author: "Aron Romero",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 04 2017",
                page: 46
            }
        }],
        wishList: [{
            bookId: "32",
            bookName: "Bullets in the Sea",
            author: "Lidia Rhodes",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "49",
            bookName: "Cheyenne Malone",
            author: "Ellie Shaw",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "18",
            bookName: "The Handsome Thorn",
            author: "Ivy Singleton",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy44",
    userType: "Student",
    displayName: "Ismaeel Booker",
    photoURL: "https://picsum.photos/200'",
    email: "ismaeel.booker@mail.com",
    biography: "There were three sphered rocks congregating in a cubed room.",
    books: {
        favourites: [{
            bookId: "24",
            bookName: "The Girl in Transylvania",
            author: "Spike Rice",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "September 11 2017"
        }, {
            bookId: "10",
            bookName: "The Crown of the Sunset",
            author: "Sommer Keith",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 27 2016"
        }, {
            bookId: "4",
            bookName: "Point Scorned",
            author: "Reanne Kennedy",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 09 2015"
        }],
        readHistory: [{
            bookId: "31",
            bookName: "Martian Baby",
            author: "Thierry Holman",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 28 2015",
                page: 147
            }
        }, {
            bookId: "16",
            bookName: "Doctor in Passion",
            author: "Katya Mansell",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "August 29 2019",
                page: 227
            }
        }, {
            bookId: "25",
            bookName: "Maigret and the Pickpocket",
            author: "Jonny Simon",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 09 2018",
                page: 56
            }
        }],
        wishList: [{
            bookId: "41",
            bookName: "Breathing Moonlight",
            author: "Ayaan Brandt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "11",
            bookName: "The Hurricane Keys",
            author: "Hugo Werner",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "21",
            bookName: "A Deadly Bastard",
            author: "Avani Flores",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy45",
    userType: "Student",
    displayName: "Harleigh Hooper",
    photoURL: "https://picsum.photos/200'",
    email: "harleigh.hooper@mail.com",
    biography: "You'll see the rainbow bridge after it rains cats and dogs.",
    books: {
        favourites: [{
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 09 2017"
        }, {
            bookId: "32",
            bookName: "Bullets in the Sea",
            author: "Lidia Rhodes",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 18 2015"
        }, {
            bookId: "25",
            bookName: "Maigret and the Pickpocket",
            author: "Jonny Simon",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 09 2018"
        }],
        readHistory: [{
            bookId: "8",
            bookName: "Batman's Fortress",
            author: "Anastazja Shepard",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 19 2017",
                page: 84
            }
        }, {
            bookId: "26",
            bookName: "Blunt Abyss",
            author: "Ella-Mae Spencer",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "February 21 2020",
                page: 131
            }
        }, {
            bookId: "38",
            bookName: "The Voice of the Matter",
            author: "Libbie Aguilar",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 14 2020",
                page: 295
            }
        }],
        wishList: [{
            bookId: "40",
            bookName: "Fine Karma",
            author: "Sofia Pratt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "41",
            bookName: "Breathing Moonlight",
            author: "Ayaan Brandt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "24",
            bookName: "The Girl in Transylvania",
            author: "Spike Rice",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy46",
    userType: "Student",
    displayName: "Evie-Rose Erickson",
    photoURL: "https://picsum.photos/200'",
    email: "evie-rose.erickson@mail.com",
    biography: "Separation anxiety is what happens when you can't find your phone.",
    books: {
        favourites: [{
            bookId: "14",
            bookName: "Country at Q",
            author: "Shanna Raymond",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 04 2018"
        }, {
            bookId: "38",
            bookName: "The Voice of the Matter",
            author: "Libbie Aguilar",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 14 2020"
        }, {
            bookId: "26",
            bookName: "Blunt Abyss",
            author: "Ella-Mae Spencer",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "February 21 2020"
        }],
        readHistory: [{
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 09 2017",
                page: 181
            }
        }, {
            bookId: "36",
            bookName: "The Spirit of the Bow",
            author: "Cherry Valencia",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "November 30 2015",
                page: 351
            }
        }, {
            bookId: "13",
            bookName: "The Piano Hospital",
            author: "Tonya Dyer",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "June 24 2017",
                page: 14
            }
        }],
        wishList: [{
            bookId: "12",
            bookName: "Amber and Glory",
            author: "Ava-Mai Combs",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "10",
            bookName: "The Crown of the Sunset",
            author: "Sommer Keith",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "44",
            bookName: "Spare Signs",
            author: "Moshe Bowden",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy47",
    userType: "Student",
    displayName: "Kofi Simon",
    photoURL: "https://picsum.photos/200'",
    email: "kofi.simon@mail.com",
    biography: "The door swung open to reveal pink giraffes and red elephants.",
    books: {
        favourites: [{
            bookId: "8",
            bookName: "Batman's Fortress",
            author: "Anastazja Shepard",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 19 2017"
        }, {
            bookId: "42",
            bookName: "The Evil Rivals",
            author: "Izabelle Benjamin",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 28 2020"
        }, {
            bookId: "32",
            bookName: "Bullets in the Sea",
            author: "Lidia Rhodes",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 18 2015"
        }],
        readHistory: [{
            bookId: "9",
            bookName: "Short Counsel",
            author: "Roy Nairn",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 10 2016",
                page: 215
            }
        }, {
            bookId: "2",
            bookName: "Vineyard Waves",
            author: "Sinead Morgan",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 29 2018",
                page: 206
            }
        }, {
            bookId: "2",
            bookName: "Vineyard Waves",
            author: "Sinead Morgan",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 29 2018",
                page: 206
            }
        }],
        wishList: [{
            bookId: "20",
            bookName: "Brighton Grail",
            author: "Reanna Clayton",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "35",
            bookName: "A Shadow of Season",
            author: "Dilara Haley",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "31",
            bookName: "Martian Baby",
            author: "Thierry Holman",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy48",
    userType: "Student",
    displayName: "Brenna Naylor",
    photoURL: "https://picsum.photos/200'",
    email: "brenna.naylor@mail.com",
    biography: "The random sentence generator generated a random sentence about a random sentence.",
    books: {
        favourites: [{
            bookId: "27",
            bookName: "The Office Eyes",
            author: "Aminah Barnes",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 13 2020"
        }, {
            bookId: "20",
            bookName: "Brighton Grail",
            author: "Reanna Clayton",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "May 05 2018"
        }, {
            bookId: "10",
            bookName: "The Crown of the Sunset",
            author: "Sommer Keith",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 27 2016"
        }],
        readHistory: [{
            bookId: "14",
            bookName: "Country at Q",
            author: "Shanna Raymond",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 04 2018",
                page: 30
            }
        }, {
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 09 2017",
                page: 181
            }
        }, {
            bookId: "13",
            bookName: "The Piano Hospital",
            author: "Tonya Dyer",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "June 24 2017",
                page: 14
            }
        }],
        wishList: [{
            bookId: "9",
            bookName: "Short Counsel",
            author: "Roy Nairn",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "42",
            bookName: "The Evil Rivals",
            author: "Izabelle Benjamin",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "1",
            bookName: "Mystery in Trouble",
            author: "Alejandro Oakley",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy49",
    userType: "Student",
    displayName: "Jensen Wicks",
    photoURL: "https://picsum.photos/200'",
    email: "jensen.wicks@mail.com",
    biography: "He wasn't bitter that she had moved on but from the radish.",
    books: {
        favourites: [{
            bookId: "7",
            bookName: "Echoes of Homicide",
            author: "Kaden Massey",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "November 10 2015"
        }, {
            bookId: "17",
            bookName: "The Wilder Walls",
            author: "Jamie Montgomery",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 27 2015"
        }, {
            bookId: "26",
            bookName: "Blunt Abyss",
            author: "Ella-Mae Spencer",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "February 21 2020"
        }],
        readHistory: [{
            bookId: "30",
            bookName: "A Tangled Balance",
            author: "Yasemin Hart",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 15 2018",
                page: 403
            }
        }, {
            bookId: "36",
            bookName: "The Spirit of the Bow",
            author: "Cherry Valencia",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "November 30 2015",
                page: 351
            }
        }, {
            bookId: "26",
            bookName: "Blunt Abyss",
            author: "Ella-Mae Spencer",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "February 21 2020",
                page: 131
            }
        }],
        wishList: [{
            bookId: "46",
            bookName: "Savage Feet",
            author: "Judith Estes",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "3",
            bookName: "The Whiskey Reality",
            author: "Carolyn Cano",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy50",
    userType: "Student",
    displayName: "Armani Vang",
    photoURL: "https://picsum.photos/200'",
    email: "armani.vang@mail.com",
    biography: "It's not often you find a soggy banana on the street.",
    books: {
        favourites: [{
            bookId: "31",
            bookName: "Martian Baby",
            author: "Thierry Holman",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 28 2015"
        }, {
            bookId: "29",
            bookName: "Change of Captivity",
            author: "Landon Clements",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 25 2018"
        }, {
            bookId: "4",
            bookName: "Point Scorned",
            author: "Reanne Kennedy",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 09 2015"
        }],
        readHistory: [{
            bookId: "4",
            bookName: "Point Scorned",
            author: "Reanne Kennedy",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 09 2015",
                page: 91
            }
        }, {
            bookId: "11",
            bookName: "The Hurricane Keys",
            author: "Hugo Werner",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "October 25 2017",
                page: 22
            }
        }, {
            bookId: "43",
            bookName: "A Rat's Salvation",
            author: "Ananya Gay",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 30 2019",
                page: 232
            }
        }],
        wishList: [{
            bookId: "16",
            bookName: "Doctor in Passion",
            author: "Katya Mansell",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "7",
            bookName: "Echoes of Homicide",
            author: "Kaden Massey",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "5",
            bookName: "Prey for a Doctor",
            author: "Brent Shelton",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy51",
    userType: "Student",
    displayName: "Hisham Hunt",
    photoURL: "https://picsum.photos/200'",
    email: "hisham.hunt@mail.com",
    biography: "He felt that dining on the bridge brought romance to his relationship with his cat.",
    books: {
        favourites: [{
            bookId: "8",
            bookName: "Batman's Fortress",
            author: "Anastazja Shepard",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 19 2017"
        }, {
            bookId: "35",
            bookName: "A Shadow of Season",
            author: "Dilara Haley",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 31 2019"
        }, {
            bookId: "3",
            bookName: "The Whiskey Reality",
            author: "Carolyn Cano",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 12 2018"
        }],
        readHistory: [{
            bookId: "27",
            bookName: "The Office Eyes",
            author: "Aminah Barnes",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 13 2020",
                page: 13
            }
        }, {
            bookId: "14",
            bookName: "Country at Q",
            author: "Shanna Raymond",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 04 2018",
                page: 30
            }
        }, {
            bookId: "33",
            bookName: "Pride of Flames",
            author: "Cayden Donovan",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "August 21 2019",
                page: 4
            }
        }],
        wishList: [{
            bookId: "8",
            bookName: "Batman's Fortress",
            author: "Anastazja Shepard",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "45",
            bookName: "The Daisy Godfather",
            author: "Hayden Malone",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "50",
            bookName: "Single Templar",
            author: "Kayne Driscoll",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy52",
    userType: "Student",
    displayName: "Willis Firth",
    photoURL: "https://picsum.photos/200'",
    email: "willis.firth@mail.com",
    biography: "The shooter says goodbye to his love.",
    books: {
        favourites: [{
            bookId: "2",
            bookName: "Vineyard Waves",
            author: "Sinead Morgan",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "September 29 2018"
        }, {
            bookId: "17",
            bookName: "The Wilder Walls",
            author: "Jamie Montgomery",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 27 2015"
        }, {
            bookId: "21",
            bookName: "A Deadly Bastard",
            author: "Avani Flores",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "May 21 2015"
        }],
        readHistory: [{
            bookId: "6",
            bookName: "The Laugh of Eternity",
            author: "Aron Romero",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 04 2017",
                page: 46
            }
        }, {
            bookId: "23",
            bookName: "Tarzan and the Stone",
            author: "Nada Hoover",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 19 2015",
                page: 51
            }
        }, {
            bookId: "18",
            bookName: "The Handsome Thorn",
            author: "Ivy Singleton",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 02 2020",
                page: 33
            }
        }],
        wishList: [{
            bookId: "42",
            bookName: "The Evil Rivals",
            author: "Izabelle Benjamin",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "46",
            bookName: "Savage Feet",
            author: "Judith Estes",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "2",
            bookName: "Vineyard Waves",
            author: "Sinead Morgan",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy53",
    userType: "Student",
    displayName: "Griff Thomas",
    photoURL: "https://picsum.photos/200'",
    email: "griff.thomas@mail.com",
    biography: "He kept telling himself that one day it would all somehow make sense.",
    books: {
        favourites: [{
            bookId: "27",
            bookName: "The Office Eyes",
            author: "Aminah Barnes",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 13 2020"
        }, {
            bookId: "31",
            bookName: "Martian Baby",
            author: "Thierry Holman",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 28 2015"
        }, {
            bookId: "50",
            bookName: "Single Templar",
            author: "Kayne Driscoll",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 21 2018"
        }],
        readHistory: [{
            bookId: "36",
            bookName: "The Spirit of the Bow",
            author: "Cherry Valencia",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "November 30 2015",
                page: 351
            }
        }, {
            bookId: "5",
            bookName: "Prey for a Doctor",
            author: "Brent Shelton",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 06 2016",
                page: 218
            }
        }, {
            bookId: "34",
            bookName: "Curtain of Mystery",
            author: "Sian Huff",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 02 2016",
                page: 110
            }
        }],
        wishList: [{
            bookId: "21",
            bookName: "A Deadly Bastard",
            author: "Avani Flores",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "3",
            bookName: "The Whiskey Reality",
            author: "Carolyn Cano",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "30",
            bookName: "A Tangled Balance",
            author: "Yasemin Hart",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy54",
    userType: "Student",
    displayName: "Abi Shaw",
    photoURL: "https://picsum.photos/200'",
    email: "abi.shaw@mail.com",
    biography: "The busker hoped that the people passing by would throw money, but they threw tomatoes instead, so he exchanged his hat for a juicer.",
    books: {
        favourites: [{
            bookId: "26",
            bookName: "Blunt Abyss",
            author: "Ella-Mae Spencer",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "February 21 2020"
        }, {
            bookId: "3",
            bookName: "The Whiskey Reality",
            author: "Carolyn Cano",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 12 2018"
        }, {
            bookId: "48",
            bookName: "A Sister's Salvation",
            author: "Opal Cummings",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 13 2017"
        }],
        readHistory: [{
            bookId: "30",
            bookName: "A Tangled Balance",
            author: "Yasemin Hart",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 15 2018",
                page: 403
            }
        }, {
            bookId: "7",
            bookName: "Echoes of Homicide",
            author: "Kaden Massey",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "November 10 2015",
                page: 15
            }
        }, {
            bookId: "48",
            bookName: "A Sister's Salvation",
            author: "Opal Cummings",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "October 13 2017",
                page: 41
            }
        }],
        wishList: [{
            bookId: "2",
            bookName: "Vineyard Waves",
            author: "Sinead Morgan",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "47",
            bookName: "Witch's Debt",
            author: "Marwah Cornish",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "15",
            bookName: "The Star Visitors",
            author: "James Stark",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy55",
    userType: "Student",
    displayName: "Jeff Ponce",
    photoURL: "https://picsum.photos/200'",
    email: "jeff.ponce@mail.com",
    biography: "At that moment he wasn't listening to music, he was living an experience.",
    books: {
        favourites: [{
            bookId: "32",
            bookName: "Bullets in the Sea",
            author: "Lidia Rhodes",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 18 2015"
        }, {
            bookId: "46",
            bookName: "Savage Feet",
            author: "Judith Estes",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 08 2018"
        }, {
            bookId: "46",
            bookName: "Savage Feet",
            author: "Judith Estes",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 08 2018"
        }],
        readHistory: [{
            bookId: "21",
            bookName: "A Deadly Bastard",
            author: "Avani Flores",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "May 21 2015",
                page: 106
            }
        }, {
            bookId: "36",
            bookName: "The Spirit of the Bow",
            author: "Cherry Valencia",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "November 30 2015",
                page: 351
            }
        }, {
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 09 2017",
                page: 181
            }
        }],
        wishList: [{
            bookId: "48",
            bookName: "A Sister's Salvation",
            author: "Opal Cummings",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "45",
            bookName: "The Daisy Godfather",
            author: "Hayden Malone",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "45",
            bookName: "The Daisy Godfather",
            author: "Hayden Malone",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy56",
    userType: "Student",
    displayName: "Dominic Bonilla",
    photoURL: "https://picsum.photos/200'",
    email: "dominic.bonilla@mail.com",
    biography: "The doll spun around in circles in hopes of coming alive.",
    books: {
        favourites: [{
            bookId: "47",
            bookName: "Witch's Debt",
            author: "Marwah Cornish",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "June 17 2018"
        }, {
            bookId: "11",
            bookName: "The Hurricane Keys",
            author: "Hugo Werner",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 25 2017"
        }, {
            bookId: "22",
            bookName: "The Dragon and the Tiger",
            author: "Alex House",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "November 27 2019"
        }],
        readHistory: [{
            bookId: "15",
            bookName: "The Star Visitors",
            author: "James Stark",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 21 2016",
                page: 230
            }
        }, {
            bookId: "45",
            bookName: "The Daisy Godfather",
            author: "Hayden Malone",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "December 28 2014",
                page: 64
            }
        }, {
            bookId: "44",
            bookName: "Spare Signs",
            author: "Moshe Bowden",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 30 2019",
                page: 234
            }
        }],
        wishList: [{
            bookId: "8",
            bookName: "Batman's Fortress",
            author: "Anastazja Shepard",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "11",
            bookName: "The Hurricane Keys",
            author: "Hugo Werner",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "22",
            bookName: "The Dragon and the Tiger",
            author: "Alex House",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy57",
    userType: "Student",
    displayName: "Jaydon Mccormack",
    photoURL: "https://picsum.photos/200'",
    email: "jaydon.mccormack@mail.com",
    biography: "The Great Dane looked more like a horse than a dog.",
    books: {
        favourites: [{
            bookId: "47",
            bookName: "Witch's Debt",
            author: "Marwah Cornish",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "June 17 2018"
        }, {
            bookId: "50",
            bookName: "Single Templar",
            author: "Kayne Driscoll",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 21 2018"
        }, {
            bookId: "7",
            bookName: "Echoes of Homicide",
            author: "Kaden Massey",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "November 10 2015"
        }],
        readHistory: [{
            bookId: "3",
            bookName: "The Whiskey Reality",
            author: "Carolyn Cano",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "October 12 2018",
                page: 45
            }
        }, {
            bookId: "46",
            bookName: "Savage Feet",
            author: "Judith Estes",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 08 2018",
                page: 75
            }
        }, {
            bookId: "41",
            bookName: "Breathing Moonlight",
            author: "Ayaan Brandt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "December 29 2016",
                page: 302
            }
        }],
        wishList: [{
            bookId: "12",
            bookName: "Amber and Glory",
            author: "Ava-Mai Combs",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "20",
            bookName: "Brighton Grail",
            author: "Reanna Clayton",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy58",
    userType: "Student",
    displayName: "Cecily Cantrell",
    photoURL: "https://picsum.photos/200'",
    email: "cecily.cantrell@mail.com",
    biography: "Today we gathered moss for my uncle's wedding.",
    books: {
        favourites: [{
            bookId: "1",
            bookName: "Mystery in Trouble",
            author: "Alejandro Oakley",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "August 24 2019"
        }, {
            bookId: "12",
            bookName: "Amber and Glory",
            author: "Ava-Mai Combs",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "May 22 2018"
        }, {
            bookId: "29",
            bookName: "Change of Captivity",
            author: "Landon Clements",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 25 2018"
        }],
        readHistory: [{
            bookId: "21",
            bookName: "A Deadly Bastard",
            author: "Avani Flores",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "May 21 2015",
                page: 106
            }
        }, {
            bookId: "8",
            bookName: "Batman's Fortress",
            author: "Anastazja Shepard",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 19 2017",
                page: 84
            }
        }, {
            bookId: "34",
            bookName: "Curtain of Mystery",
            author: "Sian Huff",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 02 2016",
                page: 110
            }
        }],
        wishList: [{
            bookId: "2",
            bookName: "Vineyard Waves",
            author: "Sinead Morgan",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "1",
            bookName: "Mystery in Trouble",
            author: "Alejandro Oakley",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "36",
            bookName: "The Spirit of the Bow",
            author: "Cherry Valencia",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy59",
    userType: "Student",
    displayName: "Yassin Huff",
    photoURL: "https://picsum.photos/200'",
    email: "yassin.huff@mail.com",
    biography: "It was her first experience training a rainbow unicorn.",
    books: {
        favourites: [{
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 09 2017"
        }, {
            bookId: "15",
            bookName: "The Star Visitors",
            author: "James Stark",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 21 2016"
        }, {
            bookId: "11",
            bookName: "The Hurricane Keys",
            author: "Hugo Werner",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 25 2017"
        }],
        readHistory: [{
            bookId: "45",
            bookName: "The Daisy Godfather",
            author: "Hayden Malone",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "December 28 2014",
                page: 64
            }
        }, {
            bookId: "17",
            bookName: "The Wilder Walls",
            author: "Jamie Montgomery",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 27 2015",
                page: 118
            }
        }, {
            bookId: "49",
            bookName: "Cheyenne Malone",
            author: "Ellie Shaw",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 07 2019",
                page: 229
            }
        }],
        wishList: [{
            bookId: "36",
            bookName: "The Spirit of the Bow",
            author: "Cherry Valencia",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "24",
            bookName: "The Girl in Transylvania",
            author: "Spike Rice",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "12",
            bookName: "Amber and Glory",
            author: "Ava-Mai Combs",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy60",
    userType: "Student",
    displayName: "Huw Gordon",
    photoURL: "https://picsum.photos/200'",
    email: "huw.gordon@mail.com",
    biography: "She was too busy always talking about what she wanted to do to actually do any of it.",
    books: {
        favourites: [{
            bookId: "8",
            bookName: "Batman's Fortress",
            author: "Anastazja Shepard",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 19 2017"
        }, {
            bookId: "19",
            bookName: "The Tulip Gift",
            author: "Barry Choi",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "September 06 2016"
        }, {
            bookId: "38",
            bookName: "The Voice of the Matter",
            author: "Libbie Aguilar",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 14 2020"
        }],
        readHistory: [{
            bookId: "45",
            bookName: "The Daisy Godfather",
            author: "Hayden Malone",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "December 28 2014",
                page: 64
            }
        }, {
            bookId: "45",
            bookName: "The Daisy Godfather",
            author: "Hayden Malone",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "December 28 2014",
                page: 64
            }
        }, {
            bookId: "6",
            bookName: "The Laugh of Eternity",
            author: "Aron Romero",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 04 2017",
                page: 46
            }
        }],
        wishList: [{
            bookId: "48",
            bookName: "A Sister's Salvation",
            author: "Opal Cummings",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "38",
            bookName: "The Voice of the Matter",
            author: "Libbie Aguilar",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "38",
            bookName: "The Voice of the Matter",
            author: "Libbie Aguilar",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy61",
    userType: "Student",
    displayName: "Julien Kramer",
    photoURL: "https://picsum.photos/200'",
    email: "julien.kramer@mail.com",
    biography: "He didn’t want to go to the dentist, yet he went anyway.",
    books: {
        favourites: [{
            bookId: "23",
            bookName: "Tarzan and the Stone",
            author: "Nada Hoover",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 19 2015"
        }, {
            bookId: "43",
            bookName: "A Rat's Salvation",
            author: "Ananya Gay",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 30 2019"
        }, {
            bookId: "10",
            bookName: "The Crown of the Sunset",
            author: "Sommer Keith",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 27 2016"
        }],
        readHistory: [{
            bookId: "35",
            bookName: "A Shadow of Season",
            author: "Dilara Haley",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 31 2019",
                page: 104
            }
        }, {
            bookId: "30",
            bookName: "A Tangled Balance",
            author: "Yasemin Hart",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 15 2018",
                page: 403
            }
        }, {
            bookId: "19",
            bookName: "The Tulip Gift",
            author: "Barry Choi",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 06 2016",
                page: 211
            }
        }],
        wishList: [{
            bookId: "9",
            bookName: "Short Counsel",
            author: "Roy Nairn",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "3",
            bookName: "The Whiskey Reality",
            author: "Carolyn Cano",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "38",
            bookName: "The Voice of the Matter",
            author: "Libbie Aguilar",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy62",
    userType: "Student",
    displayName: "Hira Clayton",
    photoURL: "https://picsum.photos/200'",
    email: "hira.clayton@mail.com",
    biography: "Buried deep in the snow, he hoped his batteries were fresh in his avalanche beacon.",
    books: {
        favourites: [{
            bookId: "13",
            bookName: "The Piano Hospital",
            author: "Tonya Dyer",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "June 24 2017"
        }, {
            bookId: "3",
            bookName: "The Whiskey Reality",
            author: "Carolyn Cano",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 12 2018"
        }, {
            bookId: "27",
            bookName: "The Office Eyes",
            author: "Aminah Barnes",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 13 2020"
        }],
        readHistory: [{
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 09 2017",
                page: 181
            }
        }, {
            bookId: "46",
            bookName: "Savage Feet",
            author: "Judith Estes",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 08 2018",
                page: 75
            }
        }, {
            bookId: "34",
            bookName: "Curtain of Mystery",
            author: "Sian Huff",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 02 2016",
                page: 110
            }
        }],
        wishList: [{
            bookId: "24",
            bookName: "The Girl in Transylvania",
            author: "Spike Rice",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "50",
            bookName: "Single Templar",
            author: "Kayne Driscoll",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "42",
            bookName: "The Evil Rivals",
            author: "Izabelle Benjamin",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy63",
    userType: "Student",
    displayName: "Danny York",
    photoURL: "https://picsum.photos/200'",
    email: "danny.york@mail.com",
    biography: "She was the type of girl who wanted to live in a pink house.",
    books: {
        favourites: [{
            bookId: "33",
            bookName: "Pride of Flames",
            author: "Cayden Donovan",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "August 21 2019"
        }, {
            bookId: "10",
            bookName: "The Crown of the Sunset",
            author: "Sommer Keith",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 27 2016"
        }, {
            bookId: "3",
            bookName: "The Whiskey Reality",
            author: "Carolyn Cano",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 12 2018"
        }],
        readHistory: [{
            bookId: "48",
            bookName: "A Sister's Salvation",
            author: "Opal Cummings",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "October 13 2017",
                page: 41
            }
        }, {
            bookId: "34",
            bookName: "Curtain of Mystery",
            author: "Sian Huff",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 02 2016",
                page: 110
            }
        }, {
            bookId: "25",
            bookName: "Maigret and the Pickpocket",
            author: "Jonny Simon",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 09 2018",
                page: 56
            }
        }],
        wishList: [{
            bookId: "50",
            bookName: "Single Templar",
            author: "Kayne Driscoll",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "30",
            bookName: "A Tangled Balance",
            author: "Yasemin Hart",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "22",
            bookName: "The Dragon and the Tiger",
            author: "Alex House",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy64",
    userType: "Student",
    displayName: "Gage Osborn",
    photoURL: "https://picsum.photos/200'",
    email: "gage.osborn@mail.com",
    biography: "Hit me with your pet shark!",
    books: {
        favourites: [{
            bookId: "32",
            bookName: "Bullets in the Sea",
            author: "Lidia Rhodes",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 18 2015"
        }, {
            bookId: "26",
            bookName: "Blunt Abyss",
            author: "Ella-Mae Spencer",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "February 21 2020"
        }, {
            bookId: "29",
            bookName: "Change of Captivity",
            author: "Landon Clements",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 25 2018"
        }],
        readHistory: [{
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 09 2017",
                page: 181
            }
        }, {
            bookId: "2",
            bookName: "Vineyard Waves",
            author: "Sinead Morgan",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 29 2018",
                page: 206
            }
        }, {
            bookId: "7",
            bookName: "Echoes of Homicide",
            author: "Kaden Massey",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "November 10 2015",
                page: 15
            }
        }],
        wishList: [{
            bookId: "34",
            bookName: "Curtain of Mystery",
            author: "Sian Huff",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "4",
            bookName: "Point Scorned",
            author: "Reanne Kennedy",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "45",
            bookName: "The Daisy Godfather",
            author: "Hayden Malone",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy65",
    userType: "Student",
    displayName: "Asher Whitworth",
    photoURL: "https://picsum.photos/200'",
    email: "asher.whitworth@mail.com",
    biography: "People keep telling me orange but I still prefer pink.",
    books: {
        favourites: [{
            bookId: "22",
            bookName: "The Dragon and the Tiger",
            author: "Alex House",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "November 27 2019"
        }, {
            bookId: "17",
            bookName: "The Wilder Walls",
            author: "Jamie Montgomery",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 27 2015"
        }, {
            bookId: "49",
            bookName: "Cheyenne Malone",
            author: "Ellie Shaw",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 07 2019"
        }],
        readHistory: [{
            bookId: "11",
            bookName: "The Hurricane Keys",
            author: "Hugo Werner",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "October 25 2017",
                page: 22
            }
        }, {
            bookId: "16",
            bookName: "Doctor in Passion",
            author: "Katya Mansell",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "August 29 2019",
                page: 227
            }
        }, {
            bookId: "22",
            bookName: "The Dragon and the Tiger",
            author: "Alex House",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "November 27 2019",
                page: 224
            }
        }],
        wishList: [{
            bookId: "43",
            bookName: "A Rat's Salvation",
            author: "Ananya Gay",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "6",
            bookName: "The Laugh of Eternity",
            author: "Aron Romero",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "40",
            bookName: "Fine Karma",
            author: "Sofia Pratt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy66",
    userType: "Student",
    displayName: "Tyra Willis",
    photoURL: "https://picsum.photos/200'",
    email: "tyra.willis@mail.com",
    biography: "I caught my squirrel rustling through my gym bag.",
    books: {
        favourites: [{
            bookId: "16",
            bookName: "Doctor in Passion",
            author: "Katya Mansell",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "August 29 2019"
        }, {
            bookId: "14",
            bookName: "Country at Q",
            author: "Shanna Raymond",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 04 2018"
        }, {
            bookId: "17",
            bookName: "The Wilder Walls",
            author: "Jamie Montgomery",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 27 2015"
        }],
        readHistory: [{
            bookId: "12",
            bookName: "Amber and Glory",
            author: "Ava-Mai Combs",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "May 22 2018",
                page: 281
            }
        }, {
            bookId: "26",
            bookName: "Blunt Abyss",
            author: "Ella-Mae Spencer",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "February 21 2020",
                page: 131
            }
        }, {
            bookId: "13",
            bookName: "The Piano Hospital",
            author: "Tonya Dyer",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "June 24 2017",
                page: 14
            }
        }],
        wishList: [{
            bookId: "26",
            bookName: "Blunt Abyss",
            author: "Ella-Mae Spencer",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "21",
            bookName: "A Deadly Bastard",
            author: "Avani Flores",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "2",
            bookName: "Vineyard Waves",
            author: "Sinead Morgan",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy67",
    userType: "Student",
    displayName: "Muhamed Duncan",
    photoURL: "https://picsum.photos/200'",
    email: "muhamed.duncan@mail.com",
    biography: "They called out her name time and again, but were met with nothing but silence.",
    books: {
        favourites: [{
            bookId: "29",
            bookName: "Change of Captivity",
            author: "Landon Clements",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 25 2018"
        }, {
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 09 2017"
        }, {
            bookId: "26",
            bookName: "Blunt Abyss",
            author: "Ella-Mae Spencer",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "February 21 2020"
        }],
        readHistory: [{
            bookId: "9",
            bookName: "Short Counsel",
            author: "Roy Nairn",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 10 2016",
                page: 215
            }
        }, {
            bookId: "39",
            bookName: "The Specialist's Vacation",
            author: "Devonte Kirk",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 16 2015",
                page: 44
            }
        }, {
            bookId: "46",
            bookName: "Savage Feet",
            author: "Judith Estes",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 08 2018",
                page: 75
            }
        }],
        wishList: [{
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "17",
            bookName: "The Wilder Walls",
            author: "Jamie Montgomery",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "15",
            bookName: "The Star Visitors",
            author: "James Stark",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy68",
    userType: "Student",
    displayName: "Ahyan Boyd",
    photoURL: "https://picsum.photos/200'",
    email: "ahyan.boyd@mail.com",
    biography: "Iron pyrite is the most foolish of all minerals.",
    books: {
        favourites: [{
            bookId: "30",
            bookName: "A Tangled Balance",
            author: "Yasemin Hart",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 15 2018"
        }, {
            bookId: "36",
            bookName: "The Spirit of the Bow",
            author: "Cherry Valencia",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "November 30 2015"
        }, {
            bookId: "33",
            bookName: "Pride of Flames",
            author: "Cayden Donovan",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "August 21 2019"
        }],
        readHistory: [{
            bookId: "31",
            bookName: "Martian Baby",
            author: "Thierry Holman",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 28 2015",
                page: 147
            }
        }, {
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 09 2017",
                page: 181
            }
        }, {
            bookId: "11",
            bookName: "The Hurricane Keys",
            author: "Hugo Werner",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "October 25 2017",
                page: 22
            }
        }],
        wishList: [{
            bookId: "41",
            bookName: "Breathing Moonlight",
            author: "Ayaan Brandt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "8",
            bookName: "Batman's Fortress",
            author: "Anastazja Shepard",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "33",
            bookName: "Pride of Flames",
            author: "Cayden Donovan",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy69",
    userType: "Student",
    displayName: "Jayden-Lee Solomon",
    photoURL: "https://picsum.photos/200'",
    email: "jayden-lee.solomon@mail.com",
    biography: "I like to leave work after my eight-hour tea-break.",
    books: {
        favourites: [{
            bookId: "18",
            bookName: "The Handsome Thorn",
            author: "Ivy Singleton",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 02 2020"
        }, {
            bookId: "41",
            bookName: "Breathing Moonlight",
            author: "Ayaan Brandt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "December 29 2016"
        }, {
            bookId: "31",
            bookName: "Martian Baby",
            author: "Thierry Holman",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 28 2015"
        }],
        readHistory: [{
            bookId: "40",
            bookName: "Fine Karma",
            author: "Sofia Pratt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "December 16 2017",
                page: 89
            }
        }, {
            bookId: "13",
            bookName: "The Piano Hospital",
            author: "Tonya Dyer",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "June 24 2017",
                page: 14
            }
        }, {
            bookId: "33",
            bookName: "Pride of Flames",
            author: "Cayden Donovan",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "August 21 2019",
                page: 4
            }
        }],
        wishList: [{
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "16",
            bookName: "Doctor in Passion",
            author: "Katya Mansell",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "20",
            bookName: "Brighton Grail",
            author: "Reanna Clayton",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy70",
    userType: "Student",
    displayName: "Eve Wynn",
    photoURL: "https://picsum.photos/200'",
    email: "eve.wynn@mail.com",
    biography: "I would be delighted if the sea were full of cucumber juice.",
    books: {
        favourites: [{
            bookId: "33",
            bookName: "Pride of Flames",
            author: "Cayden Donovan",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "August 21 2019"
        }, {
            bookId: "6",
            bookName: "The Laugh of Eternity",
            author: "Aron Romero",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 04 2017"
        }, {
            bookId: "14",
            bookName: "Country at Q",
            author: "Shanna Raymond",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 04 2018"
        }],
        readHistory: [{
            bookId: "40",
            bookName: "Fine Karma",
            author: "Sofia Pratt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "December 16 2017",
                page: 89
            }
        }, {
            bookId: "45",
            bookName: "The Daisy Godfather",
            author: "Hayden Malone",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "December 28 2014",
                page: 64
            }
        }, {
            bookId: "45",
            bookName: "The Daisy Godfather",
            author: "Hayden Malone",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "December 28 2014",
                page: 64
            }
        }],
        wishList: [{
            bookId: "50",
            bookName: "Single Templar",
            author: "Kayne Driscoll",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "32",
            bookName: "Bullets in the Sea",
            author: "Lidia Rhodes",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "47",
            bookName: "Witch's Debt",
            author: "Marwah Cornish",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy71",
    userType: "Student",
    displayName: "Ieuan Betts",
    photoURL: "https://picsum.photos/200'",
    email: "ieuan.betts@mail.com",
    biography: "There is no better feeling than staring at a wall with closed eyes.",
    books: {
        favourites: [{
            bookId: "5",
            bookName: "Prey for a Doctor",
            author: "Brent Shelton",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "September 06 2016"
        }, {
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 09 2017"
        }, {
            bookId: "41",
            bookName: "Breathing Moonlight",
            author: "Ayaan Brandt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "December 29 2016"
        }],
        readHistory: [{
            bookId: "43",
            bookName: "A Rat's Salvation",
            author: "Ananya Gay",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 30 2019",
                page: 232
            }
        }, {
            bookId: "20",
            bookName: "Brighton Grail",
            author: "Reanna Clayton",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "May 05 2018",
                page: 45
            }
        }, {
            bookId: "38",
            bookName: "The Voice of the Matter",
            author: "Libbie Aguilar",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 14 2020",
                page: 295
            }
        }],
        wishList: [{
            bookId: "5",
            bookName: "Prey for a Doctor",
            author: "Brent Shelton",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "2",
            bookName: "Vineyard Waves",
            author: "Sinead Morgan",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy72",
    userType: "Student",
    displayName: "Tasnim Schaefer",
    photoURL: "https://picsum.photos/200'",
    email: "tasnim.schaefer@mail.com",
    biography: "I liked their first two albums but changed my mind after that charity gig.",
    books: {
        favourites: [{
            bookId: "9",
            bookName: "Short Counsel",
            author: "Roy Nairn",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 10 2016"
        }, {
            bookId: "41",
            bookName: "Breathing Moonlight",
            author: "Ayaan Brandt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "December 29 2016"
        }, {
            bookId: "31",
            bookName: "Martian Baby",
            author: "Thierry Holman",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 28 2015"
        }],
        readHistory: [{
            bookId: "11",
            bookName: "The Hurricane Keys",
            author: "Hugo Werner",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "October 25 2017",
                page: 22
            }
        }, {
            bookId: "45",
            bookName: "The Daisy Godfather",
            author: "Hayden Malone",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "December 28 2014",
                page: 64
            }
        }, {
            bookId: "8",
            bookName: "Batman's Fortress",
            author: "Anastazja Shepard",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 19 2017",
                page: 84
            }
        }],
        wishList: [{
            bookId: "34",
            bookName: "Curtain of Mystery",
            author: "Sian Huff",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "4",
            bookName: "Point Scorned",
            author: "Reanne Kennedy",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "23",
            bookName: "Tarzan and the Stone",
            author: "Nada Hoover",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy73",
    userType: "Student",
    displayName: "Eshaal Shepherd",
    photoURL: "https://picsum.photos/200'",
    email: "eshaal.shepherd@mail.com",
    biography: "As the years pass by, we all know owners look more and more like their dogs.",
    books: {
        favourites: [{
            bookId: "43",
            bookName: "A Rat's Salvation",
            author: "Ananya Gay",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 30 2019"
        }, {
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 09 2017"
        }, {
            bookId: "25",
            bookName: "Maigret and the Pickpocket",
            author: "Jonny Simon",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 09 2018"
        }],
        readHistory: [{
            bookId: "32",
            bookName: "Bullets in the Sea",
            author: "Lidia Rhodes",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 18 2015",
                page: 88
            }
        }, {
            bookId: "18",
            bookName: "The Handsome Thorn",
            author: "Ivy Singleton",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 02 2020",
                page: 33
            }
        }, {
            bookId: "48",
            bookName: "A Sister's Salvation",
            author: "Opal Cummings",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "October 13 2017",
                page: 41
            }
        }],
        wishList: [{
            bookId: "27",
            bookName: "The Office Eyes",
            author: "Aminah Barnes",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "16",
            bookName: "Doctor in Passion",
            author: "Katya Mansell",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "21",
            bookName: "A Deadly Bastard",
            author: "Avani Flores",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy74",
    userType: "Student",
    displayName: "Lexie Mejia",
    photoURL: "https://picsum.photos/200'",
    email: "lexie.mejia@mail.com",
    biography: "They're playing the piano while flying in the plane.",
    books: {
        favourites: [{
            bookId: "25",
            bookName: "Maigret and the Pickpocket",
            author: "Jonny Simon",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 09 2018"
        }, {
            bookId: "26",
            bookName: "Blunt Abyss",
            author: "Ella-Mae Spencer",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "February 21 2020"
        }, {
            bookId: "47",
            bookName: "Witch's Debt",
            author: "Marwah Cornish",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "June 17 2018"
        }],
        readHistory: [{
            bookId: "10",
            bookName: "The Crown of the Sunset",
            author: "Sommer Keith",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 27 2016",
                page: 205
            }
        }, {
            bookId: "43",
            bookName: "A Rat's Salvation",
            author: "Ananya Gay",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 30 2019",
                page: 232
            }
        }, {
            bookId: "33",
            bookName: "Pride of Flames",
            author: "Cayden Donovan",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "August 21 2019",
                page: 4
            }
        }],
        wishList: [{
            bookId: "46",
            bookName: "Savage Feet",
            author: "Judith Estes",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "40",
            bookName: "Fine Karma",
            author: "Sofia Pratt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "38",
            bookName: "The Voice of the Matter",
            author: "Libbie Aguilar",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy75",
    userType: "Student",
    displayName: "Tolga Calvert",
    photoURL: "https://picsum.photos/200'",
    email: "tolga.calvert@mail.com",
    biography: "He had a vague sense that trees gave birth to dinosaurs.",
    books: {
        favourites: [{
            bookId: "49",
            bookName: "Cheyenne Malone",
            author: "Ellie Shaw",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 07 2019"
        }, {
            bookId: "12",
            bookName: "Amber and Glory",
            author: "Ava-Mai Combs",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "May 22 2018"
        }, {
            bookId: "33",
            bookName: "Pride of Flames",
            author: "Cayden Donovan",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "August 21 2019"
        }],
        readHistory: [{
            bookId: "48",
            bookName: "A Sister's Salvation",
            author: "Opal Cummings",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "October 13 2017",
                page: 41
            }
        }, {
            bookId: "34",
            bookName: "Curtain of Mystery",
            author: "Sian Huff",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 02 2016",
                page: 110
            }
        }, {
            bookId: "21",
            bookName: "A Deadly Bastard",
            author: "Avani Flores",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "May 21 2015",
                page: 106
            }
        }],
        wishList: [{
            bookId: "10",
            bookName: "The Crown of the Sunset",
            author: "Sommer Keith",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "5",
            bookName: "Prey for a Doctor",
            author: "Brent Shelton",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "2",
            bookName: "Vineyard Waves",
            author: "Sinead Morgan",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy76",
    userType: "Student",
    displayName: "Said Tait",
    photoURL: "https://picsum.photos/200'",
    email: "said.tait@mail.com",
    biography: "There are over 500 starfish in the bathroom drawer.",
    books: {
        favourites: [{
            bookId: "29",
            bookName: "Change of Captivity",
            author: "Landon Clements",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 25 2018"
        }, {
            bookId: "46",
            bookName: "Savage Feet",
            author: "Judith Estes",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 08 2018"
        }, {
            bookId: "45",
            bookName: "The Daisy Godfather",
            author: "Hayden Malone",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "December 28 2014"
        }],
        readHistory: [{
            bookId: "26",
            bookName: "Blunt Abyss",
            author: "Ella-Mae Spencer",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "February 21 2020",
                page: 131
            }
        }, {
            bookId: "40",
            bookName: "Fine Karma",
            author: "Sofia Pratt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "December 16 2017",
                page: 89
            }
        }, {
            bookId: "18",
            bookName: "The Handsome Thorn",
            author: "Ivy Singleton",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 02 2020",
                page: 33
            }
        }],
        wishList: [{
            bookId: "5",
            bookName: "Prey for a Doctor",
            author: "Brent Shelton",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "8",
            bookName: "Batman's Fortress",
            author: "Anastazja Shepard",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy77",
    userType: "Student",
    displayName: "Wanda House",
    photoURL: "https://picsum.photos/200'",
    email: "wanda.house@mail.com",
    biography: "She saw the brake lights, but not in time.",
    books: {
        favourites: [{
            bookId: "17",
            bookName: "The Wilder Walls",
            author: "Jamie Montgomery",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 27 2015"
        }, {
            bookId: "25",
            bookName: "Maigret and the Pickpocket",
            author: "Jonny Simon",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 09 2018"
        }, {
            bookId: "44",
            bookName: "Spare Signs",
            author: "Moshe Bowden",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 30 2019"
        }],
        readHistory: [{
            bookId: "16",
            bookName: "Doctor in Passion",
            author: "Katya Mansell",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "August 29 2019",
                page: 227
            }
        }, {
            bookId: "5",
            bookName: "Prey for a Doctor",
            author: "Brent Shelton",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 06 2016",
                page: 218
            }
        }, {
            bookId: "26",
            bookName: "Blunt Abyss",
            author: "Ella-Mae Spencer",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "February 21 2020",
                page: 131
            }
        }],
        wishList: [{
            bookId: "45",
            bookName: "The Daisy Godfather",
            author: "Hayden Malone",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "48",
            bookName: "A Sister's Salvation",
            author: "Opal Cummings",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "10",
            bookName: "The Crown of the Sunset",
            author: "Sommer Keith",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy78",
    userType: "Student",
    displayName: "Chester Conway",
    photoURL: "https://picsum.photos/200'",
    email: "chester.conway@mail.com",
    biography: "Shakespeare was a famous 17th-century diesel mechanic.",
    books: {
        favourites: [{
            bookId: "37",
            bookName: "Risk of Illusion",
            author: "Atlas Guthrie",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 08 2016"
        }, {
            bookId: "1",
            bookName: "Mystery in Trouble",
            author: "Alejandro Oakley",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "August 24 2019"
        }, {
            bookId: "13",
            bookName: "The Piano Hospital",
            author: "Tonya Dyer",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "June 24 2017"
        }],
        readHistory: [{
            bookId: "39",
            bookName: "The Specialist's Vacation",
            author: "Devonte Kirk",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 16 2015",
                page: 44
            }
        }, {
            bookId: "31",
            bookName: "Martian Baby",
            author: "Thierry Holman",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 28 2015",
                page: 147
            }
        }, {
            bookId: "7",
            bookName: "Echoes of Homicide",
            author: "Kaden Massey",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "November 10 2015",
                page: 15
            }
        }],
        wishList: [{
            bookId: "33",
            bookName: "Pride of Flames",
            author: "Cayden Donovan",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "42",
            bookName: "The Evil Rivals",
            author: "Izabelle Benjamin",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "26",
            bookName: "Blunt Abyss",
            author: "Ella-Mae Spencer",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy79",
    userType: "Student",
    displayName: "Reya Clifford",
    photoURL: "https://picsum.photos/200'",
    email: "reya.clifford@mail.com",
    biography: "She did her best to help him.",
    books: {
        favourites: [{
            bookId: "47",
            bookName: "Witch's Debt",
            author: "Marwah Cornish",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "June 17 2018"
        }, {
            bookId: "50",
            bookName: "Single Templar",
            author: "Kayne Driscoll",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 21 2018"
        }, {
            bookId: "34",
            bookName: "Curtain of Mystery",
            author: "Sian Huff",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 02 2016"
        }],
        readHistory: [{
            bookId: "40",
            bookName: "Fine Karma",
            author: "Sofia Pratt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "December 16 2017",
                page: 89
            }
        }, {
            bookId: "14",
            bookName: "Country at Q",
            author: "Shanna Raymond",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 04 2018",
                page: 30
            }
        }, {
            bookId: "44",
            bookName: "Spare Signs",
            author: "Moshe Bowden",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 30 2019",
                page: 234
            }
        }],
        wishList: [{
            bookId: "35",
            bookName: "A Shadow of Season",
            author: "Dilara Haley",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "44",
            bookName: "Spare Signs",
            author: "Moshe Bowden",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "41",
            bookName: "Breathing Moonlight",
            author: "Ayaan Brandt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy80",
    userType: "Student",
    displayName: "Owen Carson",
    photoURL: "https://picsum.photos/200'",
    email: "owen.carson@mail.com",
    biography: "Today is the day I'll finally know what brick tastes like.",
    books: {
        favourites: [{
            bookId: "5",
            bookName: "Prey for a Doctor",
            author: "Brent Shelton",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "September 06 2016"
        }, {
            bookId: "18",
            bookName: "The Handsome Thorn",
            author: "Ivy Singleton",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 02 2020"
        }, {
            bookId: "33",
            bookName: "Pride of Flames",
            author: "Cayden Donovan",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "August 21 2019"
        }],
        readHistory: [{
            bookId: "7",
            bookName: "Echoes of Homicide",
            author: "Kaden Massey",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "November 10 2015",
                page: 15
            }
        }, {
            bookId: "6",
            bookName: "The Laugh of Eternity",
            author: "Aron Romero",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 04 2017",
                page: 46
            }
        }, {
            bookId: "41",
            bookName: "Breathing Moonlight",
            author: "Ayaan Brandt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "December 29 2016",
                page: 302
            }
        }],
        wishList: [{
            bookId: "49",
            bookName: "Cheyenne Malone",
            author: "Ellie Shaw",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "9",
            bookName: "Short Counsel",
            author: "Roy Nairn",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "12",
            bookName: "Amber and Glory",
            author: "Ava-Mai Combs",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy81",
    userType: "Student",
    displayName: "Randall Smyth",
    photoURL: "https://picsum.photos/200'",
    email: "randall.smyth@mail.com",
    biography: "He uses onomatopoeia as a weapon of mental destruction.",
    books: {
        favourites: [{
            bookId: "16",
            bookName: "Doctor in Passion",
            author: "Katya Mansell",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "August 29 2019"
        }, {
            bookId: "40",
            bookName: "Fine Karma",
            author: "Sofia Pratt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "December 16 2017"
        }, {
            bookId: "37",
            bookName: "Risk of Illusion",
            author: "Atlas Guthrie",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 08 2016"
        }],
        readHistory: [{
            bookId: "20",
            bookName: "Brighton Grail",
            author: "Reanna Clayton",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "May 05 2018",
                page: 45
            }
        }, {
            bookId: "26",
            bookName: "Blunt Abyss",
            author: "Ella-Mae Spencer",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "February 21 2020",
                page: 131
            }
        }, {
            bookId: "30",
            bookName: "A Tangled Balance",
            author: "Yasemin Hart",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 15 2018",
                page: 403
            }
        }],
        wishList: [{
            bookId: "25",
            bookName: "Maigret and the Pickpocket",
            author: "Jonny Simon",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "1",
            bookName: "Mystery in Trouble",
            author: "Alejandro Oakley",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "37",
            bookName: "Risk of Illusion",
            author: "Atlas Guthrie",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy82",
    userType: "Student",
    displayName: "Leona Rodgers",
    photoURL: "https://picsum.photos/200'",
    email: "leona.rodgers@mail.com",
    biography: "He wore the surgical mask in public not to keep from catching a virus, but to keep people away from him.",
    books: {
        favourites: [{
            bookId: "30",
            bookName: "A Tangled Balance",
            author: "Yasemin Hart",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 15 2018"
        }, {
            bookId: "5",
            bookName: "Prey for a Doctor",
            author: "Brent Shelton",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "September 06 2016"
        }, {
            bookId: "38",
            bookName: "The Voice of the Matter",
            author: "Libbie Aguilar",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 14 2020"
        }],
        readHistory: [{
            bookId: "10",
            bookName: "The Crown of the Sunset",
            author: "Sommer Keith",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 27 2016",
                page: 205
            }
        }, {
            bookId: "34",
            bookName: "Curtain of Mystery",
            author: "Sian Huff",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 02 2016",
                page: 110
            }
        }, {
            bookId: "42",
            bookName: "The Evil Rivals",
            author: "Izabelle Benjamin",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 28 2020",
                page: 75
            }
        }],
        wishList: [{
            bookId: "16",
            bookName: "Doctor in Passion",
            author: "Katya Mansell",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "6",
            bookName: "The Laugh of Eternity",
            author: "Aron Romero",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "47",
            bookName: "Witch's Debt",
            author: "Marwah Cornish",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy83",
    userType: "Student",
    displayName: "David Connolly",
    photoURL: "https://picsum.photos/200'",
    email: "david.connolly@mail.com",
    biography: "I covered my friend in baby oil.",
    books: {
        favourites: [{
            bookId: "50",
            bookName: "Single Templar",
            author: "Kayne Driscoll",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 21 2018"
        }, {
            bookId: "3",
            bookName: "The Whiskey Reality",
            author: "Carolyn Cano",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 12 2018"
        }, {
            bookId: "33",
            bookName: "Pride of Flames",
            author: "Cayden Donovan",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "August 21 2019"
        }],
        readHistory: [{
            bookId: "46",
            bookName: "Savage Feet",
            author: "Judith Estes",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 08 2018",
                page: 75
            }
        }, {
            bookId: "45",
            bookName: "The Daisy Godfather",
            author: "Hayden Malone",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "December 28 2014",
                page: 64
            }
        }, {
            bookId: "29",
            bookName: "Change of Captivity",
            author: "Landon Clements",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 25 2018",
                page: 87
            }
        }],
        wishList: [{
            bookId: "3",
            bookName: "The Whiskey Reality",
            author: "Carolyn Cano",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "49",
            bookName: "Cheyenne Malone",
            author: "Ellie Shaw",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "14",
            bookName: "Country at Q",
            author: "Shanna Raymond",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy84",
    userType: "Student",
    displayName: "Euan Castaneda",
    photoURL: "https://picsum.photos/200'",
    email: "euan.castaneda@mail.com",
    biography: "Twin 4-month-olds slept in the shade of the palm tree while the mother tanned in the sun.",
    books: {
        favourites: [{
            bookId: "9",
            bookName: "Short Counsel",
            author: "Roy Nairn",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 10 2016"
        }, {
            bookId: "45",
            bookName: "The Daisy Godfather",
            author: "Hayden Malone",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "December 28 2014"
        }, {
            bookId: "25",
            bookName: "Maigret and the Pickpocket",
            author: "Jonny Simon",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 09 2018"
        }],
        readHistory: [{
            bookId: "22",
            bookName: "The Dragon and the Tiger",
            author: "Alex House",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "November 27 2019",
                page: 224
            }
        }, {
            bookId: "20",
            bookName: "Brighton Grail",
            author: "Reanna Clayton",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "May 05 2018",
                page: 45
            }
        }, {
            bookId: "24",
            bookName: "The Girl in Transylvania",
            author: "Spike Rice",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 11 2017",
                page: 298
            }
        }],
        wishList: [{
            bookId: "48",
            bookName: "A Sister's Salvation",
            author: "Opal Cummings",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "22",
            bookName: "The Dragon and the Tiger",
            author: "Alex House",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "11",
            bookName: "The Hurricane Keys",
            author: "Hugo Werner",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy85",
    userType: "Student",
    displayName: "Chanel Swift",
    photoURL: "https://picsum.photos/200'",
    email: "chanel.swift@mail.com",
    biography: "Nobody questions who built the pyramids in Mexico.",
    books: {
        favourites: [{
            bookId: "1",
            bookName: "Mystery in Trouble",
            author: "Alejandro Oakley",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "August 24 2019"
        }, {
            bookId: "50",
            bookName: "Single Templar",
            author: "Kayne Driscoll",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 21 2018"
        }, {
            bookId: "39",
            bookName: "The Specialist's Vacation",
            author: "Devonte Kirk",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 16 2015"
        }],
        readHistory: [{
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 09 2017",
                page: 181
            }
        }, {
            bookId: "30",
            bookName: "A Tangled Balance",
            author: "Yasemin Hart",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 15 2018",
                page: 403
            }
        }, {
            bookId: "40",
            bookName: "Fine Karma",
            author: "Sofia Pratt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "December 16 2017",
                page: 89
            }
        }],
        wishList: [{
            bookId: "34",
            bookName: "Curtain of Mystery",
            author: "Sian Huff",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "17",
            bookName: "The Wilder Walls",
            author: "Jamie Montgomery",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy86",
    userType: "Student",
    displayName: "Paulina Arroyo",
    photoURL: "https://picsum.photos/200'",
    email: "paulina.arroyo@mail.com",
    biography: "She opened up her third bottle of wine of the night.",
    books: {
        favourites: [{
            bookId: "40",
            bookName: "Fine Karma",
            author: "Sofia Pratt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "December 16 2017"
        }, {
            bookId: "40",
            bookName: "Fine Karma",
            author: "Sofia Pratt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "December 16 2017"
        }, {
            bookId: "5",
            bookName: "Prey for a Doctor",
            author: "Brent Shelton",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "September 06 2016"
        }],
        readHistory: [{
            bookId: "13",
            bookName: "The Piano Hospital",
            author: "Tonya Dyer",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "June 24 2017",
                page: 14
            }
        }, {
            bookId: "29",
            bookName: "Change of Captivity",
            author: "Landon Clements",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 25 2018",
                page: 87
            }
        }, {
            bookId: "39",
            bookName: "The Specialist's Vacation",
            author: "Devonte Kirk",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 16 2015",
                page: 44
            }
        }],
        wishList: [{
            bookId: "15",
            bookName: "The Star Visitors",
            author: "James Stark",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "50",
            bookName: "Single Templar",
            author: "Kayne Driscoll",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "35",
            bookName: "A Shadow of Season",
            author: "Dilara Haley",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy87",
    userType: "Student",
    displayName: "Freja Schroeder",
    photoURL: "https://picsum.photos/200'",
    email: "freja.schroeder@mail.com",
    biography: "We will not allow you to bring your pet armadillo along.",
    books: {
        favourites: [{
            bookId: "44",
            bookName: "Spare Signs",
            author: "Moshe Bowden",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 30 2019"
        }, {
            bookId: "21",
            bookName: "A Deadly Bastard",
            author: "Avani Flores",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "May 21 2015"
        }, {
            bookId: "12",
            bookName: "Amber and Glory",
            author: "Ava-Mai Combs",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "May 22 2018"
        }],
        readHistory: [{
            bookId: "8",
            bookName: "Batman's Fortress",
            author: "Anastazja Shepard",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 19 2017",
                page: 84
            }
        }, {
            bookId: "8",
            bookName: "Batman's Fortress",
            author: "Anastazja Shepard",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 19 2017",
                page: 84
            }
        }, {
            bookId: "25",
            bookName: "Maigret and the Pickpocket",
            author: "Jonny Simon",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 09 2018",
                page: 56
            }
        }],
        wishList: [{
            bookId: "3",
            bookName: "The Whiskey Reality",
            author: "Carolyn Cano",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "40",
            bookName: "Fine Karma",
            author: "Sofia Pratt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "47",
            bookName: "Witch's Debt",
            author: "Marwah Cornish",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy88",
    userType: "Student",
    displayName: "Aida Humphreys",
    photoURL: "https://picsum.photos/200'",
    email: "aida.humphreys@mail.com",
    biography: "The old apple revels in its authority.",
    books: {
        favourites: [{
            bookId: "49",
            bookName: "Cheyenne Malone",
            author: "Ellie Shaw",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 07 2019"
        }, {
            bookId: "9",
            bookName: "Short Counsel",
            author: "Roy Nairn",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 10 2016"
        }, {
            bookId: "50",
            bookName: "Single Templar",
            author: "Kayne Driscoll",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 21 2018"
        }],
        readHistory: [{
            bookId: "1",
            bookName: "Mystery in Trouble",
            author: "Alejandro Oakley",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "August 24 2019",
                page: 244
            }
        }, {
            bookId: "6",
            bookName: "The Laugh of Eternity",
            author: "Aron Romero",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 04 2017",
                page: 46
            }
        }, {
            bookId: "10",
            bookName: "The Crown of the Sunset",
            author: "Sommer Keith",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 27 2016",
                page: 205
            }
        }],
        wishList: [{
            bookId: "36",
            bookName: "The Spirit of the Bow",
            author: "Cherry Valencia",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "6",
            bookName: "The Laugh of Eternity",
            author: "Aron Romero",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "30",
            bookName: "A Tangled Balance",
            author: "Yasemin Hart",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy89",
    userType: "Student",
    displayName: "Jordan-Lee Mclaughlin",
    photoURL: "https://picsum.photos/200'",
    email: "jordan-lee.mclaughlin@mail.com",
    biography: "He liked to play with words in the bathtub.",
    books: {
        favourites: [{
            bookId: "27",
            bookName: "The Office Eyes",
            author: "Aminah Barnes",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 13 2020"
        }, {
            bookId: "11",
            bookName: "The Hurricane Keys",
            author: "Hugo Werner",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 25 2017"
        }, {
            bookId: "27",
            bookName: "The Office Eyes",
            author: "Aminah Barnes",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 13 2020"
        }],
        readHistory: [{
            bookId: "20",
            bookName: "Brighton Grail",
            author: "Reanna Clayton",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "May 05 2018",
                page: 45
            }
        }, {
            bookId: "3",
            bookName: "The Whiskey Reality",
            author: "Carolyn Cano",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "October 12 2018",
                page: 45
            }
        }, {
            bookId: "9",
            bookName: "Short Counsel",
            author: "Roy Nairn",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 10 2016",
                page: 215
            }
        }],
        wishList: [{
            bookId: "21",
            bookName: "A Deadly Bastard",
            author: "Avani Flores",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "50",
            bookName: "Single Templar",
            author: "Kayne Driscoll",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "19",
            bookName: "The Tulip Gift",
            author: "Barry Choi",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy90",
    userType: "Student",
    displayName: "Portia Barrow",
    photoURL: "https://picsum.photos/200'",
    email: "portia.barrow@mail.com",
    biography: "Best friends are like old tomatoes and shoelaces.",
    books: {
        favourites: [{
            bookId: "11",
            bookName: "The Hurricane Keys",
            author: "Hugo Werner",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 25 2017"
        }, {
            bookId: "4",
            bookName: "Point Scorned",
            author: "Reanne Kennedy",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 09 2015"
        }, {
            bookId: "9",
            bookName: "Short Counsel",
            author: "Roy Nairn",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 10 2016"
        }],
        readHistory: [{
            bookId: "6",
            bookName: "The Laugh of Eternity",
            author: "Aron Romero",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 04 2017",
                page: 46
            }
        }, {
            bookId: "26",
            bookName: "Blunt Abyss",
            author: "Ella-Mae Spencer",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "February 21 2020",
                page: 131
            }
        }, {
            bookId: "24",
            bookName: "The Girl in Transylvania",
            author: "Spike Rice",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 11 2017",
                page: 298
            }
        }],
        wishList: [{
            bookId: "6",
            bookName: "The Laugh of Eternity",
            author: "Aron Romero",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "41",
            bookName: "Breathing Moonlight",
            author: "Ayaan Brandt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "47",
            bookName: "Witch's Debt",
            author: "Marwah Cornish",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy91",
    userType: "Student",
    displayName: "Maja Odling",
    photoURL: "https://picsum.photos/200'",
    email: "maja.odling@mail.com",
    biography: "As she walked along the street and looked in the gutter, she realized facemasks had become the new cigarette butts.",
    books: {
        favourites: [{
            bookId: "27",
            bookName: "The Office Eyes",
            author: "Aminah Barnes",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 13 2020"
        }, {
            bookId: "48",
            bookName: "A Sister's Salvation",
            author: "Opal Cummings",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 13 2017"
        }, {
            bookId: "19",
            bookName: "The Tulip Gift",
            author: "Barry Choi",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "September 06 2016"
        }],
        readHistory: [{
            bookId: "14",
            bookName: "Country at Q",
            author: "Shanna Raymond",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 04 2018",
                page: 30
            }
        }, {
            bookId: "19",
            bookName: "The Tulip Gift",
            author: "Barry Choi",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 06 2016",
                page: 211
            }
        }, {
            bookId: "42",
            bookName: "The Evil Rivals",
            author: "Izabelle Benjamin",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 28 2020",
                page: 75
            }
        }],
        wishList: [{
            bookId: "9",
            bookName: "Short Counsel",
            author: "Roy Nairn",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "11",
            bookName: "The Hurricane Keys",
            author: "Hugo Werner",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "16",
            bookName: "Doctor in Passion",
            author: "Katya Mansell",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy92",
    userType: "Student",
    displayName: "Andreas Moyer",
    photoURL: "https://picsum.photos/200'",
    email: "andreas.moyer@mail.com",
    biography: "The efficiency we have at removing trash has made creating trash more acceptable.",
    books: {
        favourites: [{
            bookId: "48",
            bookName: "A Sister's Salvation",
            author: "Opal Cummings",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 13 2017"
        }, {
            bookId: "47",
            bookName: "Witch's Debt",
            author: "Marwah Cornish",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "June 17 2018"
        }, {
            bookId: "6",
            bookName: "The Laugh of Eternity",
            author: "Aron Romero",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 04 2017"
        }],
        readHistory: [{
            bookId: "35",
            bookName: "A Shadow of Season",
            author: "Dilara Haley",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 31 2019",
                page: 104
            }
        }, {
            bookId: "41",
            bookName: "Breathing Moonlight",
            author: "Ayaan Brandt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "December 29 2016",
                page: 302
            }
        }, {
            bookId: "39",
            bookName: "The Specialist's Vacation",
            author: "Devonte Kirk",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 16 2015",
                page: 44
            }
        }],
        wishList: [{
            bookId: "39",
            bookName: "The Specialist's Vacation",
            author: "Devonte Kirk",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "47",
            bookName: "Witch's Debt",
            author: "Marwah Cornish",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "44",
            bookName: "Spare Signs",
            author: "Moshe Bowden",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy93",
    userType: "Student",
    displayName: "Miriam Vasquez",
    photoURL: "https://picsum.photos/200'",
    email: "miriam.vasquez@mail.com",
    biography: "Everyone was busy, so I went to the movie alone.",
    books: {
        favourites: [{
            bookId: "41",
            bookName: "Breathing Moonlight",
            author: "Ayaan Brandt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "December 29 2016"
        }, {
            bookId: "23",
            bookName: "Tarzan and the Stone",
            author: "Nada Hoover",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 19 2015"
        }, {
            bookId: "6",
            bookName: "The Laugh of Eternity",
            author: "Aron Romero",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 04 2017"
        }],
        readHistory: [{
            bookId: "5",
            bookName: "Prey for a Doctor",
            author: "Brent Shelton",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 06 2016",
                page: 218
            }
        }, {
            bookId: "48",
            bookName: "A Sister's Salvation",
            author: "Opal Cummings",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "October 13 2017",
                page: 41
            }
        }, {
            bookId: "50",
            bookName: "Single Templar",
            author: "Kayne Driscoll",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "October 21 2018",
                page: 225
            }
        }],
        wishList: [{
            bookId: "37",
            bookName: "Risk of Illusion",
            author: "Atlas Guthrie",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "33",
            bookName: "Pride of Flames",
            author: "Cayden Donovan",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "23",
            bookName: "Tarzan and the Stone",
            author: "Nada Hoover",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy94",
    userType: "Student",
    displayName: "Meadow Richards",
    photoURL: "https://picsum.photos/200'",
    email: "meadow.richards@mail.com",
    biography: "So long and thanks for the fish.",
    books: {
        favourites: [{
            bookId: "18",
            bookName: "The Handsome Thorn",
            author: "Ivy Singleton",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 02 2020"
        }, {
            bookId: "19",
            bookName: "The Tulip Gift",
            author: "Barry Choi",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "September 06 2016"
        }, {
            bookId: "49",
            bookName: "Cheyenne Malone",
            author: "Ellie Shaw",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 07 2019"
        }],
        readHistory: [{
            bookId: "37",
            bookName: "Risk of Illusion",
            author: "Atlas Guthrie",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 08 2016",
                page: 43
            }
        }, {
            bookId: "17",
            bookName: "The Wilder Walls",
            author: "Jamie Montgomery",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 27 2015",
                page: 118
            }
        }, {
            bookId: "20",
            bookName: "Brighton Grail",
            author: "Reanna Clayton",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "May 05 2018",
                page: 45
            }
        }],
        wishList: [{
            bookId: "32",
            bookName: "Bullets in the Sea",
            author: "Lidia Rhodes",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "15",
            bookName: "The Star Visitors",
            author: "James Stark",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "5",
            bookName: "Prey for a Doctor",
            author: "Brent Shelton",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy95",
    userType: "Student",
    displayName: "Corben Sargent",
    photoURL: "https://picsum.photos/200'",
    email: "corben.sargent@mail.com",
    biography: "She lived on Monkey Jungle Road and that seemed to explain all of her strangeness.",
    books: {
        favourites: [{
            bookId: "45",
            bookName: "The Daisy Godfather",
            author: "Hayden Malone",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "December 28 2014"
        }, {
            bookId: "19",
            bookName: "The Tulip Gift",
            author: "Barry Choi",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "September 06 2016"
        }, {
            bookId: "40",
            bookName: "Fine Karma",
            author: "Sofia Pratt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "December 16 2017"
        }],
        readHistory: [{
            bookId: "31",
            bookName: "Martian Baby",
            author: "Thierry Holman",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 28 2015",
                page: 147
            }
        }, {
            bookId: "39",
            bookName: "The Specialist's Vacation",
            author: "Devonte Kirk",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 16 2015",
                page: 44
            }
        }, {
            bookId: "17",
            bookName: "The Wilder Walls",
            author: "Jamie Montgomery",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 27 2015",
                page: 118
            }
        }],
        wishList: [{
            bookId: "21",
            bookName: "A Deadly Bastard",
            author: "Avani Flores",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "21",
            bookName: "A Deadly Bastard",
            author: "Avani Flores",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "34",
            bookName: "Curtain of Mystery",
            author: "Sian Huff",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy96",
    userType: "Student",
    displayName: "Tom Reynolds",
    photoURL: "https://picsum.photos/200'",
    email: "tom.reynolds@mail.com",
    biography: "The minute she landed she understood the reason this was a fly-over state.",
    books: {
        favourites: [{
            bookId: "24",
            bookName: "The Girl in Transylvania",
            author: "Spike Rice",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "September 11 2017"
        }, {
            bookId: "39",
            bookName: "The Specialist's Vacation",
            author: "Devonte Kirk",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 16 2015"
        }, {
            bookId: "39",
            bookName: "The Specialist's Vacation",
            author: "Devonte Kirk",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 16 2015"
        }],
        readHistory: [{
            bookId: "25",
            bookName: "Maigret and the Pickpocket",
            author: "Jonny Simon",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "April 09 2018",
                page: 56
            }
        }, {
            bookId: "34",
            bookName: "Curtain of Mystery",
            author: "Sian Huff",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 02 2016",
                page: 110
            }
        }, {
            bookId: "3",
            bookName: "The Whiskey Reality",
            author: "Carolyn Cano",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "October 12 2018",
                page: 45
            }
        }],
        wishList: [{
            bookId: "7",
            bookName: "Echoes of Homicide",
            author: "Kaden Massey",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "4",
            bookName: "Point Scorned",
            author: "Reanne Kennedy",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "36",
            bookName: "The Spirit of the Bow",
            author: "Cherry Valencia",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy97",
    userType: "Student",
    displayName: "Giles Gibbs",
    photoURL: "https://picsum.photos/200'",
    email: "giles.gibbs@mail.com",
    biography: "Swim at your own risk was taken as a challenge for the group of Kansas City college students.",
    books: {
        favourites: [{
            bookId: "39",
            bookName: "The Specialist's Vacation",
            author: "Devonte Kirk",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "March 16 2015"
        }, {
            bookId: "36",
            bookName: "The Spirit of the Bow",
            author: "Cherry Valencia",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "November 30 2015"
        }, {
            bookId: "48",
            bookName: "A Sister's Salvation",
            author: "Opal Cummings",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 13 2017"
        }],
        readHistory: [{
            bookId: "33",
            bookName: "Pride of Flames",
            author: "Cayden Donovan",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "August 21 2019",
                page: 4
            }
        }, {
            bookId: "28",
            bookName: "Veil of the Daemon",
            author: "Kaylee Boyd",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 09 2017",
                page: 181
            }
        }, {
            bookId: "2",
            bookName: "Vineyard Waves",
            author: "Sinead Morgan",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 29 2018",
                page: 206
            }
        }],
        wishList: [{
            bookId: "48",
            bookName: "A Sister's Salvation",
            author: "Opal Cummings",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "14",
            bookName: "Country at Q",
            author: "Shanna Raymond",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "5",
            bookName: "Prey for a Doctor",
            author: "Brent Shelton",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy98",
    userType: "Student",
    displayName: "Gabrielle Whittle",
    photoURL: "https://picsum.photos/200'",
    email: "gabrielle.whittle@mail.com",
    biography: "8% of 25 is the same as 25% of 8 and one of them is much easier to do in your head.",
    books: {
        favourites: [{
            bookId: "50",
            bookName: "Single Templar",
            author: "Kayne Driscoll",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 21 2018"
        }, {
            bookId: "15",
            bookName: "The Star Visitors",
            author: "James Stark",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 21 2016"
        }, {
            bookId: "8",
            bookName: "Batman's Fortress",
            author: "Anastazja Shepard",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "July 19 2017"
        }],
        readHistory: [{
            bookId: "18",
            bookName: "The Handsome Thorn",
            author: "Ivy Singleton",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "January 02 2020",
                page: 33
            }
        }, {
            bookId: "41",
            bookName: "Breathing Moonlight",
            author: "Ayaan Brandt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "December 29 2016",
                page: 302
            }
        }, {
            bookId: "41",
            bookName: "Breathing Moonlight",
            author: "Ayaan Brandt",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "December 29 2016",
                page: 302
            }
        }],
        wishList: [{
            bookId: "36",
            bookName: "The Spirit of the Bow",
            author: "Cherry Valencia",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "9",
            bookName: "Short Counsel",
            author: "Roy Nairn",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "7",
            bookName: "Echoes of Homicide",
            author: "Kaden Massey",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy99",
    userType: "Student",
    displayName: "Lila Rudd",
    photoURL: "https://picsum.photos/200'",
    email: "lila.rudd@mail.com",
    biography: "I often see the time 11:11 or 12:34 on clocks.",
    books: {
        favourites: [{
            bookId: "2",
            bookName: "Vineyard Waves",
            author: "Sinead Morgan",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "September 29 2018"
        }, {
            bookId: "5",
            bookName: "Prey for a Doctor",
            author: "Brent Shelton",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "September 06 2016"
        }, {
            bookId: "42",
            bookName: "The Evil Rivals",
            author: "Izabelle Benjamin",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "April 28 2020"
        }],
        readHistory: [{
            bookId: "8",
            bookName: "Batman's Fortress",
            author: "Anastazja Shepard",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 19 2017",
                page: 84
            }
        }, {
            bookId: "11",
            bookName: "The Hurricane Keys",
            author: "Hugo Werner",
            genre: "Biography",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "October 25 2017",
                page: 22
            }
        }, {
            bookId: "8",
            bookName: "Batman's Fortress",
            author: "Anastazja Shepard",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "July 19 2017",
                page: 84
            }
        }],
        wishList: [{
            bookId: "42",
            bookName: "The Evil Rivals",
            author: "Izabelle Benjamin",
            genre: "History",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "44",
            bookName: "Spare Signs",
            author: "Moshe Bowden",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "20",
            bookName: "Brighton Grail",
            author: "Reanna Clayton",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}, {
    uid: "dummy100",
    userType: "Student",
    displayName: "Tea Haney",
    photoURL: "https://picsum.photos/200'",
    email: "tea.haney@mail.com",
    biography: "Wisdom is easily acquired when hiding under the bed with a saucepan on your head.",
    books: {
        favourites: [{
            bookId: "5",
            bookName: "Prey for a Doctor",
            author: "Brent Shelton",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "September 06 2016"
        }, {
            bookId: "48",
            bookName: "A Sister's Salvation",
            author: "Opal Cummings",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "October 13 2017"
        }, {
            bookId: "34",
            bookName: "Curtain of Mystery",
            author: "Sian Huff",
            genre: "Fiction",
            bookPhotoURL: "https://picsum.photos/200'",
            dateAdded: "January 02 2016"
        }],
        readHistory: [{
            bookId: "24",
            bookName: "The Girl in Transylvania",
            author: "Spike Rice",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "September 11 2017",
                page: 298
            }
        }, {
            bookId: "49",
            bookName: "Cheyenne Malone",
            author: "Ellie Shaw",
            genre: "Children's",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "March 07 2019",
                page: 229
            }
        }, {
            bookId: "22",
            bookName: "The Dragon and the Tiger",
            author: "Alex House",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'",
            log: {
                date: "November 27 2019",
                page: 224
            }
        }],
        wishList: [{
            bookId: "4",
            bookName: "Point Scorned",
            author: "Reanne Kennedy",
            genre: "Romance",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "24",
            bookName: "The Girl in Transylvania",
            author: "Spike Rice",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }, {
            bookId: "25",
            bookName: "Maigret and the Pickpocket",
            author: "Jonny Simon",
            genre: "Nonfiction",
            bookPhotoURL: "https://picsum.photos/200'"
        }]
    },
    hasAuth: false
}]

const BOOKS_DATA = [{
    bookId: "1",
    bookName: "Mystery in Trouble",
    author: "Alejandro Oakley",
    genre: "Romance",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2019,
    description: "The blue parrot drove by the hitchhiking mongoose.",
    length: 430,
    readAmount: 2958,
    favourite: 818
}, {
    bookId: "2",
    bookName: "Vineyard Waves",
    author: "Sinead Morgan",
    genre: "Romance",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2016,
    description: "The sun had set and so had his dreams.",
    length: 294,
    readAmount: 1551,
    favourite: 950
}, {
    bookId: "3",
    bookName: "The Whiskey Reality",
    author: "Carolyn Cano",
    genre: "Romance",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2008,
    description: "Peanuts don't grow on trees, but cashews do.",
    length: 411,
    readAmount: 2998,
    favourite: 716
}, {
    bookId: "4",
    bookName: "Point Scorned",
    author: "Reanne Kennedy",
    genre: "Romance",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2016,
    description: "Various sea birds are elegant, but nothing is as elegant as a gliding pelican.",
    length: 157,
    readAmount: 1236,
    favourite: 527
}, {
    bookId: "5",
    bookName: "Prey for a Doctor",
    author: "Brent Shelton",
    genre: "Romance",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2019,
    description: "The pigs were insulted that they were named hamburgers.",
    length: 238,
    readAmount: 1100,
    favourite: 872
}, {
    bookId: "6",
    bookName: "The Laugh of Eternity",
    author: "Aron Romero",
    genre: "Romance",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2008,
    description: "Edith could decide if she should paint her teeth or brush her nails.",
    length: 480,
    readAmount: 1352,
    favourite: 809
}, {
    bookId: "7",
    bookName: "Echoes of Homicide",
    author: "Kaden Massey",
    genre: "Romance",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2016,
    description: "Her daily goal was to improve on yesterday.",
    length: 171,
    readAmount: 1075,
    favourite: 956
}, {
    bookId: "8",
    bookName: "Batman's Fortress",
    author: "Anastazja Shepard",
    genre: "Romance",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2002,
    description: "They ran around the corner to find that they had traveled back in time.",
    length: 183,
    readAmount: 1164,
    favourite: 691
}, {
    bookId: "9",
    bookName: "Short Counsel",
    author: "Roy Nairn",
    genre: "Biography",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2001,
    description: "Chocolate covered crickets were his favorite snack.",
    length: 253,
    readAmount: 1311,
    favourite: 718
}, {
    bookId: "10",
    bookName: "The Crown of the Sunset",
    author: "Sommer Keith",
    genre: "Biography",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2016,
    description: "It caught him off guard that space smelled of seared steak.",
    length: 256,
    readAmount: 1970,
    favourite: 564
}, {
    bookId: "11",
    bookName: "The Hurricane Keys",
    author: "Hugo Werner",
    genre: "Biography",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2017,
    description: "Most shark attacks occur about 10 feet from the beach since that's where the people are.",
    length: 128,
    readAmount: 2312,
    favourite: 957
}, {
    bookId: "12",
    bookName: "Amber and Glory",
    author: "Ava-Mai Combs",
    genre: "Biography",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2018,
    description: "There's a message for you if you look up.",
    length: 355,
    readAmount: 2820,
    favourite: 857
}, {
    bookId: "13",
    bookName: "The Piano Hospital",
    author: "Tonya Dyer",
    genre: "Biography",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2000,
    description: "The virus had powers none of us knew existed.",
    length: 129,
    readAmount: 2625,
    favourite: 961
}, {
    bookId: "14",
    bookName: "Country at Q",
    author: "Shanna Raymond",
    genre: "Biography",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2002,
    description: "Courage and stupidity were all he had.",
    length: 215,
    readAmount: 1479,
    favourite: 700
}, {
    bookId: "15",
    bookName: "The Star Visitors",
    author: "James Stark",
    genre: "Biography",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2000,
    description: "Too many prisons have become early coffins.",
    length: 481,
    readAmount: 1856,
    favourite: 711
}, {
    bookId: "16",
    bookName: "Doctor in Passion",
    author: "Katya Mansell",
    genre: "Biography",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2017,
    description: "He figured a few sticks of dynamite were easier than a fishing pole to catch fish.",
    length: 494,
    readAmount: 1854,
    favourite: 745
}, {
    bookId: "17",
    bookName: "The Wilder Walls",
    author: "Jamie Montgomery",
    genre: "Biography",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2017,
    description: "She lived on Monkey Jungle Road and that seemed to explain all of her strangeness.",
    length: 196,
    readAmount: 1231,
    favourite: 601
}, {
    bookId: "18",
    bookName: "The Handsome Thorn",
    author: "Ivy Singleton",
    genre: "Biography",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2020,
    description: "Although it wasn't a pot of gold, Nancy was still enthralled at what she found at the end of the rainbow.",
    length: 268,
    readAmount: 2263,
    favourite: 984
}, {
    bookId: "19",
    bookName: "The Tulip Gift",
    author: "Barry Choi",
    genre: "Biography",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2019,
    description: "He was surprised that his immense laziness was inspirational to others.",
    length: 421,
    readAmount: 2154,
    favourite: 549
}, {
    bookId: "20",
    bookName: "Brighton Grail",
    author: "Reanna Clayton",
    genre: "Nonfiction",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2008,
    description: "After exploring the abandoned building, he started to believe in ghosts.",
    length: 150,
    readAmount: 2952,
    favourite: 972
}, {
    bookId: "21",
    bookName: "A Deadly Bastard",
    author: "Avani Flores",
    genre: "Nonfiction",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2010,
    description: "David proudly graduated from high school top of his class at age 97.",
    length: 402,
    readAmount: 2704,
    favourite: 983
}, {
    bookId: "22",
    bookName: "The Dragon and the Tiger",
    author: "Alex House",
    genre: "Nonfiction",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2010,
    description: "When motorists sped in and out of traffic, all she could think of was those in need of a transplant.",
    length: 431,
    readAmount: 1566,
    favourite: 567
}, {
    bookId: "23",
    bookName: "Tarzan and the Stone",
    author: "Nada Hoover",
    genre: "Nonfiction",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2020,
    description: "They improved dramatically once the lead singer left.",
    length: 237,
    readAmount: 1958,
    favourite: 639
}, {
    bookId: "24",
    bookName: "The Girl in Transylvania",
    author: "Spike Rice",
    genre: "Nonfiction",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2009,
    description: "Everyone says they love nature until they realize how dangerous she can be.",
    length: 349,
    readAmount: 1153,
    favourite: 836
}, {
    bookId: "25",
    bookName: "Maigret and the Pickpocket",
    author: "Jonny Simon",
    genre: "Nonfiction",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2012,
    description: "Smoky the Bear secretly started the fires.",
    length: 419,
    readAmount: 2602,
    favourite: 906
}, {
    bookId: "26",
    bookName: "Blunt Abyss",
    author: "Ella-Mae Spencer",
    genre: "Nonfiction",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2000,
    description: "I hear that Nancy is very pretty.",
    length: 433,
    readAmount: 1767,
    favourite: 539
}, {
    bookId: "27",
    bookName: "The Office Eyes",
    author: "Aminah Barnes",
    genre: "Nonfiction",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2002,
    description: "Her life in the confines of the house became her new normal.",
    length: 357,
    readAmount: 1500,
    favourite: 792
}, {
    bookId: "28",
    bookName: "Veil of the Daemon",
    author: "Kaylee Boyd",
    genre: "Nonfiction",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2012,
    description: "He decided that the time had come to be stronger than any of the excuses he'd used until then.",
    length: 329,
    readAmount: 1018,
    favourite: 785
}, {
    bookId: "29",
    bookName: "Change of Captivity",
    author: "Landon Clements",
    genre: "Nonfiction",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2014,
    description: "She found his complete dullness interesting.",
    length: 114,
    readAmount: 1706,
    favourite: 792
}, {
    bookId: "30",
    bookName: "A Tangled Balance",
    author: "Yasemin Hart",
    genre: "Fiction",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2003,
    description: "She hadn't had her cup of coffee, and that made things all the worse.",
    length: 434,
    readAmount: 2814,
    favourite: 757
}, {
    bookId: "31",
    bookName: "Martian Baby",
    author: "Thierry Holman",
    genre: "Fiction",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2011,
    description: "At that moment she realized she had a sixth sense.",
    length: 413,
    readAmount: 1653,
    favourite: 952
}, {
    bookId: "32",
    bookName: "Bullets in the Sea",
    author: "Lidia Rhodes",
    genre: "Fiction",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2017,
    description: "There can never be too many cherries on an ice cream sundae.",
    length: 106,
    readAmount: 1909,
    favourite: 977
}, {
    bookId: "33",
    bookName: "Pride of Flames",
    author: "Cayden Donovan",
    genre: "Fiction",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2012,
    description: "People keep telling me orange but I still prefer pink.",
    length: 190,
    readAmount: 1865,
    favourite: 921
}, {
    bookId: "34",
    bookName: "Curtain of Mystery",
    author: "Sian Huff",
    genre: "Fiction",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2015,
    description: "While all her friends were positive that Mary had a sixth sense, she knew she actually had a seventh sense.",
    length: 157,
    readAmount: 2836,
    favourite: 668
}, {
    bookId: "35",
    bookName: "A Shadow of Season",
    author: "Dilara Haley",
    genre: "Fiction",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2000,
    description: "He decided to count all the sand on the beach as a hobby.",
    length: 178,
    readAmount: 1517,
    favourite: 650
}, {
    bookId: "36",
    bookName: "The Spirit of the Bow",
    author: "Cherry Valencia",
    genre: "History",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2003,
    description: "This is a Japanese doll.",
    length: 389,
    readAmount: 2038,
    favourite: 513
}, {
    bookId: "37",
    bookName: "Risk of Illusion",
    author: "Atlas Guthrie",
    genre: "History",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2016,
    description: "A glittering gem is not enough.",
    length: 114,
    readAmount: 1262,
    favourite: 981
}, {
    bookId: "38",
    bookName: "The Voice of the Matter",
    author: "Libbie Aguilar",
    genre: "History",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2015,
    description: "He kept telling himself that one day it would all somehow make sense.",
    length: 347,
    readAmount: 2064,
    favourite: 893
}, {
    bookId: "39",
    bookName: "The Specialist's Vacation",
    author: "Devonte Kirk",
    genre: "History",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2013,
    description: "When nobody is around, the trees gossip about the people who have walked under them.",
    length: 411,
    readAmount: 1210,
    favourite: 570
}, {
    bookId: "40",
    bookName: "Fine Karma",
    author: "Sofia Pratt",
    genre: "History",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2004,
    description: "It was the scarcity that fueled his creativity.",
    length: 112,
    readAmount: 1779,
    favourite: 717
}, {
    bookId: "41",
    bookName: "Breathing Moonlight",
    author: "Ayaan Brandt",
    genre: "History",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2016,
    description: "Three generations with six decades of life experience.",
    length: 402,
    readAmount: 1512,
    favourite: 611
}, {
    bookId: "42",
    bookName: "The Evil Rivals",
    author: "Izabelle Benjamin",
    genre: "History",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2015,
    description: "The stranger officiates the meal.",
    length: 203,
    readAmount: 1651,
    favourite: 829
}, {
    bookId: "43",
    bookName: "A Rat's Salvation",
    author: "Ananya Gay",
    genre: "History",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2021,
    description: "There were three sphered rocks congregating in a cubed room.",
    length: 320,
    readAmount: 2408,
    favourite: 974
}, {
    bookId: "44",
    bookName: "Spare Signs",
    author: "Moshe Bowden",
    genre: "Children's",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2021,
    description: "You'll see the rainbow bridge after it rains cats and dogs.",
    length: 244,
    readAmount: 1119,
    favourite: 937
}, {
    bookId: "45",
    bookName: "The Daisy Godfather",
    author: "Hayden Malone",
    genre: "Children's",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2014,
    description: "Separation anxiety is what happens when you can't find your phone.",
    length: 112,
    readAmount: 1474,
    favourite: 677
}, {
    bookId: "46",
    bookName: "Savage Feet",
    author: "Judith Estes",
    genre: "Children's",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2012,
    description: "The door swung open to reveal pink giraffes and red elephants.",
    length: 157,
    readAmount: 1409,
    favourite: 642
}, {
    bookId: "47",
    bookName: "Witch's Debt",
    author: "Marwah Cornish",
    genre: "Children's",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2021,
    description: "The random sentence generator generated a random sentence about a random sentence.",
    length: 480,
    readAmount: 1385,
    favourite: 821
}, {
    bookId: "48",
    bookName: "A Sister's Salvation",
    author: "Opal Cummings",
    genre: "Children's",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2007,
    description: "He wasn't bitter that she had moved on but from the radish.",
    length: 245,
    readAmount: 1703,
    favourite: 571
}, {
    bookId: "49",
    bookName: "Cheyenne Malone",
    author: "Ellie Shaw",
    genre: "Children's",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2021,
    description: "It's not often you find a soggy banana on the street.",
    length: 396,
    readAmount: 1328,
    favourite: 713
}, {
    bookId: "50",
    bookName: "Single Templar",
    author: "Kayne Driscoll",
    genre: "Children's",
    bookPhotoURL: "https://picsum.photos/200'",
    yearPublished: 2019,
    description: "He felt that dining on the bridge brought romance to his relationship with his cat.",
    length: 331,
    readAmount: 1327,
    favourite: 689
}]




module.exports = {
    ASSIGNMENTS_DATA, USERS_DATA, BOOKS_DATA
}