import React, { useState, useMemo, useRef } from 'react'

export default function BookOverview( { book, previousSearchString, userFromDB, addToFavourites, removeFromFavourites }) {
    const [ favourited , setFavourited ] = useState(false)
    const favouriteButtonRef = useRef()

    // Need to check if this bookId is already favourited by this user

    useMemo( () => {
        console.log( "useMemo triggered")
        checkIfFavourited(book.bookId)
    }, [userFromDB, book])

    function checkIfFavourited(bookId){
        if(!userFromDB) { setFavourited(false); return} // if no user
        if(!userFromDB.books) {setFavourited(false); return} // if user.books does not exist
        if(!userFromDB.books.favourites){setFavourited(false); return} // if user.books.favourites does not exist

        const booksFavouritedByUser = userFromDB.books.favourites.map( (book) => {
            return book ? book.bookId : ""
        }) ?? []

        if(booksFavouritedByUser.includes(bookId)) setFavourited(true)
    }

    function handleFavouriteButton(){
        if(favourited){
            favouriteButtonRef.current.disabled = true
            favouriteButtonRef.current.innerText = "− Removed from Favourites!"
            removeFromFavourites()
        } else{
            favouriteButtonRef.current.disabled = true
            favouriteButtonRef.current.innerText = "✔️ Added to Favourites!"
            addToFavourites()
        }
    }
    


    
    return (
        book &&
        <div className="book-overiew-container">
            
            <div className="back-to-search-div">
                <a className="back-to-search-button" href={"/search" + previousSearchString}> 
                        ﹤ Back to Search
                </a>
            </div>

            <div className="book-summary-container">
                <div className="book-summary-photo-container">
                    <img src={(!book.bookPhotoURL || book.bookPhotoURL == 'NaN') ? "/default_book.png" : book.bookPhotoURL} />
                    <div className="book-summary-action-bar">
                        { userFromDB && (
                        
                        favourited ?
                        <button className="remove-from-favourites" onClick={handleFavouriteButton} ref={favouriteButtonRef}> ⨉ Remove from Favourites </button>
                        :
                        <button className="add-to-favourites" onClick={handleFavouriteButton} ref={favouriteButtonRef}> ♥ Add to Favourites </button>
                        )}          
                    </div>

                </div>

                <div className="book-summary-info">
                    <div className="book-summary">
                        <label className="book-summary-book-title"> 
                            {book.bookName} 
                        </label>
                        <label className="book-summary-book-year-published"> 
                            { "(" + ((!book.yearPublished || book.yearPublished == 'NaN')? "Unknown" : book.yearPublished) +")"} 
                        </label>

                        <div className="book-summary-author">
                            { "By " + ((!book.author || book.author == 'NaN')? "Unknown" : book.author)}
                        </div>

                        {/* <div className="book-summary-ratings">
                            ★★★★☆ 
                            <label> 4.0 stars (1,235 ratings) </label>
                        </div> */}

                        <br></br>

                        <div className="book-summary-description">
                            {book.description}
                        </div>

                        <div className="book-summary-genre">
                            <label> {book.genre} </label>
                        </div>

                    </div>

                   
                </div> 
            </div>


        </div>
    )
}
