import React from 'react';
import { useAuth } from '../../contexts/AuthContext';
import Sidebar from '../Sidebar/Sidebar';
import SidebarItem from '../Sidebar/SidebarItem';
import Footer from '../Footer';
import SearchBar, { SortBar } from './SearchBar';
import '../../css/search.css'
import SearchResultsBox from './SearchResultsBox';

export default function SearchResults( { searchResults}) {

	return (
		<>
		{
		searchResults.map( (book, index) => {
			return <SearchResultsBox key={index} book={book}/>
			})
		
		}
		</>
	)
}


