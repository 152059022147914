import React, { useContext, useState , useEffect} from 'react'
import { auth, db } from '../firebase'
import '../css/loading.css'
import 'bootstrap/dist/css/bootstrap.min.css';

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)


    function login(email,password){
        return auth.signInWithEmailAndPassword(email,password)
    }

    function logout(){
        return auth.signOut()
    }

    function resetPassword(email){
        return auth.sendPasswordResetEmail(email)
    }


    function signup(email,password, displayName, userType="General"){
        return (
            auth.createUserWithEmailAndPassword (email,password)
            .then((userCredential) =>{

                // Update the profile information of this user
                let user = userCredential.user
                user.updateProfile({
                    displayName: displayName
        
                })

                // Place this into our users collection in firestore to replicate the data
                db.collection("users").doc(user.uid).set({
                    uid: user.uid,
                    displayName: displayName,
                    biography: "",
                    photoURL: "",
                    email: email,
                    books: {
                        favourites: [],
                        wishlist: [],
                        readHistory: []
                    },
                    userType: userType, // Can be teacher/student/others
                    hasAuth: true
                })
                .then( () => {
                    console.log("Document successfully written: ", user.uid)
                })
                .catch( (e) => {
                    console.error("Error adding document: ", e)
                })
            })
        )
    }



    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false)
        })

        return unsubscribe
    },[])
   

    const value = {
        currentUser,
        signup,
        login,
        logout,
        resetPassword
    }

    return (
        <AuthContext.Provider value={value}>
            { loading &&
            <div className="main-div">
                <div className="loading-screen"> 
                    <div className="loading-dots"> </div>
                    <div className="loading-dots"> </div>
                    <div className="loading-dots"> </div>
                    <div className="loading-dots"> </div>
                    <div className="loading-dots"> </div>
                </div> 
            </div>
            }
            {!loading && children}
        </AuthContext.Provider>
        )
}
