import React, { useState, useEffect } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useHistory } from "react-router-dom"
import { db } from '../../firebase'
import NavbarItem from './NavbarItem'
import Logo from './Logo'
import NavbarDropdown from './NavbarDropdown'
import NavbarLinkButton from './NavbarLinkButton'
import LanguageMenu from './LanguageMenu'
import NavbarHamburger from './NavbarHamburger'


/** 
 * @module Navbar React Component
 * 
*/

/**
 * NavivgationBar.js - A functional component that returns the Navbar HTML
 * @returns {JSX}
 * - This returns the navigation bar object depends on the currentUser object
 * - currentUser is passed through the AuthContext
 * 
 */
export default function NavigationBar() {

    /**  
     * currentUser refers to the Logged In user currently,
     * @member currentUser
    */
    /**
     * logout is a function passed from AuthContext 
     * @member logout 
     */
    const { currentUser, logout } = useAuth()

    /** 
     * Hook variable from useHistory() to push user to /login after logout has been triggered by user
     * @member history  
    */
    const history = useHistory()

    /**
     * (useState) userProfile object based on the currentUser.uid
     * - this is the fetched userProfile from Firestore (not to be confused with currentUser, which is the object directly from FirebaseAuth and NOT Firestore)
     * - setUserFromDB is the function to update state of userFromDB
     * @member userFromDB
     */
    const [userFromDB , setUserFromDB] = useState("")


    /**
     * This is triggered by the logout button, which directly calls the logout function from AuthContext. Upon success, history.push('/login') will be ran to push users back to the login page.
     * @function handleLogout
     * @returns {void}
     * 
     */
    async function handleLogout(){
        try{
            await logout()
            history.push('/login')
        } catch(e){
            console.log(e)
        }

    }

    /**
     * Taking the uid from the "users" collection in Firestore, and runs setUserFromDB(fetched-result)
     * @function getProfile
     * @param {string} uid - For NavBar, this is currentUser.uid always, unless user is not logged in.
     * @returns {void}
     */
    async function getProfile(uid){
        console.log("navBar GetProfile ran.")
        if(uid==="") return
        const profileRef = db.collection("users").doc(uid)

        await profileRef.get().then((doc) => {
            if(doc.exists){
                return setUserFromDB(doc.data())
            } else{
                return setUserFromDB(undefined) 
            }
        }).catch( (e) => {
            console.log("Error in retrieving profile:", e)
        })
        return
    }

    /** 
     * This is ran just before rendering the component, and when currentUser has been changed (logout or login). Once currentUser has been fetched/changed, getProfile will be triggered, updating userFromDB.
     * @function useEffect
     */
    useEffect(() => {   
        return getProfile(currentUser ? currentUser.uid : "")
    },[currentUser])


    return (
        <>
        <nav className="navbar">
            <div className="navbar-nav-left">
                <Logo />

                { currentUser === null ? 
                <>
                    <ul className="navbar-nav-left-items">
                        <NavbarItem title="Explore" link="/explore" />
                        <NavbarItem title="About Us" link="/about" />
                        {/* <NavbarItem title="Contact Us" link="/contact" /> */}
                        <NavbarItem title="Search" link="/search" />
                        {/* <NavbarItem title="Schools" link="/schools" /> */}
                    </ul>

                    <ul className="navbar-nav-left-burger">
                        <NavbarHamburger>
                            <NavbarItem title="Explore" link="/explore" />
                            <NavbarItem title="About Us" link="/about" />
                            {/* <NavbarItem title="Contact Us" link="/contact" /> */}
                            <NavbarItem title="Search" link="/search" />
                            {/* <NavbarItem title="Schools" link="/schools" /> */}
                        </NavbarHamburger>
                    </ul>
                </>
                :
                <>
                    <ul className="navbar-nav-left-items">
                        <NavbarItem title="Explore" link="/explore" />
                        <NavbarItem title="Search" link="/search" />
                        <NavbarItem title="My Class" link="/class" />

                    </ul>

                    <ul className="navbar-nav-left-burger">
                    <NavbarHamburger>
                        <NavbarItem title="Explore" link="/explore" />
                        <NavbarItem title="Search" link="/search" />
                        <NavbarItem title="My Class" link="/class" />
                    </NavbarHamburger>
                    </ul>
                </>
                }

            </div>


            {/* <ul className="navbar-nav-right">

                {currentUser === null ?
                    <>
                    <NavbarLinkButton title="Login" link="/login" />
                    <NavbarLinkButton title="Signup" link="/signup" />
                    </>
                    :
                    <>
                    <a href={"/profile?user=" + currentUser.uid} > {userFromDB && userFromDB.displayName} </a>
                    <NavbarDropdown photoURL={userFromDB && userFromDB.photoURL} handleLogout={handleLogout} currentUserUid={currentUser.uid}/>
                    </>
                }

                <LanguageMenu />
            </ul> */}

        </nav>
        </>
    )
}
